import * as Sentry from "@sentry/react";
import { applyMiddleware, createStore as createReduxStore, Store } from "redux";
import { persistStore, persistCombineReducers } from "redux-persist";
import localForage from "localforage";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { authReducer, authInitialState } from "./auth";
import { partnerReducer, partnerInitialState } from "./partner";
import { ActionsUnion, State } from "./types";
import { featuresInitialState, featuresReducer } from "./features";
import {
  prescriptionsInitialState,
  prescriptionsReducer
} from "./prescriptions";
import { signUpInitialState, signUpReducer } from "./signUp";

export const initialState: State = {
  auth: {
    ...authInitialState
  },
  partner: {
    ...partnerInitialState
  },
  features: {
    ...featuresInitialState
  },
  prescriptions: {
    ...prescriptionsInitialState
  },
  signUp: {
    ...signUpInitialState
  }
};

const persistConfig = {
  key: "root",
  storage: localForage
};

const reducers = {
  auth: authReducer,
  partner: partnerReducer,
  features: featuresReducer,
  prescriptions: prescriptionsReducer,
  signUp: signUpReducer
};

const rootReducer = persistCombineReducers<State, ActionsUnion>(
  persistConfig,
  reducers
);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const composedMiddleware = composeWithDevTools(
  applyMiddleware(thunk),
  sentryReduxEnhancer
);

export const store = createReduxStore(
  rootReducer,
  initialState as any,
  composedMiddleware
);

export const createPersistor = (store: Store<State, ActionsUnion>) =>
  persistStore(store, {});
