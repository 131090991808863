export enum ApiErrors {
  ERR_UNKNOWN = "ERR_UNKNOWN",
  ERR_NOT_AUTHENTICATED = "ERR_NOT_AUTHENTICATED",
  ERR_AUTH_EXPIRED = "ERR_AUTH_EXPIRED",
  ERR_NOT_ALLOWED = "ERR_NOT_ALLOWED",
  ERR_INVALID_ARGUMENTS = "ERR_INVALID_ARGUMENTS",
  ERR_RESOURCE_EXHAUSTED = "ERR_RESOURCE_EXHAUSTED",
  ERR_ALREADY_EXISTS = "ERR_ALREADY_EXISTS",
  ERR_NOT_FOUND = "ERR_NOT_FOUND",
  ERR_UNDELIVERABLE_POSTCODE = "ERR_UNDELIVERABLE_POSTCODE"
}
