import React, { ReactNode } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import config from "../config";

const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);

interface Props {
  children: ReactNode;
}

const fonts = [
  {
    cssSrc: "https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500"
  }
];

export const StripeProvider = (props: Props) => {
  return (
    <Elements stripe={stripePromise} options={{ fonts: fonts }}>
      {props.children}
    </Elements>
  );
};
