// AUTO-GENERATED - DO NOT EDIT!
import gql from "graphql-tag";
import { useMutation, MutationHookOptions } from "@apollo/client";
import {
  updateExperiment,
  updateExperimentVariables
} from "../types/updateExperiment";

export const useUpdateExperimentMutation = (
  opts: MutationHookOptions<updateExperiment, updateExperimentVariables>
) => {
  return useMutation<updateExperiment, updateExperimentVariables>(
    updateExperimentMutation,
    opts
  );
};

export const updateExperimentMutation = gql`
  mutation updateExperiment($experimentName: String!, $events: [String!]!) {
    updateExperiment(experimentName: $experimentName, events: $events) {
      errors {
        field
        message
      }
    }
  }
`;
