/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import * as Segment from "./segment";

export interface SeOrderMedicineDeliveryGoBackClick {
  patientid?: string;
}
export interface SeOrderMedicineDeliveryLpClick {
  patientid?: string;
}
export interface SeOrderMedicineDeliveryLpClickCollect {
  patientid?: string;
}
export interface SeOrderMedicineDeliveryUnavailableClick {
  patientid?: string;
}
export interface SeOrderMedicineEstimateButtonClick {
  patientid?: string;
  stockEstimateStatus?: Record<string, any>;
}
export interface SeOrderMedicineFindLloydsDismissModal {
  patientid?: string;
}
export interface SeOrderMedicineFindLloydsGoBackClick {
  patientid?: string;
}
export interface SeOrderMedicineFindLloydsPageView {
  patientid?: string;
}
export interface SeOrderMedicinePageView {
  patientid?: string;
  stockEstimateStatus?: Record<string, any>;
}
export interface SeOrderMedicineSelectClick {
  patientid?: string;
  stockEstimateStatus?: Record<string, any>;
}
export interface SeOrderMedicineUnavailableDeliveryPageView {
  patientid?: string;
}
export interface SeOrderMedicineUnavailableForDeliveryBannerClick {
  patientid?: string;
}
export interface SeOrderMedicineWaitingForStockBannerClick {
  patientid?: string;
}
export interface AccountPreferredNameConfirm {
  patientid: string;
}
export interface AccountPreferredNamePageview {
  patientid: string;
}
export interface AddAnotherPerson {
  patientid: string;
}
export interface AddAnotherPersonSidebar {
  patientid: string;
}
export interface AddExemptionConfirm {
  patientid?: string;
}
export interface AddExemptionExpiryPageview {
  patientid?: string;
}
export interface AddExemptionsPageview {
  patientid?: string;
}
export interface AddMedicationConfirm {
  patientid: string;
}
export interface AddMedicationDueDatePageview {
  patientid: string;
}
export interface AddMedicationPageview {
  patientid?: string;
}
export interface AddMedicationPickStrength {
  patientid: string;
}
export interface AddMedicineAddNewMedicine {
  patientid: string;
}
export interface AddMedicineAndroidDownload {
  patientid: string;
}
export interface AddMedicineAnyLeftNo {
  patientid: string;
}
export interface AddMedicineAnyLeftPageview {
  patientid: string;
}
export interface AddMedicineAnyLeftYes {
  patientid: string;
}
export interface AddMedicineAppleDownload {
  patientid: string;
}
export interface AddMedicineAppliancePageview {
  patientid: string;
}
export interface AddMedicineExit {
  patientid?: string;
}
export interface AddMedicineHowMuch {
  patientid: string;
}
export interface AddMedicineHowMuchErrorPageview {
  patientid: string;
}
export interface AddMedicineHowMuchPageview {
  patientid: string;
}
export interface AddMedicineNhsLogin {
  patientid: string;
}
export interface AddMedicineNhsLoginContinue {
  patientid: string;
}
export interface AddMedicineNhsLoginPageview {
  patientid: string;
}
export interface AddMedicineNotTimeToOrder {
  patientid: string;
}
export interface AddMedicineNotTimeToOrderPageview {
  patientid: string;
}
export interface AddMedicinePageview {
  patientid: string;
}
export interface AddMedicineSafeCustodyPageview {
  patientid: string;
}
export interface AddMedicineSearch {
  patientid: string;
}
export interface AddMedicineSearchNoResultsPageview {
  patientid: string;
}
export interface AddMedicineSearchPageview {
  patientid: string;
}
export interface AddMedicineSearchResultsPageview {
  patientid: string;
}
export interface AddMedicineSearchSelect {
  patientid: string;
}
export interface AddMedicineSkip {
  patientid: string;
}
export interface AddMedicineTimeToOrderContinue {
  patientid: string;
}
export interface AddMedicineTimeToOrderPageview {
  patientid: string;
}
export interface AddMedicineTimeToOrderSkip {
  patientid: string;
}
export interface AddMedicineVideo {
  patientid: string;
}
export interface AddMedicineWhatDosePageview {
  patientid: string;
}
export interface AddMedicineWhatDoseSelect {
  patientid: string;
}
export interface AddMedicineWhatFormPageview {
  patientid: string;
}
export interface AddMedicineWhatFormSelect {
  patientid: string;
}
export interface AddMedicineWhichBrandContinue {
  patientid: string;
}
export interface AddMedicineWhichBrandDropdown {
  patientid: string;
}
export interface AddMedicineWhichBrandPageview {
  patientid: string;
}
export interface AddMedicineWhichBrandSkip {
  patientid: string;
}
export interface AddMedicineYourMedicineDone {
  patientid: string;
}
export interface AddMedicineYourMedicineIm1Done {
  patientid: string;
}
export interface AddMedicineYourMedicineIm1Help {
  patientit: string;
}
export interface AddMedicineYourMedicineIm1HelpGp {
  patientid: string;
}
export interface AddMedicineYourMedicineIm1HelpPageview {
  patientid: string;
}
export interface AddMedicineYourMedicineIm1Pageview {
  patientid: string;
}
export interface AddMedicineYourMedicinePageview {
  patientid: string;
}
export interface AddOnItemAdd {
  type: string;
}
export interface AddPhonePageview {
  /**
   * the flow this feature was accessed within
   */
  source: string | null;
}
export interface AddonsAdd {
  patientid: string;
  type: string;
}
export interface AddonsConfirm {
  patientid: string;
}
export interface AddonsIgnore {
  patientid: string;
}
export interface AddonsInfoPrompt {
  patientid: string;
  productid: string;
}
export interface AddonsInfoPromptDismiss {
  patientid: string;
}
export interface AddonsInfoPromptToggle {
  patientid: string;
}
export interface AskPatientAccept {
  patientid: string;
}
export interface AskPatientBarcodeRequestedPageview {
  patientid: string;
}
export interface AskPatientChangeAddress {
  patientid: string;
}
export interface AskPatientCollectLloyds {
  ""?: Record<string, any>;
  patientid: string;
}
export interface AskPatientCollectLloydsMap {
  patientid: string;
}
export interface AskPatientConfirm {
  patientid: string;
  revenue?: number;
  value?: number;
}
export interface AskPatientDeliver {
  patientid: string;
}
export interface AskPatientDeliveryMethodPageview {
  patientid: string;
}
export interface AskPatientExemptionAddConfirm {
  patientid: string;
}
export interface AskPatientExemptionAddPageview {
  patientid: string;
}
export interface AskPatientLocalCollect {
  patientid: string;
}
export interface AskPatientPageview {
  patientid: string;
}
export interface AskPatientReject {
  patientid: string;
}
export interface AskPatientRequestBarcode {
  patientid: string;
}
export interface AskPatientReviewPageview {
  patientid: string;
}
export interface AskPatientStart {
  patientid: string;
}
export interface AskPatientUrgentBarcodeRequestedPageview {
  patientid: string;
}
export interface AskPatientUrgentCollectLloyds {
  patientid: string;
}
export interface AskPatientUrgentCollectLloydsMap {
  patientid: string;
}
export interface AskPatientUrgentDeliver {
  patientid: string;
}
export interface AskPatientUrgentDeliveryMethodPageview {
  patientid: string;
}
export interface AskPatientUrgentLocalCollect {
  patientid: string;
}
export interface AskPatientUrgentPrescriptionConfirmedPageview {
  patientid: string;
}
export interface AskPatientUrgentRequestBarcode {
  patientid: string;
}
export interface AskPatientUrgentStart {
  patientid: string;
}
export interface BannerNominateEcho {
  patientid: string;
}
export interface BrandSpecificsBrandWarningPageview {
  patientid: string;
}
export interface BrandSpecificsConfirmPresDropDown {
  patientid: string;
}
export interface BrandSpecificsConfirmPresDropDownIcantake {
  patientid: string;
}
export interface BrandSpecificsMedicineTabPageView {
  patientid: string;
}
export interface BrandSpecificsOrderPresNim1BrandClick {
  Patientid: string;
}
export interface BrandSpecificsOrderPresNim1Pageview {
  patentId: string;
}
export interface BrandSpecificsSelectBrandWarning {
  patientid?: string;
}
export interface CallGp {
  patientid: string;
}
export interface CallLloydspharmacy {
  patientid: string;
}
export interface CancelPillReviewPrompt {
  patientid?: string;
}
export interface CancelPillReviewPromptConfirm {
  patientid?: string;
}
export interface CancelPillReviewPromptKeepBooking {
  patientid?: string;
}
export interface CancelRxCancellationReason {
  /**
   * Array of medication IDs that are being cancelled
   */
  medication_ids: any[];
  patientid: string;
  /**
   * The specific reason a user cancelled e.g CANCELLATION_REASON_PATIENT_NO_LONGER_NEEDS_MEDICATION
   */
  reason?: string;
}
export interface CancelRxConfirmationPageview {
  patientid: string;
}
export interface CancelRxSelectMedicationPageview {
  patientid: string;
}
export interface CannotOrderGetMedicineDeliveredPageview {
  patientid: string;
}
export interface ChangeEmailPageview {
  patientid: string;
  /**
   * the flow this feature was accessed within
   */
  source: string | null;
}
export interface ChangeGpConfirm {
  patientid: string;
}
export interface ChangeGpOrderScreen {
  patientid: string;
}
export interface ChangeGpPageview {
  patientid: string;
}
export interface ChangePhoneConfirm {
  /**
   * the flow this feature was accessed within
   */
  source: string | null;
}
export interface CheckPhoneErrorPageview {
  /**
   * the flow this feature was accessed within
   */
  source: string | null;
}
export interface CheckPhonePageview {
  /**
   * the flow this feature was accessed within
   */
  source: string | null;
}
export interface CollectLloydsAddressConfirm {
  patientid: string;
}
export interface CollectLloydsMap {
  patientid: string;
}
export interface CollectLloydsPageview {
  patientid: string;
}
export interface CollectLloydsSearch {
  patientid: string;
  /**
   * whether any LPs were found - success, no_results, error
   */
  type: string;
}
export interface CollectLloydsSearchError {
  patientid: string;
}
export interface ConnectGpConfirm {
  patientid: string;
}
export interface ConnectGpError {
  patientid: string;
}
export interface ConnectGpPageview {
  patientid: string;
}
export interface ConnectGpSuccess {
  patientid: string;
}
export interface ContactCallLloydsDirect {
  patientid: string;
}
export interface ContactDetailsPageview {
  patientid: Record<string, any>;
}
export interface CookieConsentChanged {
  /**
   * Consented categories, see https://app-uk.onetrust.com/cookies/categorizations?tab=Categories
   */
  consent?: any[] | null;
}
export interface DeliveryMethodBlock {
  patientid: string;
  /**
   * the delivery method being blocked
   */
  type: string;
}
export interface DoseRemindersSummaryPageview {
  patientid: string;
}
export interface DoseRemindersSwitchDate {
  patientid: string;
}
export interface DownloadApp {
  /**
   * the flow this feature was accessed within
   */
  source: string | null;
  /**
   * is it play store or app store
   */
  type: string;
}
export interface DownloadAppClose {
  /**
   * the flow this feature was accessed within
   */
  source: string | null;
}
export interface EditReminder {
  patientid: string;
}
export interface EditReminderConfirm {
  patientid: string;
}
export interface ExemptPremiumDeliveryLearnMore {
  patientid: string;
}
export interface ExemptPremiumDeliveryPaymentFailed {
  patientid: string;
}
export interface ExemptPremiumDeliveryPaymentSuccess {
  ""?: Record<string, any>;
  patientid: string;
}
export interface ExemptPremiumDeliveryPrompt {
  patientid: string;
}
export interface ExemptPremiumDeliveryRejected {
  patientid: string;
}
export interface ExemptPremiumDeliveryReview {
  orderids?: any[] | null;
  patientid: string;
}
export interface ExemptPremiumDeliveryUpgraded {
  patientid: string;
}
export interface ExemptionsPageview {
  patientid?: string;
}
export interface Experiment {
  variant: string;
}
export interface ExternalLink {
  type: string;
}
export interface FirstOrderConfirmed {
  order_ids: any[];
  patientid: string;
  revenue?: number;
  value?: number;
}
export interface HdyhauSurveyComplete {
  /**
   * when the user chooses 'other' as an option and adds free text
   */
  comment?: string;
  /**
   * list of sources selected, split by a double colon ::
   */
  sources: string;
}
export interface HelpArticle {
  patientid: string;
}
export interface HelpArticleAction {
  action_id: string;
  help_id: string;
  patient_id: string;
}
export interface HelpArticlePageview {
  /**
   * id of the help article
   */
  helpid: string;
  patientid: string;
}
export interface HelpContactCall {
  patientid: string;
}
export interface HelpContactMessage {
  patientid: string;
}
export interface HelpContactUsPageview {
  patientid: string;
}
export interface HelpPageview {
  patientid: string;
}
export interface HelpPatientPickerPageview {
  /**
   * The full URL e.g. "https://echo.co.uk/foo/bar?baz=1"
   */
  url?: string;
}
export interface Im1ConnectGp {
  patientid: string;
}
export interface Im1ConnectWithGpManually {
  patientid: string;
}
export interface Im1ConnectWithGpNhsLogin {
  patientid: string;
}
export interface Im1ConnectWithGpPageview {
  patientid: string;
}
export interface Im1UpdateMedicineCallGp {
  patientid: string;
}
export interface Im1UpdateMedicineListPageview {
  patientid: string;
}
export interface IncompleteAccount {
  patientid?: string;
}
export interface LocalCollectAddressConfirm {
  patientid: string;
}
export interface LocalCollectPageview {
  patientid: string;
}
export interface LocalCollectSearchError {
  patientid: string;
}
export interface Login {
  auth_type: string;
}
export interface MarketingUnsubscribeFailurePageview {
  accountid: string;
}
export interface MarketingUnsubscribeSuccessPageview {
  accountid: string;
}
export interface MedicationAddExemption {
  patientid: string;
}
export interface MedicationConfirmPrescription {
  patientid: string;
}
export interface MedicationDetailsPageview {
  patient_medication_id: string;
  patientid: string;
}
export interface MedicationPageview {
  patientid?: string;
}
export interface MedicationPayNow {
  patientid: string;
}
export interface MedicationSupplyChangeConfirm {
  patientid: string;
}
export interface MedicationSupplyPageview {
  patientid: string;
}
export interface MigrationRemindLater {
  patientid: string;
  source: string;
}
export interface N3PIm1ConnectGp {
  patientid: string;
}
export interface NeedMedicationSoonerPrompt {
  patientid: string;
}
export interface NewEmailCancelIntent {
  patientid?: string;
}
export interface NewEmailChangeEmail {
  patientid?: string;
}
export interface NewEmailCheckEmailPageview {
  patientid?: string;
}
export interface NewEmailConfirmedEmailContinue {
  patientid: string;
}
export interface NewEmailEmailTypoFix {
  patientid?: string;
}
export interface NewEmailErrorAccountDuplicate {
  patientid?: string;
}
export interface NewEmailErrorEmailVerification {
  patientid?: string;
}
export interface NewEmailErrorExisting {
  patientid?: string;
}
export interface NewEmailErrorLinkInvalid {
  patientid?: string;
}
export interface NewEmailErrorOther {
  patientid?: string;
}
export interface NewEmailPageview {
  patientid?: string;
}
export interface NewEmailResendEmail {
  patientid?: string;
}
export interface NewEmailSetIntent {
  patientid?: string;
}
export interface NewEmailSpamEmailInfo {
  patientid?: string;
}
export interface NewEmailVerified {
  patientid?: string;
}
export interface NoksOrderConfirmed {
  patientid: string;
}
export interface NoksPopup {
  patientid: string;
}
export interface NoksPopupAddAnotherPerson {
  patientid: string;
}
export interface NoksPopupClose {
  patientid: string;
}
export interface NoksPopupNotNow {
  patientid: string;
}
export interface NominateEchoConfirm {
  patientid: string;
}
export interface NominateEchoPageview {
  patientid: string;
}
export interface OpenIntercom {
  help_id?: string;
  /**
   * source page that the user opens intercom from
   */
  source?: string;
}
export interface PartialDispensingConfirmationPageview {
  patient_id: string;
}
export interface PartialDispensingIntroPageview {
  patient_id: string;
}
export interface PartialDispensingReviewPageview {
  patient_id: string;
}
export interface PatientChosen {
  /**
   * patient currently active (not one being chosen)
   */
  patientid: string;
}
export interface PatientDetailsNominateEchoConfirm {
  patientid: string;
}
export interface PatientDetailsPageview {
  patientid: string;
}
export interface PatientsListOpen {
  patientid: string;
}
export interface PaymentConfirm {
  patientid: string;
}
export interface PaymentConfirmationPageview {
  patientid: string;
}
export interface PaymentFailed {
  patientid: string;
}
export interface PaymentMethodChanged {
  patientid: string;
}
export interface PaymentPremiumDeliveryLearnMore {
  patientid: string;
}
export interface PaymentReviewPageview {
  patientid: string;
}
export interface PaymentShownPremiumDelivery {
  patientid: string;
  /**
   * price of upgrade offered
   */
  shipping_cost: number;
}
export interface PaymentSuccessful {
  patientid: string;
}
export interface PhoneConfirmedPageview {
  /**
   * the flow this feature was accessed within
   */
  source: string | null;
}
export interface PhoneVerifyCall {
  /**
   * the flow this feature was accessed within
   */
  source: string | null;
}
export interface PillReviewBloodPressurePageview {
  patientid?: string;
}
export interface PillReviewBookedPageview {
  patientid?: string;
}
export interface PillReviewCalendarBookingPageview {
  patientid?: string;
}
export interface PillReviewCombinedPrompt {
  patientid?: string;
}
export interface PillReviewNoBloodPressureConfirm {
  patientid?: string;
}
export interface PillReviewNoBloodPressurePageview {
  patientid?: string;
}
export interface PillReviewNoPillScheduleConfirm {
  patientid?: string;
}
export interface PillReviewNoPillSchedulePageview {
  patientid?: string;
}
export interface PillReviewPageview {
  patientid?: string;
}
export interface PillReviewPhoneNumberPageview {
  patientid?: string;
}
export interface PillReviewPillSchedulePageview {
  patientid?: string;
}
export interface PillReviewProgesteronePrompt {
  patientid?: string;
}
export interface RdsRequestCollectLloyds {
  patientid: string;
}
export interface RdsRequestCollectLloydsMap {
  patientid: string;
}
export interface RdsRequestConfirm {
  patientid: string;
}
export interface RdsRequestDeliver {
  patientid: string;
}
export interface RdsRequestDeliveryMethodPageview {
  patientid: string;
}
export interface RdsRequestHealthChange {
  patientid: string;
}
export interface RdsRequestHealthCheckPageview {
  patientid: string;
}
export interface RdsRequestLocalCollect {
  patientid: string;
}
export interface RdsRequestPageview {
  patientid: string;
}
export interface RdsRequestReviewPageview {
  patientid: string;
}
export interface RdsRequestStart {
  patientid: string;
}
export interface RdsRequestWarningModal {
  patientid: string;
}
export interface ReferralsChooseRewardConfirm {
  /**
   * type of reward chosen
   */
  type: string;
}
export interface RemoveExemptionConfirm {
  patientid: string;
}
export interface RemoveMedicationConfirm {
  patientid: string;
}
export interface RequestDetailsPageview {
  patientid: string;
}
export interface RequestHistoryPageview {
  patientid: string;
}
export interface RequestMedicationAddonsPageview {
  patientid: string;
}
export interface RequestMedicationChangeAddress {
  patientid: string;
}
export interface RequestMedicationCollectLloyds {
  patientid: string;
}
export interface RequestMedicationCollectLloydsMap {
  patientid: string;
}
export interface RequestMedicationConfirm {
  patientid: string;
  revenue?: number;
  value?: number;
}
export interface RequestMedicationDeliver {
  patientid: string;
}
export interface RequestMedicationDeliveryMethodPageview {
  patientid: string;
}
export interface RequestMedicationEditAddressPageview {
  patientid: string;
}
export interface RequestMedicationExemptionAddConfirm {
  patientid: string;
}
export interface RequestMedicationExemptionAddPageview {
  patientid: string;
}
export interface RequestMedicationLocalCollect {
  patientid: string;
}
export interface RequestMedicationPageview {
  /**
   * patientid of the medications being shown
   */
  patientid: string;
}
export interface RequestMedicationPartialAccept {
  patientid: string;
}
export interface RequestMedicationPartialPrompt {
  patientid: string;
}
export interface RequestMedicationPartialReject {
  patientid: string;
}
export interface RequestMedicationReviewPageview {
  patientid: string;
}
export interface RequestMedicationStart {
  patientid: string;
}
export interface RequestMedicationUnavailableLearnMore {
  patientid: string;
}
export interface RequestMedicationUnavailablePrompt {
  patientid: string;
}
export interface ResendPhone {
  /**
   * the flow this feature was accessed within
   */
  source: string | null;
}
export interface ReturnRxCompletePageview {
  patientid: string;
}
export interface ReturnRxIntroPageview {
  patientid: string;
}
export interface ReturnRxPatientPickerPageview {
  patientid: string;
}
export interface ReturnRxReviewPageview {
  patientid: string;
}
export interface ReturnRxSelectPageview {
  patientid: string;
}
export interface SelfHelpContactUs {
  /**
   * Patient chooses to call or chat to us
   */
  medium: string;
  patientid: string;
}
export interface SendPartialConfirm {
  patientid: string;
}
export interface SignupComplete {
  auth_type: string;
}
export interface SignupErrorPdsFail {
  type: string;
}
export interface SignupN3PConnectGpPageview {
  patientid: string;
}
export interface SignupN3PConnectManuallyContinue {
  patientid: string;
}
export interface SignupN3PNhsLoginContinue {
  patientid: string;
}
export interface SignupPdsFailCall {
  patientid: string;
}
export interface SignupPdsFailConnectGpConfirm {
  patientid: string;
}
export interface SignupPdsFailConnectGpPageview {
  patientid: string;
}
export interface SignupPdsFailConnectManuallyContinue {
  patientid: string;
}
export interface SignupPdsFailNhsLoginContinue {
  patientid: string;
}
export interface SignupPdsFailPageview {
  patientid: string;
}
export interface SignupPdsFailSendMessage {
  patientid: string;
}
export interface SignupPdsTraceSuccess {
  type: string;
}
export interface SignupStarted {
  auth_type: string;
}
export interface SponsoredcontentDemand01Close {
  /**
   * Article which was active when the modal was closed
   */
  article_slug?: string;
  /**
	 * ID of the patient interacting with the experiment

	 */
  patientid: string;
  /**
   * Medicine which prompted showing this article
   */
  vtm_name?: string;
}
export interface SponsoredcontentDemand01ListMedicines {
  /**
   * Articles shown to the patient
   */
  articles_shown: any[];
  /**
   * ID of the patient interacting with the experiment
   */
  patientid: string;
}
export interface SponsoredcontentDemand01NotInterested {
  /**
   * ID of the patient interacting with the experiment
   */
  patientid: string;
}
export interface SponsoredcontentDemand01Prompt {
  /**
   * ID of the patient interacting with the experiment
   */
  patientId: string;
}
export interface SponsoredcontentDemand01Read {
  /**
   * Article being read
   */
  article_slug: string;
  /**
   * ID of the patient interacting with the experiment
   */
  patientid: string;
  /**
   * How far the patient scrolled the article
   */
  scroll_depth: string;
  /**
   * Medicine linked to this article
   */
  vtm_name: string;
}
export interface TrackDelivery {
  patientid: string;
}
export interface UpdateNextDueDatePageview {
  patientid: string;
}
export interface VerifyEmailConfirm {
  patientid: Record<string, any>;
  source: string | null;
}
export interface VerifyEmailConfirmationPageview {
  patientid: string;
}
export interface VitaminsTestClosed {
  /**
   * Location experiment was shown
   */
  location?: string;
  /**
   * ID of the patient interacting with the experiment
   */
  patientid: string;
  /**
   * stage of the modal flow they closed the experiment
   */
  step?: string;
}
export interface VitaminsTestConfirm {
  /**
   * Log if the patient answers they are interested or not in vitamins and supplements
   */
  interest?: boolean;
  /**
   * Location experiment was shown
   */
  location?: string;
  /**
   * ID of the patient interacting with the experiment
   */
  patientid: string;
}
export interface VitaminsTestOpened {
  /**
   * Location experiment was shown
   */
  location?: string;
  /**
   * ID of the patient interacting with the experiment
   */
  patientid: string;
}
export interface VitaminsTestPrompt {
  /**
   * Location experiment was shown
   */
  location?: string;
  /**
   * ID of the patient interacting with the experiment
   */
  patientid: string;
}
export interface VitaminsTestSurvey {
  /**
   * Location experiment was shown
   */
  location?: string;
  /**
   * ID of the patient interacting with the experiment
   */
  patientid: string;
}
export interface WatchVideo {
  /**
   * CMS id of the CTA
   */
  id: string;
  /**
   * CMS name of the CTA
   */
  name: string;
}

export type ViolationHandler = (
  message: Record<string, any>,
  violations: any[]
) => void;

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
  message,
  violations
) => {
  const msg = JSON.stringify(
    {
      type: "Typewriter JSON Schema Validation Error",
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        "Tracking Plan spec.",
      errors: violations
    },
    undefined,
    2
  );

  console.warn(msg);
};

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
  return window.analytics;
};

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.analytics.
   */
  analytics?: SegmentAnalytics.AnalyticsJS;
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler;
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
  analytics = options.analytics
    ? () => options.analytics || window.analytics
    : analytics;
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: "typescript",
        version: "7.4.1"
      }
    }
  };
}

/**
 * @typedef SeOrderMedicineDeliveryGoBackClick
 * @property {string} [patientid] -
 */
/**
 * @typedef SeOrderMedicineDeliveryLpClick
 * @property {string} [patientid] -
 */
/**
 * @typedef SeOrderMedicineDeliveryLpClickCollect
 * @property {string} [patientid] -
 */
/**
 * @typedef SeOrderMedicineDeliveryUnavailableClick
 * @property {string} [patientid] -
 */
/**
 * @typedef SeOrderMedicineEstimateButtonClick
 * @property {string} [patientid] -
 * @property {Record<string, any>} [stockEstimateStatus] -
 */
/**
 * @typedef SeOrderMedicineFindLloydsDismissModal
 * @property {string} [patientid] -
 */
/**
 * @typedef SeOrderMedicineFindLloydsGoBackClick
 * @property {string} [patientid] -
 */
/**
 * @typedef SeOrderMedicineFindLloydsPageView
 * @property {string} [patientid] -
 */
/**
 * @typedef SeOrderMedicinePageView
 * @property {string} [patientid] -
 * @property {Record<string, any>} [stockEstimateStatus] -
 */
/**
 * @typedef SeOrderMedicineSelectClick
 * @property {string} [patientid] -
 * @property {Record<string, any>} [stockEstimateStatus] -
 */
/**
 * @typedef SeOrderMedicineUnavailableDeliveryPageView
 * @property {string} [patientid] -
 */
/**
 * @typedef SeOrderMedicineUnavailableForDeliveryBannerClick
 * @property {string} [patientid] -
 */
/**
 * @typedef SeOrderMedicineWaitingForStockBannerClick
 * @property {string} [patientid] -
 */
/**
 * @typedef AccountPreferredNameConfirm
 * @property {string} patientid -
 */
/**
 * @typedef AccountPreferredNamePageview
 * @property {string} patientid -
 */
/**
 * @typedef AddAnotherPerson
 * @property {string} patientid -
 */
/**
 * @typedef AddAnotherPersonSidebar
 * @property {string} patientid -
 */
/**
 * @typedef AddExemptionConfirm
 * @property {string} [patientid] -
 */
/**
 * @typedef AddExemptionExpiryPageview
 * @property {string} [patientid] -
 */
/**
 * @typedef AddExemptionsPageview
 * @property {string} [patientid] -
 */
/**
 * @typedef AddMedicationConfirm
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicationDueDatePageview
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicationPageview
 * @property {string} [patientid] -
 */
/**
 * @typedef AddMedicationPickStrength
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineAddNewMedicine
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineAndroidDownload
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineAnyLeftNo
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineAnyLeftPageview
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineAnyLeftYes
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineAppleDownload
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineAppliancePageview
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineExit
 * @property {string} [patientid] -
 */
/**
 * @typedef AddMedicineHowMuch
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineHowMuchErrorPageview
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineHowMuchPageview
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineNhsLogin
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineNhsLoginContinue
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineNhsLoginPageview
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineNotTimeToOrder
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineNotTimeToOrderPageview
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicinePageview
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineSafeCustodyPageview
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineSearch
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineSearchNoResultsPageview
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineSearchPageview
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineSearchResultsPageview
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineSearchSelect
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineSkip
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineTimeToOrderContinue
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineTimeToOrderPageview
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineTimeToOrderSkip
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineVideo
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineWhatDosePageview
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineWhatDoseSelect
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineWhatFormPageview
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineWhatFormSelect
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineWhichBrandContinue
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineWhichBrandDropdown
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineWhichBrandPageview
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineWhichBrandSkip
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineYourMedicineDone
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineYourMedicineIm1Done
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineYourMedicineIm1Help
 * @property {string} patientit -
 */
/**
 * @typedef AddMedicineYourMedicineIm1HelpGp
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineYourMedicineIm1HelpPageview
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineYourMedicineIm1Pageview
 * @property {string} patientid -
 */
/**
 * @typedef AddMedicineYourMedicinePageview
 * @property {string} patientid -
 */
/**
 * @typedef AddOnItemAdd
 * @property {string} type -
 */
/**
 * @typedef AddPhonePageview
 * @property {string | null} source - the flow this feature was accessed within
 */
/**
 * @typedef AddonsAdd
 * @property {string} patientid -
 * @property {string} type -
 */
/**
 * @typedef AddonsConfirm
 * @property {string} patientid -
 */
/**
 * @typedef AddonsIgnore
 * @property {string} patientid -
 */
/**
 * @typedef AddonsInfoPrompt
 * @property {string} patientid -
 * @property {string} productid -
 */
/**
 * @typedef AddonsInfoPromptDismiss
 * @property {string} patientid -
 */
/**
 * @typedef AddonsInfoPromptToggle
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientAccept
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientBarcodeRequestedPageview
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientChangeAddress
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientCollectLloyds
 * @property {Record<string, any>} [] -
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientCollectLloydsMap
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientConfirm
 * @property {string} patientid -
 * @property {number} [revenue] -
 * @property {number} [value] -
 */
/**
 * @typedef AskPatientDeliver
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientDeliveryMethodPageview
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientExemptionAddConfirm
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientExemptionAddPageview
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientLocalCollect
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientPageview
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientReject
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientRequestBarcode
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientReviewPageview
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientStart
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientUrgentBarcodeRequestedPageview
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientUrgentCollectLloyds
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientUrgentCollectLloydsMap
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientUrgentDeliver
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientUrgentDeliveryMethodPageview
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientUrgentLocalCollect
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientUrgentPrescriptionConfirmedPageview
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientUrgentRequestBarcode
 * @property {string} patientid -
 */
/**
 * @typedef AskPatientUrgentStart
 * @property {string} patientid -
 */
/**
 * @typedef BannerNominateEcho
 * @property {string} patientid -
 */
/**
 * @typedef BrandSpecificsBrandWarningPageview
 * @property {string} patientid -
 */
/**
 * @typedef BrandSpecificsConfirmPresDropDown
 * @property {string} patientid -
 */
/**
 * @typedef BrandSpecificsConfirmPresDropDownIcantake
 * @property {string} patientid -
 */
/**
 * @typedef BrandSpecificsMedicineTabPageView
 * @property {string} patientid -
 */
/**
 * @typedef BrandSpecificsOrderPresNim1BrandClick
 * @property {string} Patientid -
 */
/**
 * @typedef BrandSpecificsOrderPresNim1Pageview
 * @property {string} patentId -
 */
/**
 * @typedef BrandSpecificsSelectBrandWarning
 * @property {string} [patientid] -
 */
/**
 * @typedef CallGp
 * @property {string} patientid -
 */
/**
 * @typedef CallLloydspharmacy
 * @property {string} patientid -
 */
/**
 * @typedef CancelPillReviewPrompt
 * @property {string} [patientid] -
 */
/**
 * @typedef CancelPillReviewPromptConfirm
 * @property {string} [patientid] -
 */
/**
 * @typedef CancelPillReviewPromptKeepBooking
 * @property {string} [patientid] -
 */
/**
 * @typedef CancelRxCancellationReason
 * @property {any[]} medication_ids - Array of medication IDs that are being cancelled
 * @property {string} patientid -
 * @property {string} [reason] - The specific reason a user cancelled e.g CANCELLATION_REASON_PATIENT_NO_LONGER_NEEDS_MEDICATION
 */
/**
 * @typedef CancelRxConfirmationPageview
 * @property {string} patientid -
 */
/**
 * @typedef CancelRxSelectMedicationPageview
 * @property {string} patientid -
 */
/**
 * @typedef CannotOrderGetMedicineDeliveredPageview
 * @property {string} patientid -
 */
/**
 * @typedef ChangeEmailPageview
 * @property {string} patientid -
 * @property {string | null} source - the flow this feature was accessed within
 */
/**
 * @typedef ChangeGpConfirm
 * @property {string} patientid -
 */
/**
 * @typedef ChangeGpOrderScreen
 * @property {string} patientid -
 */
/**
 * @typedef ChangeGpPageview
 * @property {string} patientid -
 */
/**
 * @typedef ChangePhoneConfirm
 * @property {string | null} source - the flow this feature was accessed within
 */
/**
 * @typedef CheckPhoneErrorPageview
 * @property {string | null} source - the flow this feature was accessed within
 */
/**
 * @typedef CheckPhonePageview
 * @property {string | null} source - the flow this feature was accessed within
 */
/**
 * @typedef CollectLloydsAddressConfirm
 * @property {string} patientid -
 */
/**
 * @typedef CollectLloydsMap
 * @property {string} patientid -
 */
/**
 * @typedef CollectLloydsPageview
 * @property {string} patientid -
 */
/**
 * @typedef CollectLloydsSearch
 * @property {string} patientid -
 * @property {string} type - whether any LPs were found - success, no_results, error
 */
/**
 * @typedef CollectLloydsSearchError
 * @property {string} patientid -
 */
/**
 * @typedef ConnectGpConfirm
 * @property {string} patientid -
 */
/**
 * @typedef ConnectGpError
 * @property {string} patientid -
 */
/**
 * @typedef ConnectGpPageview
 * @property {string} patientid -
 */
/**
 * @typedef ConnectGpSuccess
 * @property {string} patientid -
 */
/**
 * @typedef ContactCallLloydsDirect
 * @property {string} patientid -
 */
/**
 * @typedef ContactDetailsPageview
 * @property {Record<string, any>} patientid -
 */
/**
 * @typedef CookieConsentChanged
 * @property {any[] | null} [consent] - Consented categories, see https://app-uk.onetrust.com/cookies/categorizations?tab=Categories
 */
/**
 * @typedef DeliveryMethodBlock
 * @property {string} patientid -
 * @property {string} type - the delivery method being blocked
 */
/**
 * @typedef DoseRemindersSummaryPageview
 * @property {string} patientid -
 */
/**
 * @typedef DoseRemindersSwitchDate
 * @property {string} patientid -
 */
/**
 * @typedef DownloadApp
 * @property {string | null} source - the flow this feature was accessed within
 * @property {string} type - is it play store or app store
 */
/**
 * @typedef DownloadAppClose
 * @property {string | null} source - the flow this feature was accessed within
 */
/**
 * @typedef EditReminder
 * @property {string} patientid -
 */
/**
 * @typedef EditReminderConfirm
 * @property {string} patientid -
 */
/**
 * @typedef ExemptPremiumDeliveryLearnMore
 * @property {string} patientid -
 */
/**
 * @typedef ExemptPremiumDeliveryPaymentFailed
 * @property {string} patientid -
 */
/**
 * @typedef ExemptPremiumDeliveryPaymentSuccess
 * @property {Record<string, any>} [] -
 * @property {string} patientid -
 */
/**
 * @typedef ExemptPremiumDeliveryPrompt
 * @property {string} patientid -
 */
/**
 * @typedef ExemptPremiumDeliveryRejected
 * @property {string} patientid -
 */
/**
 * @typedef ExemptPremiumDeliveryReview
 * @property {any[] | null} [orderids] -
 * @property {string} patientid -
 */
/**
 * @typedef ExemptPremiumDeliveryUpgraded
 * @property {string} patientid -
 */
/**
 * @typedef ExemptionsPageview
 * @property {string} [patientid] -
 */
/**
 * @typedef Experiment
 * @property {string} variant -
 */
/**
 * @typedef ExternalLink
 * @property {string} type -
 */
/**
 * @typedef FirstOrderConfirmed
 * @property {any[]} order_ids -
 * @property {string} patientid -
 * @property {number} [revenue] -
 * @property {number} [value] -
 */
/**
 * @typedef HdyhauSurveyComplete
 * @property {string} [comment] - when the user chooses 'other' as an option and adds free text
 * @property {string} sources - list of sources selected, split by a double colon ::
 */
/**
 * @typedef HelpArticle
 * @property {string} patientid -
 */
/**
 * @typedef HelpArticleAction
 * @property {string} action_id -
 * @property {string} help_id -
 * @property {string} patient_id -
 */
/**
 * @typedef HelpArticlePageview
 * @property {string} helpid - id of the help article
 * @property {string} patientid -
 */
/**
 * @typedef HelpContactCall
 * @property {string} patientid -
 */
/**
 * @typedef HelpContactMessage
 * @property {string} patientid -
 */
/**
 * @typedef HelpContactUsPageview
 * @property {string} patientid -
 */
/**
 * @typedef HelpPageview
 * @property {string} patientid -
 */
/**
 * @typedef HelpPatientPickerPageview
 * @property {string} [url] - The full URL e.g. "https://echo.co.uk/foo/bar?baz=1"
 */
/**
 * @typedef Im1ConnectGp
 * @property {string} patientid -
 */
/**
 * @typedef Im1ConnectWithGpManually
 * @property {string} patientid -
 */
/**
 * @typedef Im1ConnectWithGpNhsLogin
 * @property {string} patientid -
 */
/**
 * @typedef Im1ConnectWithGpPageview
 * @property {string} patientid -
 */
/**
 * @typedef Im1UpdateMedicineCallGp
 * @property {string} patientid -
 */
/**
 * @typedef Im1UpdateMedicineListPageview
 * @property {string} patientid -
 */
/**
 * @typedef IncompleteAccount
 * @property {string} [patientid] -
 */
/**
 * @typedef LocalCollectAddressConfirm
 * @property {string} patientid -
 */
/**
 * @typedef LocalCollectPageview
 * @property {string} patientid -
 */
/**
 * @typedef LocalCollectSearchError
 * @property {string} patientid -
 */
/**
 * @typedef Login
 * @property {string} auth_type -
 */
/**
 * @typedef MarketingUnsubscribeFailurePageview
 * @property {string} accountid -
 */
/**
 * @typedef MarketingUnsubscribeSuccessPageview
 * @property {string} accountid -
 */
/**
 * @typedef MedicationAddExemption
 * @property {string} patientid -
 */
/**
 * @typedef MedicationConfirmPrescription
 * @property {string} patientid -
 */
/**
 * @typedef MedicationDetailsPageview
 * @property {string} patient_medication_id -
 * @property {string} patientid -
 */
/**
 * @typedef MedicationPageview
 * @property {string} [patientid] -
 */
/**
 * @typedef MedicationPayNow
 * @property {string} patientid -
 */
/**
 * @typedef MedicationSupplyChangeConfirm
 * @property {string} patientid -
 */
/**
 * @typedef MedicationSupplyPageview
 * @property {string} patientid -
 */
/**
 * @typedef MigrationRemindLater
 * @property {string} patientid -
 * @property {string} source -
 */
/**
 * @typedef N3PIm1ConnectGp
 * @property {string} patientid -
 */
/**
 * @typedef NeedMedicationSoonerPrompt
 * @property {string} patientid -
 */
/**
 * @typedef NewEmailCancelIntent
 * @property {string} [patientid] -
 */
/**
 * @typedef NewEmailChangeEmail
 * @property {string} [patientid] -
 */
/**
 * @typedef NewEmailCheckEmailPageview
 * @property {string} [patientid] -
 */
/**
 * @typedef NewEmailConfirmedEmailContinue
 * @property {string} patientid -
 */
/**
 * @typedef NewEmailEmailTypoFix
 * @property {string} [patientid] -
 */
/**
 * @typedef NewEmailErrorAccountDuplicate
 * @property {string} [patientid] -
 */
/**
 * @typedef NewEmailErrorEmailVerification
 * @property {string} [patientid] -
 */
/**
 * @typedef NewEmailErrorExisting
 * @property {string} [patientid] -
 */
/**
 * @typedef NewEmailErrorLinkInvalid
 * @property {string} [patientid] -
 */
/**
 * @typedef NewEmailErrorOther
 * @property {string} [patientid] -
 */
/**
 * @typedef NewEmailPageview
 * @property {string} [patientid] -
 */
/**
 * @typedef NewEmailResendEmail
 * @property {string} [patientid] -
 */
/**
 * @typedef NewEmailSetIntent
 * @property {string} [patientid] -
 */
/**
 * @typedef NewEmailSpamEmailInfo
 * @property {string} [patientid] -
 */
/**
 * @typedef NewEmailVerified
 * @property {string} [patientid] -
 */
/**
 * @typedef NoksOrderConfirmed
 * @property {string} patientid -
 */
/**
 * @typedef NoksPopup
 * @property {string} patientid -
 */
/**
 * @typedef NoksPopupAddAnotherPerson
 * @property {string} patientid -
 */
/**
 * @typedef NoksPopupClose
 * @property {string} patientid -
 */
/**
 * @typedef NoksPopupNotNow
 * @property {string} patientid -
 */
/**
 * @typedef NominateEchoConfirm
 * @property {string} patientid -
 */
/**
 * @typedef NominateEchoPageview
 * @property {string} patientid -
 */
/**
 * @typedef OpenIntercom
 * @property {string} [help_id] -
 * @property {string} [source] - source page that the user opens intercom from
 */
/**
 * @typedef PartialDispensingConfirmationPageview
 * @property {string} patient_id -
 */
/**
 * @typedef PartialDispensingIntroPageview
 * @property {string} patient_id -
 */
/**
 * @typedef PartialDispensingReviewPageview
 * @property {string} patient_id -
 */
/**
 * @typedef PatientChosen
 * @property {string} patientid - patient currently active (not one being chosen)
 */
/**
 * @typedef PatientDetailsNominateEchoConfirm
 * @property {string} patientid -
 */
/**
 * @typedef PatientDetailsPageview
 * @property {string} patientid -
 */
/**
 * @typedef PatientsListOpen
 * @property {string} patientid -
 */
/**
 * @typedef PaymentConfirm
 * @property {string} patientid -
 */
/**
 * @typedef PaymentConfirmationPageview
 * @property {string} patientid -
 */
/**
 * @typedef PaymentFailed
 * @property {string} patientid -
 */
/**
 * @typedef PaymentMethodChanged
 * @property {string} patientid -
 */
/**
 * @typedef PaymentPremiumDeliveryLearnMore
 * @property {string} patientid -
 */
/**
 * @typedef PaymentReviewPageview
 * @property {string} patientid -
 */
/**
 * @typedef PaymentShownPremiumDelivery
 * @property {string} patientid -
 * @property {number} shipping_cost - price of upgrade offered
 */
/**
 * @typedef PaymentSuccessful
 * @property {string} patientid -
 */
/**
 * @typedef PhoneConfirmedPageview
 * @property {string | null} source - the flow this feature was accessed within
 */
/**
 * @typedef PhoneVerifyCall
 * @property {string | null} source - the flow this feature was accessed within
 */
/**
 * @typedef PillReviewBloodPressurePageview
 * @property {string} [patientid] -
 */
/**
 * @typedef PillReviewBookedPageview
 * @property {string} [patientid] -
 */
/**
 * @typedef PillReviewCalendarBookingPageview
 * @property {string} [patientid] -
 */
/**
 * @typedef PillReviewCombinedPrompt
 * @property {string} [patientid] -
 */
/**
 * @typedef PillReviewNoBloodPressureConfirm
 * @property {string} [patientid] -
 */
/**
 * @typedef PillReviewNoBloodPressurePageview
 * @property {string} [patientid] -
 */
/**
 * @typedef PillReviewNoPillScheduleConfirm
 * @property {string} [patientid] -
 */
/**
 * @typedef PillReviewNoPillSchedulePageview
 * @property {string} [patientid] -
 */
/**
 * @typedef PillReviewPageview
 * @property {string} [patientid] -
 */
/**
 * @typedef PillReviewPhoneNumberPageview
 * @property {string} [patientid] -
 */
/**
 * @typedef PillReviewPillSchedulePageview
 * @property {string} [patientid] -
 */
/**
 * @typedef PillReviewProgesteronePrompt
 * @property {string} [patientid] -
 */
/**
 * @typedef RdsRequestCollectLloyds
 * @property {string} patientid -
 */
/**
 * @typedef RdsRequestCollectLloydsMap
 * @property {string} patientid -
 */
/**
 * @typedef RdsRequestConfirm
 * @property {string} patientid -
 */
/**
 * @typedef RdsRequestDeliver
 * @property {string} patientid -
 */
/**
 * @typedef RdsRequestDeliveryMethodPageview
 * @property {string} patientid -
 */
/**
 * @typedef RdsRequestHealthChange
 * @property {string} patientid -
 */
/**
 * @typedef RdsRequestHealthCheckPageview
 * @property {string} patientid -
 */
/**
 * @typedef RdsRequestLocalCollect
 * @property {string} patientid -
 */
/**
 * @typedef RdsRequestPageview
 * @property {string} patientid -
 */
/**
 * @typedef RdsRequestReviewPageview
 * @property {string} patientid -
 */
/**
 * @typedef RdsRequestStart
 * @property {string} patientid -
 */
/**
 * @typedef RdsRequestWarningModal
 * @property {string} patientid -
 */
/**
 * @typedef ReferralsChooseRewardConfirm
 * @property {string} type - type of reward chosen
 */
/**
 * @typedef RemoveExemptionConfirm
 * @property {string} patientid -
 */
/**
 * @typedef RemoveMedicationConfirm
 * @property {string} patientid -
 */
/**
 * @typedef RequestDetailsPageview
 * @property {string} patientid -
 */
/**
 * @typedef RequestHistoryPageview
 * @property {string} patientid -
 */
/**
 * @typedef RequestMedicationAddonsPageview
 * @property {string} patientid -
 */
/**
 * @typedef RequestMedicationChangeAddress
 * @property {string} patientid -
 */
/**
 * @typedef RequestMedicationCollectLloyds
 * @property {string} patientid -
 */
/**
 * @typedef RequestMedicationCollectLloydsMap
 * @property {string} patientid -
 */
/**
 * @typedef RequestMedicationConfirm
 * @property {string} patientid -
 * @property {number} [revenue] -
 * @property {number} [value] -
 */
/**
 * @typedef RequestMedicationDeliver
 * @property {string} patientid -
 */
/**
 * @typedef RequestMedicationDeliveryMethodPageview
 * @property {string} patientid -
 */
/**
 * @typedef RequestMedicationEditAddressPageview
 * @property {string} patientid -
 */
/**
 * @typedef RequestMedicationExemptionAddConfirm
 * @property {string} patientid -
 */
/**
 * @typedef RequestMedicationExemptionAddPageview
 * @property {string} patientid -
 */
/**
 * @typedef RequestMedicationLocalCollect
 * @property {string} patientid -
 */
/**
 * @typedef RequestMedicationPageview
 * @property {string} patientid - patientid of the medications being shown
 */
/**
 * @typedef RequestMedicationPartialAccept
 * @property {string} patientid -
 */
/**
 * @typedef RequestMedicationPartialPrompt
 * @property {string} patientid -
 */
/**
 * @typedef RequestMedicationPartialReject
 * @property {string} patientid -
 */
/**
 * @typedef RequestMedicationReviewPageview
 * @property {string} patientid -
 */
/**
 * @typedef RequestMedicationStart
 * @property {string} patientid -
 */
/**
 * @typedef RequestMedicationUnavailableLearnMore
 * @property {string} patientid -
 */
/**
 * @typedef RequestMedicationUnavailablePrompt
 * @property {string} patientid -
 */
/**
 * @typedef ResendPhone
 * @property {string | null} source - the flow this feature was accessed within
 */
/**
 * @typedef ReturnRxCompletePageview
 * @property {string} patientid -
 */
/**
 * @typedef ReturnRxIntroPageview
 * @property {string} patientid -
 */
/**
 * @typedef ReturnRxPatientPickerPageview
 * @property {string} patientid -
 */
/**
 * @typedef ReturnRxReviewPageview
 * @property {string} patientid -
 */
/**
 * @typedef ReturnRxSelectPageview
 * @property {string} patientid -
 */
/**
 * @typedef SelfHelpContactUs
 * @property {string} medium - Patient chooses to call or chat to us
 * @property {string} patientid -
 */
/**
 * @typedef SendPartialConfirm
 * @property {string} patientid -
 */
/**
 * @typedef SignupComplete
 * @property {string} auth_type -
 */
/**
 * @typedef SignupErrorPdsFail
 * @property {string} type -
 */
/**
 * @typedef SignupN3PConnectGpPageview
 * @property {string} patientid -
 */
/**
 * @typedef SignupN3PConnectManuallyContinue
 * @property {string} patientid -
 */
/**
 * @typedef SignupN3PNhsLoginContinue
 * @property {string} patientid -
 */
/**
 * @typedef SignupPdsFailCall
 * @property {string} patientid -
 */
/**
 * @typedef SignupPdsFailConnectGpConfirm
 * @property {string} patientid -
 */
/**
 * @typedef SignupPdsFailConnectGpPageview
 * @property {string} patientid -
 */
/**
 * @typedef SignupPdsFailConnectManuallyContinue
 * @property {string} patientid -
 */
/**
 * @typedef SignupPdsFailNhsLoginContinue
 * @property {string} patientid -
 */
/**
 * @typedef SignupPdsFailPageview
 * @property {string} patientid -
 */
/**
 * @typedef SignupPdsFailSendMessage
 * @property {string} patientid -
 */
/**
 * @typedef SignupPdsTraceSuccess
 * @property {string} type -
 */
/**
 * @typedef SignupStarted
 * @property {string} auth_type -
 */
/**
 * @typedef SponsoredcontentDemand01Close
 * @property {string} [article_slug] - Article which was active when the modal was closed
 * @property {string} patientid - ID of the patient interacting with the experiment

 * @property {string} [vtm_name] - Medicine which prompted showing this article
 */
/**
 * @typedef SponsoredcontentDemand01ListMedicines
 * @property {any[]} articles_shown - Articles shown to the patient
 * @property {string} patientid - ID of the patient interacting with the experiment
 */
/**
 * @typedef SponsoredcontentDemand01NotInterested
 * @property {string} patientid - ID of the patient interacting with the experiment
 */
/**
 * @typedef SponsoredcontentDemand01Prompt
 * @property {string} patientId - ID of the patient interacting with the experiment
 */
/**
 * @typedef SponsoredcontentDemand01Read
 * @property {string} article_slug - Article being read
 * @property {string} patientid - ID of the patient interacting with the experiment
 * @property {string} scroll_depth - How far the patient scrolled the article
 * @property {string} vtm_name - Medicine linked to this article
 */
/**
 * @typedef TrackDelivery
 * @property {string} patientid -
 */
/**
 * @typedef UpdateNextDueDatePageview
 * @property {string} patientid -
 */
/**
 * @typedef VerifyEmailConfirm
 * @property {Record<string, any>} patientid -
 * @property {string | null} source -
 */
/**
 * @typedef VerifyEmailConfirmationPageview
 * @property {string} patientid -
 */
/**
 * @typedef VitaminsTestClosed
 * @property {string} [location] - Location experiment was shown
 * @property {string} patientid - ID of the patient interacting with the experiment
 * @property {string} [step] - stage of the modal flow they closed the experiment
 */
/**
 * @typedef VitaminsTestConfirm
 * @property {boolean} [interest] - Log if the patient answers they are interested or not in vitamins and supplements
 * @property {string} [location] - Location experiment was shown
 * @property {string} patientid - ID of the patient interacting with the experiment
 */
/**
 * @typedef VitaminsTestOpened
 * @property {string} [location] - Location experiment was shown
 * @property {string} patientid - ID of the patient interacting with the experiment
 */
/**
 * @typedef VitaminsTestPrompt
 * @property {string} [location] - Location experiment was shown
 * @property {string} patientid - ID of the patient interacting with the experiment
 */
/**
 * @typedef VitaminsTestSurvey
 * @property {string} [location] - Location experiment was shown
 * @property {string} patientid - ID of the patient interacting with the experiment
 */
/**
 * @typedef WatchVideo
 * @property {string} id - CMS id of the CTA
 * @property {string} name - CMS name of the CTA
 */

/**
 * Patient clicks on the ‘Go Back’ button
 *
 * @param {SeOrderMedicineDeliveryGoBackClick} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function seOrderMedicineDeliveryGoBackClick(
  props?: SeOrderMedicineDeliveryGoBackClick,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "SE_order_medicine_delivery_go_back_click",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Patient clicks on the ‘Find a Lloyds Pharmacy’ button
 *
 * @param {SeOrderMedicineDeliveryLpClick} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function seOrderMedicineDeliveryLpClick(
  props?: SeOrderMedicineDeliveryLpClick,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "SE_order_medicine_delivery_lp_click",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Patient clicks on the ‘Collect at this store’ button
 *
 * @param {SeOrderMedicineDeliveryLpClickCollect} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function seOrderMedicineDeliveryLpClickCollect(
  props?: SeOrderMedicineDeliveryLpClickCollect,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "SE_order_medicine_delivery_lp_click_collect",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Track If a patient clicks on the ‘Delivery  is unavailable’ banner
 *
 * @param {SeOrderMedicineDeliveryUnavailableClick} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function seOrderMedicineDeliveryUnavailableClick(
  props?: SeOrderMedicineDeliveryUnavailableClick,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "SE_order_medicine_delivery_unavailable_click",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Track if a patient clicks in to the stock estimate button for more information
 *
 * @param {SeOrderMedicineEstimateButtonClick} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function seOrderMedicineEstimateButtonClick(
  props?: SeOrderMedicineEstimateButtonClick,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "SE_order_medicine_estimate_button_click",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Patient dismissed the stock warnings modal, by any means.
 *
 * @param {SeOrderMedicineFindLloydsDismissModal} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function seOrderMedicineFindLloydsDismissModal(
  props?: SeOrderMedicineFindLloydsDismissModal,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "SE_order_medicine_find_lloyds_dismiss_modal",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Patient clicks on the ‘Go back’ button on the pop up
 *
 * @param {SeOrderMedicineFindLloydsGoBackClick} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function seOrderMedicineFindLloydsGoBackClick(
  props?: SeOrderMedicineFindLloydsGoBackClick,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "SE_order_medicine_find_lloyds_go_back_click",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Track patients reaching the find lloyds pharmacy page
 *
 * @param {SeOrderMedicineFindLloydsPageView} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function seOrderMedicineFindLloydsPageView(
  props?: SeOrderMedicineFindLloydsPageView,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "SE_order_medicine_find_lloyds_page_view",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Track when a patient reaches the order medicine page
 *
 * @param {SeOrderMedicinePageView} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function seOrderMedicinePageView(
  props?: SeOrderMedicinePageView,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "SE_order_medicine_page_view",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Track when a patient selects to order a medicine
 *
 * @param {SeOrderMedicineSelectClick} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function seOrderMedicineSelectClick(
  props?: SeOrderMedicineSelectClick,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "SE_order_medicine_select_click",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Track when patient reaches the unavailable for delivery page
 *
 * @param {SeOrderMedicineUnavailableDeliveryPageView} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function seOrderMedicineUnavailableDeliveryPageView(
  props?: SeOrderMedicineUnavailableDeliveryPageView,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "SE_order_medicine_unavailable_delivery_page_view",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Track if a patient clicks in to the ‘Ordering medicine that’s unavailable for delivery?’ banner
 *
 * @param {SeOrderMedicineUnavailableForDeliveryBannerClick} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function seOrderMedicineUnavailableForDeliveryBannerClick(
  props?: SeOrderMedicineUnavailableForDeliveryBannerClick,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "SE_order_medicine_unavailable_for_delivery_banner_click",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Track if a patient clicks in to the ‘Ordering medicine that’s waiting for stock?’ banner
 *
 * @param {SeOrderMedicineWaitingForStockBannerClick} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function seOrderMedicineWaitingForStockBannerClick(
  props?: SeOrderMedicineWaitingForStockBannerClick,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "SE_order_medicine_waiting_for_stock_banner_click",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user lands on the change password page after accessing it from their account tab
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountChangePasswordPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "account_change_password_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when an account has been deleted and the confirmation page is shown with options for reasons this was chosen
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountDeletedPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "account_deleted_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when this page is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "account_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user confirms a preferred name change
 *
 * @param {AccountPreferredNameConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountPreferredNameConfirm(
  props: AccountPreferredNameConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "account_preferred_name_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user lands on the preferred name page after accessing it from the account nav
 *
 * @param {AccountPreferredNamePageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountPreferredNamePageview(
  props: AccountPreferredNamePageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "account_preferred_name_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a patient see's the 'action needed' banner
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function actionNeededBanner(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "action_needed_banner",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when an address has been added and confirmed
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addAddressConfirm(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_address_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the first page of the add address flow is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addAddressPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_address_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a patient clicks 'add another person' in the account tab
 *
 * @param {AddAnotherPerson} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addAnotherPerson(
  props: AddAnotherPerson,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_another_person",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a patient clicks 'add another person' in the patient switcher sidebar toggle
 *
 * @param {AddAnotherPersonSidebar} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addAnotherPersonSidebar(
  props: AddAnotherPersonSidebar,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_another_person_sidebar",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page where you have to add an exemption expiry date loads
 *
 * @param {AddExemptionConfirm} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addExemptionConfirm(
  props?: AddExemptionConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_exemption_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page where you have to add an exemption expiry date loads
 *
 * @param {AddExemptionExpiryPageview} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addExemptionExpiryPageview(
  props?: AddExemptionExpiryPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_exemption_expiry_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when someone hits confirm to add an exemption but it fails
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addExemptionFailure(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_exemption_failure",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page with a list of exemptions to choose from is shown
 *
 * @param {AddExemptionsPageview} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addExemptionsPageview(
  props?: AddExemptionsPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_exemptions_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user has chosen a date for their manually added medication and hits confirm
 *
 * @param {AddMedicationConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicationConfirm(
  props: AddMedicationConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medication_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the due date page is shown on the add medication flow
 *
 * @param {AddMedicationDueDatePageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicationDueDatePageview(
  props: AddMedicationDueDatePageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medication_due_date_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when ‘add medicine’ is hit and the page after  loads
 *
 * @param {AddMedicationPageview} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicationPageview(
  props?: AddMedicationPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medication_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * on the add medication flow when the user picks a strength for the medication they selected
 *
 * @param {AddMedicationPickStrength} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicationPickStrength(
  props: AddMedicationPickStrength,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medication_pick_strength",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits 'Add new medicine' on the 'Your medicines' page in the add medicine flow
 *
 * @param {AddMedicineAddNewMedicine} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineAddNewMedicine(
  props: AddMedicineAddNewMedicine,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_add_new_medicine",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits ‘Get it on Google Play’ on the ‘Not time to order’ page
 *
 * @param {AddMedicineAndroidDownload} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineAndroidDownload(
  props: AddMedicineAndroidDownload,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_android_download",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits ‘No, I've run out’ on the  'Do you have any [specified medicine] left?' page in the add medicine flow
 *
 * @param {AddMedicineAnyLeftNo} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineAnyLeftNo(
  props: AddMedicineAnyLeftNo,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_any_left_no",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the 'Do you have any [specified medicine] left?' page in the add medicine flow
 *
 * @param {AddMedicineAnyLeftPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineAnyLeftPageview(
  props: AddMedicineAnyLeftPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_any_left_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits ‘Yes, I have some left’ on the  'Do you have any [specified medicine] left?' page in the add medicine flow
 *
 * @param {AddMedicineAnyLeftYes} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineAnyLeftYes(
  props: AddMedicineAnyLeftYes,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_any_left_yes",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits ‘Download on the App Store’ on the ‘Not time to order’ page
 *
 * @param {AddMedicineAppleDownload} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineAppleDownload(
  props: AddMedicineAppleDownload,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_apple_download",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the appliance options search results page in the add medicine flow
 *
 * @param {AddMedicineAppliancePageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineAppliancePageview(
  props: AddMedicineAppliancePageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_appliance_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits the cross to exit the add medicine flow on any of the pages of the flow
 *
 * @param {AddMedicineExit} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineExit(
  props?: AddMedicineExit,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_exit",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits done on the 'How many days medicine do you have left?' page in the add medicine flow
 *
 * @param {AddMedicineHowMuch} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineHowMuch(
  props: AddMedicineHowMuch,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_how_much",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the error message that appears on the 'How many days medicine do you have left?' in the add medicine flow
 *
 * @param {AddMedicineHowMuchErrorPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineHowMuchErrorPageview(
  props: AddMedicineHowMuchErrorPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_how_much_error_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the 'How many days medicine do you have left?' page in the add medicine flow
 *
 * @param {AddMedicineHowMuchPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineHowMuchPageview(
  props: AddMedicineHowMuchPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_how_much_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits ‘Connect with NHS Login’ on the interstitial adding medicine screen to connect with NHS login and sync their repeat medicines
 *
 * @param {AddMedicineNhsLogin} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineNhsLogin(
  props: AddMedicineNhsLogin,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_nhs_login",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits ‘Continue with NHS login’ button to navigate to the NHS site to connect as part of the adding medicine flow
 *
 * @param {AddMedicineNhsLoginContinue} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineNhsLoginContinue(
  props: AddMedicineNhsLoginContinue,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_nhs_login_continue",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the intermediary ‘Connect with NHS login’ page after selecting to ‘continue with nhs login’ on the adding medicine page
 *
 * @param {AddMedicineNhsLoginPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineNhsLoginPageview(
  props: AddMedicineNhsLoginPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_nhs_login_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits ‘Okay’ on the ‘Not time to order’ page
 *
 * @param {AddMedicineNotTimeToOrder} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineNotTimeToOrder(
  props: AddMedicineNotTimeToOrder,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_not_time_to_order",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the 'Not time to order' page after adding medicines on the add medicine flow
 *
 * @param {AddMedicineNotTimeToOrderPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineNotTimeToOrderPageview(
  props: AddMedicineNotTimeToOrderPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_not_time_to_order_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the interstitial ‘How would you like to add your medicine screen’ during the signup flow
 *
 * @param {AddMedicinePageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicinePageview(
  props: AddMedicinePageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the 'Safe custody' after searching for the medicine they require in the add medicine flow
 *
 * @param {AddMedicineSafeCustodyPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineSafeCustodyPageview(
  props: AddMedicineSafeCustodyPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_safe_custody_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits ‘Search for your medicines’ on the interstitial adding medicine screen to add their medicines manually
 *
 * @param {AddMedicineSearch} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineSearch(
  props: AddMedicineSearch,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_search",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the 'no results' pop up after entering their required medicine on the 'Search for your medicine' page
 *
 * @param {AddMedicineSearchNoResultsPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineSearchNoResultsPageview(
  props: AddMedicineSearchNoResultsPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_search_no_results_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the 'Search for your medicine' page after selecting 'Search for your medicines'  on the interstitial adding medicine screen
 *
 * @param {AddMedicineSearchPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineSearchPageview(
  props: AddMedicineSearchPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_search_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the results on the 'Search for your medicine' page after selecting 'Search for your medicines'  on the interstitial adding medicine screen
 *
 * @param {AddMedicineSearchResultsPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineSearchResultsPageview(
  props: AddMedicineSearchResultsPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_search_results_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits what medicine they require on the 'Search for your medicine'  page
 *
 * @param {AddMedicineSearchSelect} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineSearchSelect(
  props: AddMedicineSearchSelect,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_search_select",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits ‘Skip for Now’ on the interstitial adding medicine screen to add medicines later
 *
 * @param {AddMedicineSkip} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineSkip(
  props: AddMedicineSkip,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_skip",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits ‘Continue to order’ on the ‘Time to order’ page to create an order in the add medicine flow
 *
 * @param {AddMedicineTimeToOrderContinue} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineTimeToOrderContinue(
  props: AddMedicineTimeToOrderContinue,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_time_to_order_continue",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the 'Time to order' page after adding medicines on the add medicine flow
 *
 * @param {AddMedicineTimeToOrderPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineTimeToOrderPageview(
  props: AddMedicineTimeToOrderPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_time_to_order_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits ‘Skip for now’ on the ‘Time to order’ page to skip ordering immediately in the add medicine flow
 *
 * @param {AddMedicineTimeToOrderSkip} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineTimeToOrderSkip(
  props: AddMedicineTimeToOrderSkip,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_time_to_order_skip",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user clicks to view the video on the interstitial adding medicine screen (this does not navigate away from the screen)
 *
 * @param {AddMedicineVideo} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineVideo(
  props: AddMedicineVideo,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_video",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the 'What dose does this medication come in?' page in the add medicine flow
 *
 * @param {AddMedicineWhatDosePageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineWhatDosePageview(
  props: AddMedicineWhatDosePageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_what_dose_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits what dose of medication they require on the 'What does does this medication come in?'  page
 *
 * @param {AddMedicineWhatDoseSelect} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineWhatDoseSelect(
  props: AddMedicineWhatDoseSelect,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_what_dose_select",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the 'What form does this medication come in?' page in the add medicine flow
 *
 * @param {AddMedicineWhatFormPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineWhatFormPageview(
  props: AddMedicineWhatFormPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_what_form_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits what form of medication they require on the 'What form does this medication come in?' page
 *
 * @param {AddMedicineWhatFormSelect} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineWhatFormSelect(
  props: AddMedicineWhatFormSelect,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_what_form_select",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits ‘Continue’ on  the 'Which brand do you need?' page on the add medicine flow
 *
 * @param {AddMedicineWhichBrandContinue} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineWhichBrandContinue(
  props: AddMedicineWhichBrandContinue,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_which_brand_continue",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits which brand they require for their medicine on the 'Which brand do you need?' page on the add medicine flow
 *
 * @param {AddMedicineWhichBrandDropdown} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineWhichBrandDropdown(
  props: AddMedicineWhichBrandDropdown,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_which_brand_dropdown",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the 'Which brand do you need?' page on the add medicine flow
 *
 * @param {AddMedicineWhichBrandPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineWhichBrandPageview(
  props: AddMedicineWhichBrandPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_which_brand_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits ‘Skip for now’ on  the 'Which brand do you need?' page on the add medicine flow
 *
 * @param {AddMedicineWhichBrandSkip} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineWhichBrandSkip(
  props: AddMedicineWhichBrandSkip,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_which_brand_skip",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits 'Done' on the 'Your medicines' page in the add medicine flow
 *
 * @param {AddMedicineYourMedicineDone} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineYourMedicineDone(
  props: AddMedicineYourMedicineDone,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_your_medicine_done",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits 'Done' on the 'Your medicines' page when IM1 connected in the add medicine flow
 *
 * @param {AddMedicineYourMedicineIm1Done} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineYourMedicineIm1Done(
  props: AddMedicineYourMedicineIm1Done,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_your_medicine_im1_done",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits the help article on the 'Your medicines' page and are IM1 connected in the add medicine flow
 *
 * @param {AddMedicineYourMedicineIm1Help} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineYourMedicineIm1Help(
  props: AddMedicineYourMedicineIm1Help,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_your_medicine_im1_help",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits ‘Call GP Surgery’  on the ‘Update your medicine list' page after selecting to on the ‘Your medicines’ page
 *
 * @param {AddMedicineYourMedicineIm1HelpGp} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineYourMedicineIm1HelpGp(
  props: AddMedicineYourMedicineIm1HelpGp,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_your_medicine_im1_help_gp",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the 'Update your medicine list' page after selecting to on the ‘Your medicines’ page
 *
 * @param {AddMedicineYourMedicineIm1HelpPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineYourMedicineIm1HelpPageview(
  props: AddMedicineYourMedicineIm1HelpPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_your_medicine_im1_help_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the 'Your medicines' page and are IM1 connected on the add medicine flow
 *
 * @param {AddMedicineYourMedicineIm1Pageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineYourMedicineIm1Pageview(
  props: AddMedicineYourMedicineIm1Pageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_your_medicine_im1_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views  the 'Your medicines' page in the add medicine flow
 *
 * @param {AddMedicineYourMedicinePageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addMedicineYourMedicinePageview(
  props: AddMedicineYourMedicinePageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_medicine_your_medicine_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user taps the “Add” button on the add-on items list
 *
 * @param {AddOnItemAdd} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addOnItemAdd(
  props: AddOnItemAdd,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_on_item_add",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user taps the "Info" button on the add-on items list
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addOnItemInfoPrompt(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_on_item_info_prompt",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user dismisses the info prompt for an add-on item
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addOnItemInfoPromptDismiss(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_on_item_info_prompt_dismiss",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user expands a section of more information for an add-on item
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addOnItemInfoPromptToggle(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_on_item_info_prompt_toggle",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user taps “Continue” on the add-on items list
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addOnItemsConfirm(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_on_items_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user taps the “No thanks” button on the add-on items list
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addOnItemsIgnore(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_on_items_ignore",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a payment method has been added and confirmed
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addPaymentMethodConfirm(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_payment_method_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when confirm is hit at the end of the add payment flow and it fails
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addPaymentMethodFailure(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_payment_method_failure",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the first page of the add payment method flow is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addPaymentMethodPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_payment_method_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the add phone page is viewed
 *
 * @param {AddPhonePageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addPhonePageview(
  props: AddPhonePageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "add_phone_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits “Add” button for an add-on item

 *
 * @param {AddonsAdd} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addonsAdd(
  props: AddonsAdd,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "addons_add",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the "Continue" button on the add-on items list

 *
 * @param {AddonsConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addonsConfirm(
  props: AddonsConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "addons_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the “No thanks” button on the add-on items list

 *
 * @param {AddonsIgnore} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addonsIgnore(
  props: AddonsIgnore,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "addons_ignore",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits “Info” button on the add-on items list

 *
 * @param {AddonsInfoPrompt} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addonsInfoPrompt(
  props: AddonsInfoPrompt,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "addons_info_prompt",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user dismisses the info prompt for an add-on item
 *
 * @param {AddonsInfoPromptDismiss} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addonsInfoPromptDismiss(
  props: AddonsInfoPromptDismiss,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "addons_info_prompt_dismiss",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user expands a section of more information for an add-on item
 *
 * @param {AddonsInfoPromptToggle} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function addonsInfoPromptToggle(
  props: AddonsInfoPromptToggle,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "addons_info_prompt_toggle",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits continue after selecting any medication they want to accept
 *
 * @param {AskPatientAccept} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientAccept(
  props: AskPatientAccept,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_accept",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user is shown the barcode requested page during the ask patient flow
 *
 * @param {AskPatientBarcodeRequestedPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientBarcodeRequestedPageview(
  props: AskPatientBarcodeRequestedPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_barcode_requested_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits change address on the review page of the ask patient flow
 *
 * @param {AskPatientChangeAddress} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientChangeAddress(
  props: AskPatientChangeAddress,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_change_address",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the 'collect from lloyds' button on the delivery method page in the ask patient flow
 *
 * @param {AskPatientCollectLloyds} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientCollectLloyds(
  props: AskPatientCollectLloyds,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_collect_lloyds",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user opens the collect lloyds map on the delivery wedge screen on the ask patient flow
 *
 * @param {AskPatientCollectLloydsMap} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientCollectLloydsMap(
  props: AskPatientCollectLloydsMap,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_collect_lloyds_map",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits confirm on the ask patient review page
 *
 * @param {AskPatientConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientConfirm(
  props: AskPatientConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the 'deliver my medication' button on the delivery method page in the ask patient flow
 *
 * @param {AskPatientDeliver} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientDeliver(
  props: AskPatientDeliver,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_deliver",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user is shown the choose your delivery method page during the ask patient flow
 *
 * @param {AskPatientDeliveryMethodPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientDeliveryMethodPageview(
  props: AskPatientDeliveryMethodPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_delivery_method_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user is going through their first ask patient request flow and hits confirm to add an exemption
 *
 * @param {AskPatientExemptionAddConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientExemptionAddConfirm(
  props: AskPatientExemptionAddConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_exemption_add_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user has indicated they dont pay for rx's, and a list of exemptions to choose from is shown
 *
 * @param {AskPatientExemptionAddPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientExemptionAddPageview(
  props: AskPatientExemptionAddPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_exemption_add_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the 'find a collection point' button on the delivery method page in the ask patient flow
 *
 * @param {AskPatientLocalCollect} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientLocalCollect(
  props: AskPatientLocalCollect,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_local_collect",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user is shown the list of medications the gp has sent an rx for
 *
 * @param {AskPatientPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientPageview(
  props: AskPatientPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits continue after saying they dont want any of the medication
 *
 * @param {AskPatientReject} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientReject(
  props: AskPatientReject,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_reject",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the 'request perscription barcode' button on the delivery method page in the ask patient flow
 *
 * @param {AskPatientRequestBarcode} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientRequestBarcode(
  props: AskPatientRequestBarcode,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_request_barcode",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user is shown the review page for their ask patient request
 *
 * @param {AskPatientReviewPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientReviewPageview(
  props: AskPatientReviewPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_review_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the confirm medicine button is hit on the dashboard to start the ask patient flow
 *
 * @param {AskPatientStart} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientStart(
  props: AskPatientStart,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_start",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user is shown the barcode requested page during the urgent ask patient flow
 *
 * @param {AskPatientUrgentBarcodeRequestedPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientUrgentBarcodeRequestedPageview(
  props: AskPatientUrgentBarcodeRequestedPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_urgent_barcode_requested_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the 'find a lloyds pharmacy store' button or ‘collect at this store’ button on the delivery method page in the urgent ask patient flow
 *
 * @param {AskPatientUrgentCollectLloyds} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientUrgentCollectLloyds(
  props: AskPatientUrgentCollectLloyds,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_urgent_collect_lloyds",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user opens the collect lloyds map on the delivery wedge screen on the urgent ask patient flow
 *
 * @param {AskPatientUrgentCollectLloydsMap} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientUrgentCollectLloydsMap(
  props: AskPatientUrgentCollectLloydsMap,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_urgent_collect_lloyds_map",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the 'choose delivery' button on the delivery method page in the urgent ask patient flow
 *
 * @param {AskPatientUrgentDeliver} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientUrgentDeliver(
  props: AskPatientUrgentDeliver,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_urgent_deliver",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user is shown the choose your delivery method page during the urgent ask patient flow
 *
 * @param {AskPatientUrgentDeliveryMethodPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientUrgentDeliveryMethodPageview(
  props: AskPatientUrgentDeliveryMethodPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_urgent_delivery_method_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the 'find a collection point' button on the delivery method page in the urgent ask patient flow
 *
 * @param {AskPatientUrgentLocalCollect} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientUrgentLocalCollect(
  props: AskPatientUrgentLocalCollect,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_urgent_local_collect",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user is shown the prescription confirmed page during the urgent ask patient flow
 *
 * @param {AskPatientUrgentPrescriptionConfirmedPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientUrgentPrescriptionConfirmedPageview(
  props: AskPatientUrgentPrescriptionConfirmedPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_urgent_prescription_confirmed_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the 'request prescription barcode' button on the delivery method page in the urgent ask patient flow
 *
 * @param {AskPatientUrgentRequestBarcode} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientUrgentRequestBarcode(
  props: AskPatientUrgentRequestBarcode,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_urgent_request_barcode",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the confirm medicine button is hit on the dashboard to start the urgent ask patient flow
 *
 * @param {AskPatientUrgentStart} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function askPatientUrgentStart(
  props: AskPatientUrgentStart,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "ask_patient_urgent_start",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the patient hits the 'switch to echo' button on the banner on the medicine tab
 *
 * @param {BannerNominateEcho} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function bannerNominateEcho(
  props: BannerNominateEcho,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "banner_nominate_echo",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a patient see's the 'action needed' banner
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandSpecificActionNeededBanner(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "brand_specific_action_needed_banner",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When a patient lands on the Brand Warning page
 *
 * @param {BrandSpecificsBrandWarningPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandSpecificsBrandWarningPageview(
  props: BrandSpecificsBrandWarningPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "brand_specifics_brand_warning_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * On the confirm prescription page when a patient selects in the drop down
 *
 * @param {BrandSpecificsConfirmPresDropDown} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandSpecificsConfirmPresDropDown(
  props: BrandSpecificsConfirmPresDropDown,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "brand_specifics_confirm_pres_drop_down",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When a patient selects 'I can take any medicine' on the confirm prescription page
 *
 * @param {BrandSpecificsConfirmPresDropDownIcantake} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandSpecificsConfirmPresDropDownIcantake(
  props: BrandSpecificsConfirmPresDropDownIcantake,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "brand_specifics_confirm_pres_drop_down_icantake",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the patient lands on the Confirm prescription page
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandSpecificsConfirmPresPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "brand_specifics_confirm_pres_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When a patient lands on the medicine tab
 *
 * @param {BrandSpecificsMedicineTabPageView} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandSpecificsMedicineTabPageView(
  props: BrandSpecificsMedicineTabPageView,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "brand_specifics_medicine_tab_page_view",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When a patient clicks on see available brands on the order prescription page
 *
 * @param {BrandSpecificsOrderPresNim1BrandClick} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandSpecificsOrderPresNim1BrandClick(
  props: BrandSpecificsOrderPresNim1BrandClick,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "brand_specifics_order_pres_nim1_brand_click",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When a patient lands on the order prescription page
 *
 * @param {BrandSpecificsOrderPresNim1Pageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandSpecificsOrderPresNim1Pageview(
  props: BrandSpecificsOrderPresNim1Pageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "brand_specifics_order_pres_nim1_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Brand Warning Interaction with drop down menu
 *
 * @param {BrandSpecificsSelectBrandWarning} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandSpecificsSelectBrandWarning(
  props?: BrandSpecificsSelectBrandWarning,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "brand_specifics_select_brand_warning",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the button to call the patients gp is hit
 *
 * @param {CallGp} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function callGp(
  props: CallGp,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track("call_gp", props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * when the user hits call lloydspharmacy on their request screen under their pending omnichannel order
 *
 * @param {CallLloydspharmacy} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function callLloydspharmacy(
  props: CallLloydspharmacy,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "call_lloydspharmacy",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the prompt appears for cancelling pill review 'Do you want to cancel?'
 *
 * @param {CancelPillReviewPrompt} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cancelPillReviewPrompt(
  props?: CancelPillReviewPrompt,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "cancel_pill_review_prompt",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the user clicks on 'cancel my review' on the 'Do you want to cancel?' prompt
 *
 * @param {CancelPillReviewPromptConfirm} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cancelPillReviewPromptConfirm(
  props?: CancelPillReviewPromptConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "cancel_pill_review_prompt_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the user clicks on 'Keep booking' on the 'Do you want to cancel?' prompt
 *
 * @param {CancelPillReviewPromptKeepBooking} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cancelPillReviewPromptKeepBooking(
  props?: CancelPillReviewPromptKeepBooking,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "cancel_pill_review_prompt_keep_booking",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Why is the user cancelling the rx? Example 'getting medication elsewhere'
 *
 * @param {CancelRxCancellationReason} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cancelRxCancellationReason(
  props: CancelRxCancellationReason,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "cancel_rx_cancellation_reason",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Confirmation page of cancel rx
 *
 * @param {CancelRxConfirmationPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cancelRxConfirmationPageview(
  props: CancelRxConfirmationPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "cancel_rx_confirmation_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Selecting what medication to cancel page
 *
 * @param {CancelRxSelectMedicationPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cancelRxSelectMedicationPageview(
  props: CancelRxSelectMedicationPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "cancel_rx_select_medication_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the Medicine tab after the signup flow (where their gp is a N3P practice and we also don’t work with their IM1 provider)
 *
 * @param {CannotOrderGetMedicineDeliveredPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cannotOrderGetMedicineDeliveredPageview(
  props: CannotOrderGetMedicineDeliveredPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "cannot_order_get_medicine_delivered_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a new email is confirmed
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function changeEmailConfirm(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "change_email_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page to change your email is shown
 *
 * @param {ChangeEmailPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function changeEmailPageview(
  props: ChangeEmailPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "change_email_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the patient has selected a gp from the change gp workflow and hits confirm
 *
 * @param {ChangeGpConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function changeGpConfirm(
  props: ChangeGpConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "change_gp_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a patient clicks a link from the order review screen to change their gp (as the gp shown on the screen is not their correct gp)
 *
 * @param {ChangeGpOrderScreen} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function changeGpOrderScreen(
  props: ChangeGpOrderScreen,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "change_gp_order_screen",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the first page of the change gp flow is shown
 *
 * @param {ChangeGpPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function changeGpPageview(
  props: ChangeGpPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "change_gp_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page to change your password is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function changePasswordPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "change_password_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a ‘change password’ email is requested
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function changePasswordSendEmail(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "change_password_send_email",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the button to send a confirmation text to another phone number
 *
 * @param {ChangePhoneConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function changePhoneConfirm(
  props: ChangePhoneConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "change_phone_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page to change your phone no. is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function changePhonePageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "change_phone_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user lands on the page saying there is a phone error.
 *
 * @param {CheckPhoneErrorPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkPhoneErrorPageview(
  props: CheckPhoneErrorPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "check_phone_error_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user lands on the page asking them to check their phone to verify their number
 *
 * @param {CheckPhonePageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkPhonePageview(
  props: CheckPhonePageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "check_phone_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a lloyds collection store is chosen from the search page
 *
 * @param {CollectLloydsAddressConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function collectLloydsAddressConfirm(
  props: CollectLloydsAddressConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "collect_lloyds_address_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the map is opened when choosing a lloyds to collect medication from
 *
 * @param {CollectLloydsMap} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function collectLloydsMap(
  props: CollectLloydsMap,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "collect_lloyds_map",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page to choose a lloyds to collect medication from is landed on
 *
 * @param {CollectLloydsPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function collectLloydsPageview(
  props: CollectLloydsPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "collect_lloyds_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when an LP pharmacy is searched when choosing a delivery option
 *
 * @param {CollectLloydsSearch} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function collectLloydsSearch(
  props: CollectLloydsSearch,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "collect_lloyds_search",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when choosing a lloyds store to collect medication from, and a search is made, but no stores were found
 *
 * @param {CollectLloydsSearchError} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function collectLloydsSearchError(
  props: CollectLloydsSearchError,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "collect_lloyds_search_error",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user has added their linkage keys and hits confirm
 *
 * @param {ConnectGpConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function connectGpConfirm(
  props: ConnectGpConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "connect_gp_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user adds their linkage keys, hits confirm, and an error is shown
 *
 * @param {ConnectGpError} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function connectGpError(
  props: ConnectGpError,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "connect_gp_error",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user has no medications and their gp is im1 enabled, and they hit the 'connect to gp' button on the tile on the dashboard, this page loads
 *
 * @param {ConnectGpPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function connectGpPageview(
  props: ConnectGpPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "connect_gp_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user adds their linkage keys, hits confirm, and is successfully connected
 *
 * @param {ConnectGpSuccess} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function connectGpSuccess(
  props: ConnectGpSuccess,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "connect_gp_success",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the call button is hit on pages outside of the help centre (i.e. in account page or in signup)
 *
 * @param {ContactCallLloydsDirect} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contactCallLloydsDirect(
  props: ContactCallLloydsDirect,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "contact_call_lloyds_direct",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user lands on contact details page
 *
 * @param {ContactDetailsPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function contactDetailsPageview(
  props: ContactDetailsPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "contact_details_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When cookie consent is changed, log the categories consented to https://app-uk.onetrust.com/cookies/categorizations?tab=Categories
 *
 * @param {CookieConsentChanged} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cookieConsentChanged(
  props?: CookieConsentChanged,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "cookie_consent_changed",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the dashboard home page loads
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dashboardPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "dashboard_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the prompt saying the user failed to be nominated to echo during signup is shown on the dashboard
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function dashboardPdsFail(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "dashboard_pds_fail",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the confirmation button on the delete account prompt is hit
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deleteAccountConfirm(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "delete_account_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the confirm button is hit on a delivery address
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deliveryAddressesDeleteConfirm(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "delivery_addresses_delete_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user has open orders and we block a delivery type
 *
 * @param {DeliveryMethodBlock} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function deliveryMethodBlock(
  props: DeliveryMethodBlock,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "delivery_method_block",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Patient views the dose reminders page where they can change the day and view the dosage
 *
 * @param {DoseRemindersSummaryPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function doseRemindersSummaryPageview(
  props: DoseRemindersSummaryPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "dose_reminders_summary_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Patient changes the date and is viewing dose reminders for a day in the past or future
 *
 * @param {DoseRemindersSwitchDate} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function doseRemindersSwitchDate(
  props: DoseRemindersSwitchDate,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "dose_reminders_switch_date",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the google play store or ios app store buttons to download the app
 *
 * @param {DownloadApp} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function downloadApp(
  props: DownloadApp,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "download_app",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user clicks on the “x” to close the “download the LloydsDirect app” banner
 *
 * @param {DownloadAppClose} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function downloadAppClose(
  props: DownloadAppClose,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "download_app_close",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when someone hits the confirmation button after editing their address
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function editAddressConfirm(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "edit_address_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the change button is hit on the medication reminder
 *
 * @param {EditReminder} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function editReminder(
  props: EditReminder,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "edit_reminder",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the ‘confirm’ button is hit after editing the medication's reminder
 *
 * @param {EditReminderConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function editReminderConfirm(
  props: EditReminderConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "edit_reminder_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the generic error modal is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function errorModal(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "error_modal",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Patient clicks on the "Learn More" link next to the delivery options.]
 *
 * @param {ExemptPremiumDeliveryLearnMore} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exemptPremiumDeliveryLearnMore(
  props: ExemptPremiumDeliveryLearnMore,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "exempt_premium_delivery_learn_more",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Payment fails for exempt premium delivery
 *
 * @param {ExemptPremiumDeliveryPaymentFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exemptPremiumDeliveryPaymentFailed(
  props: ExemptPremiumDeliveryPaymentFailed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "exempt_premium_delivery_payment_failed",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Payment is successful for exempt premium delivery
 *
 * @param {ExemptPremiumDeliveryPaymentSuccess} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exemptPremiumDeliveryPaymentSuccess(
  props: ExemptPremiumDeliveryPaymentSuccess,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "exempt_premium_delivery_payment_success",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Patient is shown exempt premium delivery card on main medications list view
 *
 * @param {ExemptPremiumDeliveryPrompt} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exemptPremiumDeliveryPrompt(
  props: ExemptPremiumDeliveryPrompt,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "exempt_premium_delivery_prompt",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Patient explicitly decides to stick with normal delivery instead of upgrading
 *
 * @param {ExemptPremiumDeliveryRejected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exemptPremiumDeliveryRejected(
  props: ExemptPremiumDeliveryRejected,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "exempt_premium_delivery_rejected",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Patient views exempt premium delivery upgrade and payment page
 *
 * @param {ExemptPremiumDeliveryReview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exemptPremiumDeliveryReview(
  props: ExemptPremiumDeliveryReview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "exempt_premium_delivery_review",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Patient decides to upgrade their order to premium delivery
 *
 * @param {ExemptPremiumDeliveryUpgraded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exemptPremiumDeliveryUpgraded(
  props: ExemptPremiumDeliveryUpgraded,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "exempt_premium_delivery_upgraded",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page explaining exemptions is loaded after hitting ‘add an exemption or prepayment certificate’
 *
 * @param {ExemptionsPageview} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function exemptionsPageview(
  props?: ExemptionsPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "exemptions_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * on load of a experiment page
 *
 * @param {Experiment} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function experiment(
  props: Experiment,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "experiment",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits any link to an external page on the marketing website e.g. nhs app library, nhs exemption info etc
 *
 * @param {ExternalLink} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function externalLink(
  props: ExternalLink,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "external_link",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the patients first order is confirmed
 *
 * @param {FirstOrderConfirmed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function firstOrderConfirmed(
  props: FirstOrderConfirmed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "first_order_confirmed",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page after clicking forgotten password is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function forgotPasswordPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "forgot_password_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user submits a response to the 'how did you hear about us' survey
 *
 * @param {HdyhauSurveyComplete} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function hdyhauSurveyComplete(
  props: HdyhauSurveyComplete,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "hdyhau_survey_complete",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a help article page is shown
 *
 * @param {HelpArticle} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function helpArticle(
  props: HelpArticle,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "help_article",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when an action is hit on the help centre article
 *
 * @param {HelpArticleAction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function helpArticleAction(
  props: HelpArticleAction,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "help_article_action",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a help article page loads
 *
 * @param {HelpArticlePageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function helpArticlePageview(
  props: HelpArticlePageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "help_article_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the call button is hit on the contact us page of the help centre
 *
 * @param {HelpContactCall} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function helpContactCall(
  props: HelpContactCall,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "help_contact_call",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the message button is hit on the contact us page of the help centre
 *
 * @param {HelpContactMessage} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function helpContactMessage(
  props: HelpContactMessage,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "help_contact_message",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the contact us page is shown in the help centre
 *
 * @param {HelpContactUsPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function helpContactUsPageview(
  props: HelpContactUsPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "help_contact_us_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the help centre page is shown with a list of help articles
 *
 * @param {HelpPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function helpPageview(
  props: HelpPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "help_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires a 'help_patient_picker_pageview' track call.
 *
 * @param {HelpPatientPickerPageview} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function helpPatientPickerPageview(
  props?: HelpPatientPickerPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "help_patient_picker_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user clicks ‘Connect with your GP’ to connect via IM1 from the account page (they could be N3P or not)
 *
 * @param {Im1ConnectGp} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function im1ConnectGp(
  props: Im1ConnectGp,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "im1_connect_gp",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user clicks ‘Connect manually’ button from the Connect with your GP page
 *
 * @param {Im1ConnectWithGpManually} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function im1ConnectWithGpManually(
  props: Im1ConnectWithGpManually,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "im1_connect_with_gp_manually",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user clicks ‘Connect with NHS login’ button from the Connect with your GP page
 *
 * @param {Im1ConnectWithGpNhsLogin} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function im1ConnectWithGpNhsLogin(
  props: Im1ConnectWithGpNhsLogin,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "im1_connect_with_gp_nhs_login",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the the 'connect with gp' screen to connect via IM1
 *
 * @param {Im1ConnectWithGpPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function im1ConnectWithGpPageview(
  props: Im1ConnectWithGpPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "im1_connect_with_gp_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user who is IM1 connected and requires their medications to be updated clicks the ‘call gp surgery’ button so their medicine list can be updated by their gp
 *
 * @param {Im1UpdateMedicineCallGp} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function im1UpdateMedicineCallGp(
  props: Im1UpdateMedicineCallGp,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "im1_update_medicine_call_gp",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user views the ‘Update your medicine list’ page for im1 patients
 *
 * @param {Im1UpdateMedicineListPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function im1UpdateMedicineListPageview(
  props: Im1UpdateMedicineListPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "im1_update_medicine_list_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the ‘your account doesnt seem complete’ tile is shown
 *
 * @param {IncompleteAccount} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function incompleteAccount(
  props?: IncompleteAccount,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "incomplete_account",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when collect from this address button is hit
 *
 * @param {LocalCollectAddressConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localCollectAddressConfirm(
  props: LocalCollectAddressConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "local_collect_address_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user is shown the page to find a local collect point
 *
 * @param {LocalCollectPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localCollectPageview(
  props: LocalCollectPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "local_collect_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user searches for an address and is shown no collection points found
 *
 * @param {LocalCollectSearchError} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function localCollectSearchError(
  props: LocalCollectSearchError,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "local_collect_search_error",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the login button is hit
 *
 * @param {Login} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function login(
  props: Login,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track("login", props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * when the user hits a login button on the marketing website
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginCta(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track("login_cta", props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * when a failed login occurs because of an invalid password
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginFailureNotValid(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "login_failure_not_valid",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the login page is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "login_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a successful login occurs
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function loginSuccessful(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "login_successful",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page showing the patients addresses is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function manageAddressesPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "manage_addresses_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When an account views the 'Something went wrong unsubscribing' page after clicking to unsubscribe on a marketing email
 *
 * @param {MarketingUnsubscribeFailurePageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function marketingUnsubscribeFailurePageview(
  props: MarketingUnsubscribeFailurePageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "marketing_unsubscribe_failure_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When an account views the 'Unsubscribe Successful' page after clicking to unsubscribe on a marketing email
 *
 * @param {MarketingUnsubscribeSuccessPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function marketingUnsubscribeSuccessPageview(
  props: MarketingUnsubscribeSuccessPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "marketing_unsubscribe_success_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the "Add exemption" link on the prescription payment card
 *
 * @param {MedicationAddExemption} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function medicationAddExemption(
  props: MedicationAddExemption,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "medication_add_exemption",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the "Confirm prescription" button in the ask patient card on the medicine screen
 *
 * @param {MedicationConfirmPrescription} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function medicationConfirmPrescription(
  props: MedicationConfirmPrescription,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "medication_confirm_prescription",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * where the user clicks through to the details of a certain medication including dosage instructions and reminders
 *
 * @param {MedicationDetailsPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function medicationDetailsPageview(
  props: MedicationDetailsPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "medication_details_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the medication page is rendered
 *
 * @param {MedicationPageview} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function medicationPageview(
  props?: MedicationPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "medication_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the "Pay now" button on the prescription payment card
 *
 * @param {MedicationPayNow} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function medicationPayNow(
  props: MedicationPayNow,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "medication_pay_now",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the edit to the medication supply has been confirmed
 *
 * @param {MedicationSupplyChangeConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function medicationSupplyChangeConfirm(
  props: MedicationSupplyChangeConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "medication_supply_change_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page to change a medications supply details is shown
 *
 * @param {MedicationSupplyPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function medicationSupplyPageview(
  props: MedicationSupplyPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "medication_supply_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When a patient clicks 'remind me later' on the prompt to migrate to P2U
 *
 * @param {MigrationRemindLater} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function migrationRemindLater(
  props: MigrationRemindLater,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "migration_remind_later",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user’s gp changes to n3p post-signup (and the user is prevented from ordering) and the user clicks ‘Connect with your GP’ to connect via IM1
 *
 * @param {N3PIm1ConnectGp} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function n3PIm1ConnectGp(
  props: N3PIm1ConnectGp,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "n3p_im1_connect_gp",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the account button is hit on the nav bar
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function navAccount(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nav_account",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the help button is hit on the nav bar
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function navHelp(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track("nav_help", props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * when the home button is hit on the nav bar
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function navHome(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track("nav_home", props || {}, withTypewriterContext(options), callback);
  }
}
/**
 * when the user clicks the "Shop" link in the main navigation
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function navLloydsShop(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nav_lloyds_shop",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the logout button is hit on the nav bar
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function navLogout(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nav_logout",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the medication button is hit on the nav bar
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function navMedication(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nav_medication",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the invite a friend button on the nav bar
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function navReferrals(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nav_referrals",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the request button is hit on the nav bar
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function navRequest(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nav_request",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user clicks the "What's new" link in the main navigation
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function navWhatsNew(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nav_whats_new",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the patients order is waiting for stock and the 'i need it sooner' button is hit, showing the bottom sheet
 *
 * @param {NeedMedicationSoonerPrompt} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function needMedicationSoonerPrompt(
  props: NeedMedicationSoonerPrompt,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "need_medication_sooner_prompt",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User cancels email change
 *
 * @param {NewEmailCancelIntent} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newEmailCancelIntent(
  props?: NewEmailCancelIntent,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "new_email_cancel_intent",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User updates the address on intent from the "Check your email" page
 *
 * @param {NewEmailChangeEmail} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newEmailChangeEmail(
  props?: NewEmailChangeEmail,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "new_email_change_email",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User visits "check your email" page after starting update flow
 *
 * @param {NewEmailCheckEmailPageview} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newEmailCheckEmailPageview(
  props?: NewEmailCheckEmailPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "new_email_check_email_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User clicks \"I have already confirmed my email address\" when viewing email change review screen
 *
 * @param {NewEmailConfirmedEmailContinue} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newEmailConfirmedEmailContinue(
  props: NewEmailConfirmedEmailContinue,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "new_email_confirmed_email_continue",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User corrects email address from suggested typo fix
 *
 * @param {NewEmailEmailTypoFix} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newEmailEmailTypoFix(
  props?: NewEmailEmailTypoFix,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "new_email_email_typo_fix",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User tries to change email address to one which exists on another account
 *
 * @param {NewEmailErrorAccountDuplicate} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newEmailErrorAccountDuplicate(
  props?: NewEmailErrorAccountDuplicate,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "new_email_error_account_duplicate",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User clicks "I've already confirmed by address" but address has not been confirmed
 *
 * @param {NewEmailErrorEmailVerification} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newEmailErrorEmailVerification(
  props?: NewEmailErrorEmailVerification,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "new_email_error_email_verification",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User tries to change email address to the address already used for another account
 *
 * @param {NewEmailErrorExisting} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newEmailErrorExisting(
  props?: NewEmailErrorExisting,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "new_email_error_existing",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User follows verification link in email but link is invalid (most likely expired)
 *
 * @param {NewEmailErrorLinkInvalid} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newEmailErrorLinkInvalid(
  props?: NewEmailErrorLinkInvalid,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "new_email_error_link_invalid",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User tries to set a new email address but an unexpected error occurs
 *
 * @param {NewEmailErrorOther} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newEmailErrorOther(
  props?: NewEmailErrorOther,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "new_email_error_other",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User views update email form
 *
 * @param {NewEmailPageview} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newEmailPageview(
  props?: NewEmailPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "new_email_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User resends verification email
 *
 * @param {NewEmailResendEmail} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newEmailResendEmail(
  props?: NewEmailResendEmail,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "new_email_resend_email",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User sets a new email address, but has not yet verified
 *
 * @param {NewEmailSetIntent} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newEmailSetIntent(
  props?: NewEmailSetIntent,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "new_email_set_intent",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User clicks on link offering more information on avoiding spam
 *
 * @param {NewEmailSpamEmailInfo} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newEmailSpamEmailInfo(
  props?: NewEmailSpamEmailInfo,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "new_email_spam_email_info",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * User successfully verifies a new email address
 *
 * @param {NewEmailVerified} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newEmailVerified(
  props?: NewEmailVerified,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "new_email_verified",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * page shown when there is an error creating an account for a user after logging in with nhs login
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function nhsLoginAccountCreationErrorPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nhs_login_account_creation_error_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Screen shown while the account creation process is being completed

 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function nhsLoginAccountCreationPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nhs_login_account_creation_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Terms and consent screen for NHS login signups

 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function nhsLoginAccountCreationTermsPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nhs_login_account_creation_terms_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * page shown when there is an error when logging into nhs login
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function nhsLoginErrorPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nhs_login_error_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Terms/consent when an existing Echo user uses NHS login for the first time

 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function nhsLoginExistingUserConsentPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nhs_login_existing_user_consent_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * track when there is a successful login to nhs login
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function nhsLoginSuccess(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nhs_login_success",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * page shown when nhs login is unavailable
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function nhsLoginUnavaliablePageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nhs_login_unavaliable_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * view the page for confirming the nok address

 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function nokConfirmAddressPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nok_confirm_address_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the patient details page is shown on the add nok flow
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function nokDetailsPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nok_details_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the first page of the add nok flow is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function nokSearchGpPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nok_search_gp_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the terms page is shown on the add nok flow
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function nokTermsPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nok_terms_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a patient who placed three orders sees the ‘your order is in’ screen, with the option to continue to the new noks screen
 *
 * @param {NoksOrderConfirmed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function noksOrderConfirmed(
  props: NoksOrderConfirmed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "noks_order_confirmed",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a patient sees the screen encouraging them to add a nok at the end of the order flow (when they’ve placed three orders)
 *
 * @param {NoksPopup} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function noksPopup(
  props: NoksPopup,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "noks_popup",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a patient hits ‘add another person’ on the noks popup screen at the end of the order flow (when they’ve placed three orders)
 *
 * @param {NoksPopupAddAnotherPerson} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function noksPopupAddAnotherPerson(
  props: NoksPopupAddAnotherPerson,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "noks_popup_add_another_person",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a patient closes the noks popup screen at the end of the order flow (when they’ve placed three orders)
 *
 * @param {NoksPopupClose} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function noksPopupClose(
  props: NoksPopupClose,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "noks_popup_close",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a patient hits ‘not right now’ on the noks popup screen at the end of the order flow (when they’ve placed three orders)
 *
 * @param {NoksPopupNotNow} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function noksPopupNotNow(
  props: NoksPopupNotNow,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "noks_popup_not_now",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * after hitting the button on the 'switch to echo' banner, and they hit the switch to echo confirmation button on that page
 *
 * @param {NominateEchoConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function nominateEchoConfirm(
  props: NominateEchoConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nominate_echo_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * after hitting the button on the 'switch to echo' banner and the page  after loads
 *
 * @param {NominateEchoPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function nominateEchoPageview(
  props: NominateEchoPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "nominate_echo_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the not found error page is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function notFoundPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "not_found_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user lands on the notification preferences pages
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function notificationsPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "notifications_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the user intentionally opens intercom. This should not be fired when intercom is opened as a result of a new message being sent to the patient
 *
 * @param {OpenIntercom} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function openIntercom(
  props?: OpenIntercom,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "open_intercom",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires a 'partial_dispensing_confirmation_pageview' track call.
 *
 * @param {PartialDispensingConfirmationPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partialDispensingConfirmationPageview(
  props: PartialDispensingConfirmationPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "partial_dispensing_confirmation_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires a 'partial_dispensing_intro_pageview' track call.
 *
 * @param {PartialDispensingIntroPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partialDispensingIntroPageview(
  props: PartialDispensingIntroPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "partial_dispensing_intro_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires a 'partial_dispensing_review_pageview' track call.
 *
 * @param {PartialDispensingReviewPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partialDispensingReviewPageview(
  props: PartialDispensingReviewPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "partial_dispensing_review_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * on the partner request flow when a warning stating its faster to add medication via the mygp app
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerAddMedicationWarningPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "partner_add_medication_warning_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * on the partner request flow when the delivery method is confirmed
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerArrangeDeliveryConfirmationPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "partner_arrange_delivery_confirmation_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * on the partner request flow when the choose delivery method page is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerArrangeDeliveryReviewPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "partner_arrange_delivery_review_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the partner user tries to make a request but it fails
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerConfirmRequestFail(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "partner_confirm_request_fail",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the partner user tries to pay for their rx but there is an error
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerPaymentError(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "partner_payment_error",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * on the partner request flow when a warning stating its faster to request medication via the mygp app
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerRequestPrescriptionWarningPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "partner_request_prescription_warning_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * on the partner request flow when the list of their requestable medications is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerSelectMedicationsPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "partner_select_medications_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * on the partner signup flow when the page asking for account details is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerSignupAccountPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "partner_signup_account_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * on the partner signup flow when the page asking for address is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerSignupAddressPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "partner_signup_address_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the partner signup flow is completed
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerSignupComplete(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "partner_signup_complete",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * first intro page of the partner signup flow
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerSignupIntroPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "partner_signup_intro_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * on the partner signup flow when the terms page is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerSignupTermsPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "partner_signup_terms_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * on the partner signup flow when the page asking for email verification is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function partnerSignupVerifyEmailPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "partner_signup_verify_email_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a patient is chosen from the drop down on the sidebar of patients on that account
 *
 * @param {PatientChosen} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function patientChosen(
  props: PatientChosen,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "patient_chosen",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the patient hits the switch to echo button confirmation button on the patient details page
 *
 * @param {PatientDetailsNominateEchoConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function patientDetailsNominateEchoConfirm(
  props: PatientDetailsNominateEchoConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "patient_details_nominate_echo_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the patient details page loads in the account tab
 *
 * @param {PatientDetailsPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function patientDetailsPageview(
  props: PatientDetailsPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "patient_details_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the drop down on the sidebar showing the list of patients for the account is hit
 *
 * @param {PatientsListOpen} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function patientsListOpen(
  props: PatientsListOpen,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "patients_list_open",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the confirm button is hit on the payment flow
 *
 * @param {PaymentConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentConfirm(
  props: PaymentConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "payment_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when payment has been made successfully and the confirmation page is shown
 *
 * @param {PaymentConfirmationPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentConfirmationPageview(
  props: PaymentConfirmationPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "payment_confirmation_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a payment failure occurs
 *
 * @param {PaymentFailed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentFailed(
  props: PaymentFailed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "payment_failed",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * patient changes the method they would like to pay with and adds a payment method
 *
 * @param {PaymentMethodChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentMethodChanged(
  props: PaymentMethodChanged,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "payment_method_changed",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when someone hits the confirmation button after choosing to delete a payment method
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentMethodsDeleteConfirm(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "payment_methods_delete_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when someone picks their primary payment method
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentMethodsMakePrimary(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "payment_methods_make_primary",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page showing the patients payment methods is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentMethodsPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "payment_methods_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the learn more button is shown on the payment screen with the premium delivery option
 *
 * @param {PaymentPremiumDeliveryLearnMore} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentPremiumDeliveryLearnMore(
  props: PaymentPremiumDeliveryLearnMore,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "payment_premium_delivery_learn_more",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Patient reviews order before pressing 'pay' button

 *
 * @param {PaymentReviewPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentReviewPageview(
  props: PaymentReviewPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "payment_review_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the premium delivery upgrade option is shown on the payment screen
 *
 * @param {PaymentShownPremiumDelivery} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentShownPremiumDelivery(
  props: PaymentShownPremiumDelivery,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "payment_shown_premium_delivery",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * the patient successfully pays for their order
 *
 * @param {PaymentSuccessful} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentSuccessful(
  props: PaymentSuccessful,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "payment_successful",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * page shown asking user to contact support after too many failed trace attempts
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pdsContactSupportPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "pds_contact_support_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * page shown with input fields after a user hits update details after a failed pds trace during signup
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pdsUpdateDetailsPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "pds_update_details_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the prompt saying a pds trace attempt failed is shown on the update details page
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pdsUpdateFail(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "pds_update_fail",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * page shown after a user successfully updated their pds info
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pdsUpdateSuccessPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "pds_update_success_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the banner “phone number confirmed” is shown
 *
 * @param {PhoneConfirmedPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function phoneConfirmedPageview(
  props: PhoneConfirmedPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "phone_confirmed_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits 'call me instead' on the page for phone verification
 *
 * @param {PhoneVerifyCall} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function phoneVerifyCall(
  props: PhoneVerifyCall,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "phone_verify_call",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the page loads for the 'Can you measure your blood pressure' page
 *
 * @param {PillReviewBloodPressurePageview} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pillReviewBloodPressurePageview(
  props?: PillReviewBloodPressurePageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "pill_review_blood_pressure_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the page loads for the 'your pill review is booked' page
 *
 * @param {PillReviewBookedPageview} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pillReviewBookedPageview(
  props?: PillReviewBookedPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "pill_review_booked_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the page loads for the 'When would you like to have your pill review' page
 *
 * @param {PillReviewCalendarBookingPageview} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pillReviewCalendarBookingPageview(
  props?: PillReviewCalendarBookingPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "pill_review_calendar_booking_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the prompt appears for the combined pill instructions
 *
 * @param {PillReviewCombinedPrompt} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pillReviewCombinedPrompt(
  props?: PillReviewCombinedPrompt,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "pill_review_combined_prompt",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the user clicks on 'Order without booking' on the 'Sorry, we cannot do your pill review this time?' in the measure blood pressure prompt
 *
 * @param {PillReviewNoBloodPressureConfirm} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pillReviewNoBloodPressureConfirm(
  props?: PillReviewNoBloodPressureConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "pill_review_no_blood_pressure_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the page loads for the 'Sorry, we cannot do your pill review this time?' in the measure blood pressure prompt
 *
 * @param {PillReviewNoBloodPressurePageview} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pillReviewNoBloodPressurePageview(
  props?: PillReviewNoBloodPressurePageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "pill_review_no_blood_pressure_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the user clicks on 'Order without booking' on the 'Sorry, we cannot do your pill review this time' in the pill schedule prompt
 *
 * @param {PillReviewNoPillScheduleConfirm} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pillReviewNoPillScheduleConfirm(
  props?: PillReviewNoPillScheduleConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "pill_review_no_pill_schedule_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the page loads for the 'Sorry, we cannot do your pill review this time?' in the pill schedule prompt
 *
 * @param {PillReviewNoPillSchedulePageview} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pillReviewNoPillSchedulePageview(
  props?: PillReviewNoPillSchedulePageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "pill_review_no_pill_schedule_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the page loads for the 'book a pill review' page
 *
 * @param {PillReviewPageview} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pillReviewPageview(
  props?: PillReviewPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "pill_review_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the page loads for the 'what is your phone number' page
 *
 * @param {PillReviewPhoneNumberPageview} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pillReviewPhoneNumberPageview(
  props?: PillReviewPhoneNumberPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "pill_review_phone_number_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the page loads for the 'have you been sticking to your pill schedule' page
 *
 * @param {PillReviewPillSchedulePageview} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pillReviewPillSchedulePageview(
  props?: PillReviewPillSchedulePageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "pill_review_pill_schedule_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When the prompt appears for the progesterone only pill instructions
 *
 * @param {PillReviewProgesteronePrompt} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pillReviewProgesteronePrompt(
  props?: PillReviewProgesteronePrompt,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "pill_review_progesterone_prompt",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires a 'privacy_policy_external_pageview' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function privacyPolicyExternalPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "privacy_policy_external_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when lloyds collects is chosen on the delivery wedge screen on the rds request flow
 *
 * @param {RdsRequestCollectLloyds} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rdsRequestCollectLloyds(
  props: RdsRequestCollectLloyds,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "rds_request_collect_lloyds",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when lloyds collect map is opened on the delivery wedge screen on the rds request flow
 *
 * @param {RdsRequestCollectLloydsMap} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rdsRequestCollectLloydsMap(
  props: RdsRequestCollectLloydsMap,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "rds_request_collect_lloyds_map",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the confirm button is hit on the end of the rds request flow
 *
 * @param {RdsRequestConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rdsRequestConfirm(
  props: RdsRequestConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "rds_request_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when delivery is chosen on the delivery wedge screen on the rds request flow
 *
 * @param {RdsRequestDeliver} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rdsRequestDeliver(
  props: RdsRequestDeliver,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "rds_request_deliver",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the delivery method wedge is viewed on the rds request flow
 *
 * @param {RdsRequestDeliveryMethodPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rdsRequestDeliveryMethodPageview(
  props: RdsRequestDeliveryMethodPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "rds_request_delivery_method_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user states yes there has been a change, writes the change, and hits continue in the rds flow
 *
 * @param {RdsRequestHealthChange} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rdsRequestHealthChange(
  props: RdsRequestHealthChange,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "rds_request_health_change",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page asking if anything has changed with their health is hit in the rds flow
 *
 * @param {RdsRequestHealthCheckPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rdsRequestHealthCheckPageview(
  props: RdsRequestHealthCheckPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "rds_request_health_check_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when lloyds collect option is chosen on the delivery wedge screen on the rds request flow
 *
 * @param {RdsRequestLocalCollect} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rdsRequestLocalCollect(
  props: RdsRequestLocalCollect,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "rds_request_local_collect",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the first page of the rds request flow is hit to select medications to order
 *
 * @param {RdsRequestPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rdsRequestPageview(
  props: RdsRequestPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "rds_request_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the review page is hit on the rds flow
 *
 * @param {RdsRequestReviewPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rdsRequestReviewPageview(
  props: RdsRequestReviewPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "rds_request_review_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the request next batch button is hit on the medication dashboard tile
 *
 * @param {RdsRequestStart} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rdsRequestStart(
  props: RdsRequestStart,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "rds_request_start",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the warning modal pops up for an rds request when the user doesnt select all available medications
 *
 * @param {RdsRequestWarningModal} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function rdsRequestWarningModal(
  props: RdsRequestWarningModal,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "rds_request_warning_modal",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When a patient clicks on the 'Read about the changes to LP collection' banner
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function readAboutLpChangesCollectionTrack(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "read_about_LP_changes_collection_track",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the first signup page is shown with info on referrals
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralsAccountInfoPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "referrals_account_info_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the banner on referrals is shown on the marketing page
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralsBannerWebsite(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "referrals_banner_website",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the choose reward button
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralsChooseReward(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "referrals_choose_reward",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the confirm button after choosing a reward
 *
 * @param {ReferralsChooseRewardConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralsChooseRewardConfirm(
  props: ReferralsChooseRewardConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "referrals_choose_reward_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page loads after a reward has successfully been chosen
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralsChooseRewardConfirmPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "referrals_choose_reward_confirm_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the copy button to copy the referrals link
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralsCopyLink(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "referrals_copy_link",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the email share button
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralsEmail(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "referrals_email",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the fb share button
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralsFacebook(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "referrals_facebook",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the sms and more button is hit for choosing the platform to share the referral link on
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralsMoreOptions(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "referrals_more_options",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the referrals nav is hit and the page is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralsPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "referrals_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the twitter share button
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function referralsTwitter(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "referrals_twitter",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the confirm button is hit for removing an exemption from the account tab
 *
 * @param {RemoveExemptionConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function removeExemptionConfirm(
  props: RemoveExemptionConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "remove_exemption_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the confirmation button is hit after choosing to remove a medication
 *
 * @param {RemoveMedicationConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function removeMedicationConfirm(
  props: RemoveMedicationConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "remove_medication_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page loads showing all the details of a request e.g. timeline of status'
 *
 * @param {RequestDetailsPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestDetailsPageview(
  props: RequestDetailsPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_details_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page showing the request history for a medication is shown
 *
 * @param {RequestHistoryPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestHistoryPageview(
  props: RequestHistoryPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_history_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires a 'request_medication_add_on_items_pageview' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationAddOnItemsPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_add_on_items_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page offering add ons in the request flow is shown
 *
 * @param {RequestMedicationAddonsPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationAddonsPageview(
  props: RequestMedicationAddonsPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_addons_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * on the review request screen when the user chooses to change their address
 *
 * @param {RequestMedicationChangeAddress} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationChangeAddress(
  props: RequestMedicationChangeAddress,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_change_address",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the collect from lloyds option is selected as the order delivery option
 *
 * @param {RequestMedicationCollectLloyds} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationCollectLloyds(
  props: RequestMedicationCollectLloyds,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_collect_lloyds",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when google maps is accessed to see the lloyds location
 *
 * @param {RequestMedicationCollectLloydsMap} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationCollectLloydsMap(
  props: RequestMedicationCollectLloydsMap,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_collect_lloyds_map",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits request now on the review your request screen

 *
 * @param {RequestMedicationConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationConfirm(
  props: RequestMedicationConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the deliver to an address option is selected for the delivery method
 *
 * @param {RequestMedicationDeliver} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationDeliver(
  props: RequestMedicationDeliver,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_deliver",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user has selected the medication they want to order and is taken to the screen to choose their delivery method
 *
 * @param {RequestMedicationDeliveryMethodPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationDeliveryMethodPageview(
  props: RequestMedicationDeliveryMethodPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_delivery_method_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user lands on the edit address page in the request medication flow
 *
 * @param {RequestMedicationEditAddressPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationEditAddressPageview(
  props: RequestMedicationEditAddressPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_edit_address_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user is going through their first request flow and hits confirm to add an exemption
 *
 * @param {RequestMedicationExemptionAddConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationExemptionAddConfirm(
  props: RequestMedicationExemptionAddConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_exemption_add_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user is going through their first request flow and hits confirm to add an exemption
 *
 * @param {RequestMedicationExemptionAddPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationExemptionAddPageview(
  props: RequestMedicationExemptionAddPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_exemption_add_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the find a collection point on the delivery wedge of the request flow
 *
 * @param {RequestMedicationLocalCollect} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationLocalCollect(
  props: RequestMedicationLocalCollect,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_local_collect",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * after the user has hit request medication, and the page showing a list of their medications they can request is shown
 *
 * @param {RequestMedicationPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationPageview(
  props: RequestMedicationPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user selects unavailable and available medication, is notified about partials, and confirms they would like partials
 *
 * @param {RequestMedicationPartialAccept} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationPartialAccept(
  props: RequestMedicationPartialAccept,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_partial_accept",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * after a user selects unavailable and available medication to request, and the prompt is shown checking theyre ok with partials
 *
 * @param {RequestMedicationPartialPrompt} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationPartialPrompt(
  props: RequestMedicationPartialPrompt,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_partial_prompt",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user selects unavailable and available medication, is notified about partials, and rejects partials
 *
 * @param {RequestMedicationPartialReject} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationPartialReject(
  props: RequestMedicationPartialReject,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_partial_reject",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the review your request page is shown

 *
 * @param {RequestMedicationReviewPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationReviewPageview(
  props: RequestMedicationReviewPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_review_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits 'request prescription' either from the requests tab or the medication tab
 *
 * @param {RequestMedicationStart} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationStart(
  props: RequestMedicationStart,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_start",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the 'learn more' button is hit after being shown on a medication with an unavailable stock warning
 *
 * @param {RequestMedicationUnavailableLearnMore} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationUnavailableLearnMore(
  props: RequestMedicationUnavailableLearnMore,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_unavailable_learn_more",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * after a user selects an unavailable medication to request, and the prompt is shown explaining the order may be delayed
 *
 * @param {RequestMedicationUnavailablePrompt} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function requestMedicationUnavailablePrompt(
  props: RequestMedicationUnavailablePrompt,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "request_medication_unavailable_prompt",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the button to resend a phone verification text
 *
 * @param {ResendPhone} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function resendPhone(
  props: ResendPhone,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "resend_phone",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the confirm button is hit for resetting a password from the account tab
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function resetPasswordConfirm(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "reset_password_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when someone attempts to reset their password but it fails
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function resetPasswordError(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "reset_password_error",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the reset password page is accessed from the account tab
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function resetPasswordPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "reset_password_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires a 'return_rx_complete_pageview' track call.
 *
 * @param {ReturnRxCompletePageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function returnRxCompletePageview(
  props: ReturnRxCompletePageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "return_rx_complete_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires a 'return_rx_intro_pageview' track call.
 *
 * @param {ReturnRxIntroPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function returnRxIntroPageview(
  props: ReturnRxIntroPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "return_rx_intro_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires a 'return_rx_patient_picker_pageview' track call.
 *
 * @param {ReturnRxPatientPickerPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function returnRxPatientPickerPageview(
  props: ReturnRxPatientPickerPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "return_rx_patient_picker_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires a 'return_rx_review_pageview' track call.
 *
 * @param {ReturnRxReviewPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function returnRxReviewPageview(
  props: ReturnRxReviewPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "return_rx_review_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires a 'return_rx_select_pageview' track call.
 *
 * @param {ReturnRxSelectPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function returnRxSelectPageview(
  props: ReturnRxSelectPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "return_rx_select_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Patient presses the help tab and reaches this page where they either press 'call us' or 'send us a message' to either contact the phone team or intercom team.
 *
 * @param {SelfHelpContactUs} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function selfHelpContactUs(
  props: SelfHelpContactUs,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "self_help_contact_us",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * after 'i need it sooner' is hit on an order waiting for stock, and 'send available medicine' is hit
 *
 * @param {SendPartialConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sendPartialConfirm(
  props: SendPartialConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "send_partial_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user has forgotten their password and requests a password reset email
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sendPasswordResetEmail(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "send_password_reset_email",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user lands on the page asking for an email and password
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupAccountInfoPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_account_info_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user lands on the page asking them for their phone number
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupAddPhonePageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_add_phone_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the button to send a confirmation email to another email address
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupChangeEmail(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_change_email",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the button to send a confirmation text to another phone number
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupChangePhone(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_change_phone",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user lands on the page asking them to check their email
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupCheckEmailPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_check_email_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user lands on the page asking them to check their phone to verify their number
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupCheckPhonePageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_check_phone_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires a 'signup_complete' track call.
 *
 * @param {SignupComplete} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupComplete(
  props: SignupComplete,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_complete",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user lands on the page asking if the pds trace has chosen the correct gp
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupConfirmGpPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_confirm_gp_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user lands on the page to confirm their manually chosen gp
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupConfirmManualGpPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_confirm_manual_gp_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when ‘ive confirmed my email’ is hit on the check email page
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupConfirmedEmailContinue(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_confirmed_email_continue",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits a signup button on the marketing website
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupCta(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_cta",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the signup with nhs login button on the marketing page
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupCtaNhsLogin(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_cta_nhs_login",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the suggested fix for an email typo
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupEmailTypoFix(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_email_typo_fix",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user is shown the error 'something went wrong' when they try to sign up with an email that already exists
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupErrorAccountDuplicate(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_error_account_duplicate",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the modal pops up with 
the error the user hasnt verified their email
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupErrorEmailVerification(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_error_email_verification",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the error modal is shown after a user uses an invalid verification link
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupErrorLinkInvalid(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_error_link_invalid",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the error stating another patient from the pds trace already exists is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupErrorPatientDuplicate(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_error_patient_duplicate",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the error stating another patient from the pds trace already exists is shown and the user creates the call to patient care from the popup
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupErrorPatientDuplicateCall(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_error_patient_duplicate_call",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the error stating another patient from the pds trace already exists is shown and the user hits 'send us a message'
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupErrorPatientDuplicateIntercom(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_error_patient_duplicate_intercom",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the error stating the users details couldnt be found on the nhs system is shown
 *
 * @param {SignupErrorPdsFail} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupErrorPdsFail(
  props: SignupErrorPdsFail,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_error_pds_fail",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user creates the call to patient care from the popup on the error page shown after failing their pds trace too many times
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupErrorPdsFailCall(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_error_pds_fail_call",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user opens intercom from the error page shown after failing their pds trace too many times
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupErrorPdsFailIntercom(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_error_pds_fail_intercom",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page asking the user to contact support is shown after 3 failed pds attempts
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupErrorPdsFailPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_error_pds_fail_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user lands on the page for manually searching their gp because the pds trace didnt choose the right one
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupManualGpSearchPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_manual_gp_search_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user in the signup flow confirms their gp where their gp is a N3P practice, and we work with their gp’s IM1 provider
 *
 * @param {SignupN3PConnectGpPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupN3PConnectGpPageview(
  props: SignupN3PConnectGpPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_n3p_connect_gp_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user in the signup flow confirms their gp where their gp is a N3P practice, clicks the ‘continue signing up’ button to connect manually to LD (via linkage keys), where we work with GP's IM1 provider
 *
 * @param {SignupN3PConnectManuallyContinue} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupN3PConnectManuallyContinue(
  props: SignupN3PConnectManuallyContinue,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_n3p_connect_manually_continue",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user in the signup flow confirms their gp where their gp is a N3P practice, clicks the ‘continue with nhs login’ button, where we work with GP's IM1 provider
 *
 * @param {SignupN3PNhsLoginContinue} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupN3PNhsLoginContinue(
  props: SignupN3PNhsLoginContinue,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_n3p_nhs_login_continue",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits 'continue with nhs login' on the popup explaining nhs login accessed from the initial account info page
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupNhsLoginContinue(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_nhs_login_continue",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits 'use your nhs login' on the initial account info page
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupNhsLoginInfo(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_nhs_login_info",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits 'learn more' on the popup explaining nhs login accessed from the initial account info page
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupNhsLoginLearnMore(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_nhs_login_learn_more",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page loads after a user confirms a no third party gp as their practice
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupNoThirdPartyGpPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_no_third_party_gp_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when someone chooses yes nominate echo and hits continue
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupNominateEchoConfirm(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_nominate_echo_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user lands on the page asking if theyd like to nominate echo
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupNominateEchoPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_nominate_echo_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page loads after a user confirms a non eps gp as their practice
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupNonEpsGpPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_non_eps_gp_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires a 'signup_pageview' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user in the signup flow is on the pds error screen and clicks the ‘call us’ button to contact PC to continue signing up
 *
 * @param {SignupPdsFailCall} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupPdsFailCall(
  props: SignupPdsFailCall,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_pds_fail_call",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user in the signup flow reaches the screen to connect manually via linkage keys (after failing pds trace) and hits the ‘connect to gp surgery’ button
 *
 * @param {SignupPdsFailConnectGpConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupPdsFailConnectGpConfirm(
  props: SignupPdsFailConnectGpConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_pds_fail_connect_gp_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user in the signup flow reaches this screen to connect with linkage keys, after failing pds and choosing to connect manually
 *
 * @param {SignupPdsFailConnectGpPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupPdsFailConnectGpPageview(
  props: SignupPdsFailConnectGpPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_pds_fail_connect_gp_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user in the signup flow is on the pds error screen and clicks the ‘connect manually’ button to connect manually to LP (via linkage keys)
 *
 * @param {SignupPdsFailConnectManuallyContinue} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupPdsFailConnectManuallyContinue(
  props: SignupPdsFailConnectManuallyContinue,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_pds_fail_connect_manually_continue",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user in the signup flow is on the pds error screen and clicks the ‘continue with nhs login’ button
 *
 * @param {SignupPdsFailNhsLoginContinue} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupPdsFailNhsLoginContinue(
  props: SignupPdsFailNhsLoginContinue,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_pds_fail_nhs_login_continue",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user in the signup flow reaches an error screen after failing their pds trace 3 times
 *
 * @param {SignupPdsFailPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupPdsFailPageview(
  props: SignupPdsFailPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_pds_fail_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user in the signup flow is on the pds error screen and clicks the ‘send us a message' button to contact PC to continue signing up
 *
 * @param {SignupPdsFailSendMessage} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupPdsFailSendMessage(
  props: SignupPdsFailSendMessage,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_pds_fail_send_message",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user lands on the page to manually input their details for a pds trace
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupPdsManualTracePageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_pds_manual_trace_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user lands on the page for adding their nhs number for a pds trace
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupPdsNhsNumberPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_pds_nhs_number_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user lands on the page asking whether they know their nhs number or whether they want to manually add their details
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupPdsOptionsPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_pds_options_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user has a successful pds trace via nhs number or manual trace
 *
 * @param {SignupPdsTraceSuccess} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupPdsTraceSuccess(
  props: SignupPdsTraceSuccess,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_pds_trace_success",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user lands on the page stating their phone number was verified and we need a few more details from them
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupPhoneConfirmedPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_phone_confirmed_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits 'call me instead' on the page for phone verification
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupPhoneVerifyCall(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_phone_verify_call",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the button to resend the verification email
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupResendEmail(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_resend_email",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits the button to resend a phone verification text
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupResendPhone(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_resend_phone",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user hits 'change spam settings' on the email verification page showing a popup on how to debug
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupSpamEmailInfo(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_spam_email_info",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when either signup via email or signup via nhs login is hit
 *
 * @param {SignupStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupStarted(
  props: SignupStarted,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_started",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user lands on the signup flow page after the gp confirmation page which states their gp is either not in england or is not in our database
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signupUnsupportedGpPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "signup_unsupported_gp_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When a sponsored article is closed by the patient
 *
 * @param {SponsoredcontentDemand01Close} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredcontentDemand01Close(
  props: SponsoredcontentDemand01Close,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "sponsoredcontent_demand_01_close",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Patient was shown  a list of sponsored content articles
 *
 * @param {SponsoredcontentDemand01ListMedicines} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredcontentDemand01ListMedicines(
  props: SponsoredcontentDemand01ListMedicines,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "sponsoredcontent_demand_01_list_medicines",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * When a patient dismisses sponsored content
 *
 * @param {SponsoredcontentDemand01NotInterested} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredcontentDemand01NotInterested(
  props: SponsoredcontentDemand01NotInterested,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "sponsoredcontent_demand_01_not_interested",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Patient was shown the sponsored content call to action
 *
 * @param {SponsoredcontentDemand01Prompt} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredcontentDemand01Prompt(
  props: SponsoredcontentDemand01Prompt,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "sponsoredcontent_demand_01_prompt",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Patient reads sponsored content, and how much they read
 *
 * @param {SponsoredcontentDemand01Read} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function sponsoredcontentDemand01Read(
  props: SponsoredcontentDemand01Read,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "sponsoredcontent_demand_01_read",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * Fires a 'terms_and_conditions_external_pageview' track call.
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function termsAndConditionsExternalPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "terms_and_conditions_external_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user hits 'track via royal mail' for their in flight order
 *
 * @param {TrackDelivery} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function trackDelivery(
  props: TrackDelivery,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "track_delivery",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user chooses to edit an existing delivery address
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function updateAddressPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "update_address_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the confirm button is hit on the update email page
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function updateEmailConfirm(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "update_email_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the update email page is accessed from the account tab
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function updateEmailPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "update_email_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the page with the option to update a medicines supply is shown
 *
 * @param {UpdateNextDueDatePageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function updateNextDueDatePageview(
  props: UpdateNextDueDatePageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "update_next_due_date_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the update phone page is accessed via the account tab
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function updatePhonePageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "update_phone_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the “confirm email” button is clicks on verify phone number flow
 *
 * @param {VerifyEmailConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function verifyEmailConfirm(
  props: VerifyEmailConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "verify_email_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when a user see’s the screen sayinng an email has been verified
 *
 * @param {VerifyEmailConfirmationPageview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function verifyEmailConfirmationPageview(
  props: VerifyEmailConfirmationPageview,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "verify_email_confirmation_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the test modal is closed
 *
 * @param {VitaminsTestClosed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vitaminsTestClosed(
  props: VitaminsTestClosed,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "vitamins_test_closed",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when confirming interest / no interest in experiment
 *
 * @param {VitaminsTestConfirm} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vitaminsTestConfirm(
  props: VitaminsTestConfirm,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "vitamins_test_confirm",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the “find out more” button is clicked and modal is opened
 *
 * @param {VitaminsTestOpened} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vitaminsTestOpened(
  props: VitaminsTestOpened,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "vitamins_test_opened",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the vitamins and supplements experiment is shown to a patient
 *
 * @param {VitaminsTestPrompt} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vitaminsTestPrompt(
  props: VitaminsTestPrompt,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "vitamins_test_prompt",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when survey is opened
 *
 * @param {VitaminsTestSurvey} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function vitaminsTestSurvey(
  props: VitaminsTestSurvey,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "vitamins_test_survey",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the user clicks a "watch video" CTA on the marketing site
 *
 * @param {WatchVideo} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function watchVideo(
  props: WatchVideo,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "watch_video",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}
/**
 * when the whats new tab is hit and the page is shown
 *
 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function whatsNewPageview(
  props?: Record<string, any>,
  options?: Segment.Options,
  callback?: Segment.Callback
): void {
  const a = analytics();
  if (a) {
    a.track(
      "whats_new_pageview",
      props || {},
      withTypewriterContext(options),
      callback
    );
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,
  /**
   * Patient clicks on the ‘Go Back’ button
   *
   * @param {SeOrderMedicineDeliveryGoBackClick} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  seOrderMedicineDeliveryGoBackClick,
  /**
   * Patient clicks on the ‘Find a Lloyds Pharmacy’ button
   *
   * @param {SeOrderMedicineDeliveryLpClick} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  seOrderMedicineDeliveryLpClick,
  /**
   * Patient clicks on the ‘Collect at this store’ button
   *
   * @param {SeOrderMedicineDeliveryLpClickCollect} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  seOrderMedicineDeliveryLpClickCollect,
  /**
   * Track If a patient clicks on the ‘Delivery  is unavailable’ banner
   *
   * @param {SeOrderMedicineDeliveryUnavailableClick} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  seOrderMedicineDeliveryUnavailableClick,
  /**
   * Track if a patient clicks in to the stock estimate button for more information
   *
   * @param {SeOrderMedicineEstimateButtonClick} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  seOrderMedicineEstimateButtonClick,
  /**
   * Patient dismissed the stock warnings modal, by any means.
   *
   * @param {SeOrderMedicineFindLloydsDismissModal} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  seOrderMedicineFindLloydsDismissModal,
  /**
   * Patient clicks on the ‘Go back’ button on the pop up
   *
   * @param {SeOrderMedicineFindLloydsGoBackClick} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  seOrderMedicineFindLloydsGoBackClick,
  /**
   * Track patients reaching the find lloyds pharmacy page
   *
   * @param {SeOrderMedicineFindLloydsPageView} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  seOrderMedicineFindLloydsPageView,
  /**
   * Track when a patient reaches the order medicine page
   *
   * @param {SeOrderMedicinePageView} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  seOrderMedicinePageView,
  /**
   * Track when a patient selects to order a medicine
   *
   * @param {SeOrderMedicineSelectClick} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  seOrderMedicineSelectClick,
  /**
   * Track when patient reaches the unavailable for delivery page
   *
   * @param {SeOrderMedicineUnavailableDeliveryPageView} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  seOrderMedicineUnavailableDeliveryPageView,
  /**
   * Track if a patient clicks in to the ‘Ordering medicine that’s unavailable for delivery?’ banner
   *
   * @param {SeOrderMedicineUnavailableForDeliveryBannerClick} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  seOrderMedicineUnavailableForDeliveryBannerClick,
  /**
   * Track if a patient clicks in to the ‘Ordering medicine that’s waiting for stock?’ banner
   *
   * @param {SeOrderMedicineWaitingForStockBannerClick} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  seOrderMedicineWaitingForStockBannerClick,
  /**
   * when the user lands on the change password page after accessing it from their account tab
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  accountChangePasswordPageview,
  /**
   * when an account has been deleted and the confirmation page is shown with options for reasons this was chosen
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  accountDeletedPageview,
  /**
   * when this page is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  accountPageview,
  /**
   * when the user confirms a preferred name change
   *
   * @param {AccountPreferredNameConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  accountPreferredNameConfirm,
  /**
   * when the user lands on the preferred name page after accessing it from the account nav
   *
   * @param {AccountPreferredNamePageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  accountPreferredNamePageview,
  /**
   * when a patient see's the 'action needed' banner
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  actionNeededBanner,
  /**
   * when an address has been added and confirmed
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addAddressConfirm,
  /**
   * when the first page of the add address flow is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addAddressPageview,
  /**
   * when a patient clicks 'add another person' in the account tab
   *
   * @param {AddAnotherPerson} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addAnotherPerson,
  /**
   * when a patient clicks 'add another person' in the patient switcher sidebar toggle
   *
   * @param {AddAnotherPersonSidebar} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addAnotherPersonSidebar,
  /**
   * when the page where you have to add an exemption expiry date loads
   *
   * @param {AddExemptionConfirm} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addExemptionConfirm,
  /**
   * when the page where you have to add an exemption expiry date loads
   *
   * @param {AddExemptionExpiryPageview} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addExemptionExpiryPageview,
  /**
   * when someone hits confirm to add an exemption but it fails
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addExemptionFailure,
  /**
   * when the page with a list of exemptions to choose from is shown
   *
   * @param {AddExemptionsPageview} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addExemptionsPageview,
  /**
   * when the user has chosen a date for their manually added medication and hits confirm
   *
   * @param {AddMedicationConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicationConfirm,
  /**
   * when the due date page is shown on the add medication flow
   *
   * @param {AddMedicationDueDatePageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicationDueDatePageview,
  /**
   * when ‘add medicine’ is hit and the page after  loads
   *
   * @param {AddMedicationPageview} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicationPageview,
  /**
   * on the add medication flow when the user picks a strength for the medication they selected
   *
   * @param {AddMedicationPickStrength} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicationPickStrength,
  /**
   * when a user hits 'Add new medicine' on the 'Your medicines' page in the add medicine flow
   *
   * @param {AddMedicineAddNewMedicine} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineAddNewMedicine,
  /**
   * when a user hits ‘Get it on Google Play’ on the ‘Not time to order’ page
   *
   * @param {AddMedicineAndroidDownload} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineAndroidDownload,
  /**
   * when a user hits ‘No, I've run out’ on the  'Do you have any [specified medicine] left?' page in the add medicine flow
   *
   * @param {AddMedicineAnyLeftNo} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineAnyLeftNo,
  /**
   * when a user views the 'Do you have any [specified medicine] left?' page in the add medicine flow
   *
   * @param {AddMedicineAnyLeftPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineAnyLeftPageview,
  /**
   * when a user hits ‘Yes, I have some left’ on the  'Do you have any [specified medicine] left?' page in the add medicine flow
   *
   * @param {AddMedicineAnyLeftYes} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineAnyLeftYes,
  /**
   * when a user hits ‘Download on the App Store’ on the ‘Not time to order’ page
   *
   * @param {AddMedicineAppleDownload} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineAppleDownload,
  /**
   * when a user views the appliance options search results page in the add medicine flow
   *
   * @param {AddMedicineAppliancePageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineAppliancePageview,
  /**
   * when a user hits the cross to exit the add medicine flow on any of the pages of the flow
   *
   * @param {AddMedicineExit} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineExit,
  /**
   * when a user hits done on the 'How many days medicine do you have left?' page in the add medicine flow
   *
   * @param {AddMedicineHowMuch} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineHowMuch,
  /**
   * when a user views the error message that appears on the 'How many days medicine do you have left?' in the add medicine flow
   *
   * @param {AddMedicineHowMuchErrorPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineHowMuchErrorPageview,
  /**
   * when a user views the 'How many days medicine do you have left?' page in the add medicine flow
   *
   * @param {AddMedicineHowMuchPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineHowMuchPageview,
  /**
   * when a user hits ‘Connect with NHS Login’ on the interstitial adding medicine screen to connect with NHS login and sync their repeat medicines
   *
   * @param {AddMedicineNhsLogin} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineNhsLogin,
  /**
   * when a user hits ‘Continue with NHS login’ button to navigate to the NHS site to connect as part of the adding medicine flow
   *
   * @param {AddMedicineNhsLoginContinue} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineNhsLoginContinue,
  /**
   * when a user views the intermediary ‘Connect with NHS login’ page after selecting to ‘continue with nhs login’ on the adding medicine page
   *
   * @param {AddMedicineNhsLoginPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineNhsLoginPageview,
  /**
   * when a user hits ‘Okay’ on the ‘Not time to order’ page
   *
   * @param {AddMedicineNotTimeToOrder} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineNotTimeToOrder,
  /**
   * when a user views the 'Not time to order' page after adding medicines on the add medicine flow
   *
   * @param {AddMedicineNotTimeToOrderPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineNotTimeToOrderPageview,
  /**
   * when a user views the interstitial ‘How would you like to add your medicine screen’ during the signup flow
   *
   * @param {AddMedicinePageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicinePageview,
  /**
   * when a user views the 'Safe custody' after searching for the medicine they require in the add medicine flow
   *
   * @param {AddMedicineSafeCustodyPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineSafeCustodyPageview,
  /**
   * when a user hits ‘Search for your medicines’ on the interstitial adding medicine screen to add their medicines manually
   *
   * @param {AddMedicineSearch} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineSearch,
  /**
   * when a user views the 'no results' pop up after entering their required medicine on the 'Search for your medicine' page
   *
   * @param {AddMedicineSearchNoResultsPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineSearchNoResultsPageview,
  /**
   * when a user views the 'Search for your medicine' page after selecting 'Search for your medicines'  on the interstitial adding medicine screen
   *
   * @param {AddMedicineSearchPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineSearchPageview,
  /**
   * when a user views the results on the 'Search for your medicine' page after selecting 'Search for your medicines'  on the interstitial adding medicine screen
   *
   * @param {AddMedicineSearchResultsPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineSearchResultsPageview,
  /**
   * when a user hits what medicine they require on the 'Search for your medicine'  page
   *
   * @param {AddMedicineSearchSelect} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineSearchSelect,
  /**
   * when a user hits ‘Skip for Now’ on the interstitial adding medicine screen to add medicines later
   *
   * @param {AddMedicineSkip} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineSkip,
  /**
   * when a user hits ‘Continue to order’ on the ‘Time to order’ page to create an order in the add medicine flow
   *
   * @param {AddMedicineTimeToOrderContinue} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineTimeToOrderContinue,
  /**
   * when a user views the 'Time to order' page after adding medicines on the add medicine flow
   *
   * @param {AddMedicineTimeToOrderPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineTimeToOrderPageview,
  /**
   * when a user hits ‘Skip for now’ on the ‘Time to order’ page to skip ordering immediately in the add medicine flow
   *
   * @param {AddMedicineTimeToOrderSkip} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineTimeToOrderSkip,
  /**
   * when a user clicks to view the video on the interstitial adding medicine screen (this does not navigate away from the screen)
   *
   * @param {AddMedicineVideo} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineVideo,
  /**
   * when a user views the 'What dose does this medication come in?' page in the add medicine flow
   *
   * @param {AddMedicineWhatDosePageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineWhatDosePageview,
  /**
   * when a user hits what dose of medication they require on the 'What does does this medication come in?'  page
   *
   * @param {AddMedicineWhatDoseSelect} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineWhatDoseSelect,
  /**
   * when a user views the 'What form does this medication come in?' page in the add medicine flow
   *
   * @param {AddMedicineWhatFormPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineWhatFormPageview,
  /**
   * when a user hits what form of medication they require on the 'What form does this medication come in?' page
   *
   * @param {AddMedicineWhatFormSelect} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineWhatFormSelect,
  /**
   * when a user hits ‘Continue’ on  the 'Which brand do you need?' page on the add medicine flow
   *
   * @param {AddMedicineWhichBrandContinue} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineWhichBrandContinue,
  /**
   * when a user hits which brand they require for their medicine on the 'Which brand do you need?' page on the add medicine flow
   *
   * @param {AddMedicineWhichBrandDropdown} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineWhichBrandDropdown,
  /**
   * when a user views the 'Which brand do you need?' page on the add medicine flow
   *
   * @param {AddMedicineWhichBrandPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineWhichBrandPageview,
  /**
   * when a user hits ‘Skip for now’ on  the 'Which brand do you need?' page on the add medicine flow
   *
   * @param {AddMedicineWhichBrandSkip} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineWhichBrandSkip,
  /**
   * when a user hits 'Done' on the 'Your medicines' page in the add medicine flow
   *
   * @param {AddMedicineYourMedicineDone} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineYourMedicineDone,
  /**
   * when a user hits 'Done' on the 'Your medicines' page when IM1 connected in the add medicine flow
   *
   * @param {AddMedicineYourMedicineIm1Done} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineYourMedicineIm1Done,
  /**
   * when a user hits the help article on the 'Your medicines' page and are IM1 connected in the add medicine flow
   *
   * @param {AddMedicineYourMedicineIm1Help} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineYourMedicineIm1Help,
  /**
   * when a user hits ‘Call GP Surgery’  on the ‘Update your medicine list' page after selecting to on the ‘Your medicines’ page
   *
   * @param {AddMedicineYourMedicineIm1HelpGp} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineYourMedicineIm1HelpGp,
  /**
   * when a user views the 'Update your medicine list' page after selecting to on the ‘Your medicines’ page
   *
   * @param {AddMedicineYourMedicineIm1HelpPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineYourMedicineIm1HelpPageview,
  /**
   * when a user views the 'Your medicines' page and are IM1 connected on the add medicine flow
   *
   * @param {AddMedicineYourMedicineIm1Pageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineYourMedicineIm1Pageview,
  /**
   * when a user views  the 'Your medicines' page in the add medicine flow
   *
   * @param {AddMedicineYourMedicinePageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addMedicineYourMedicinePageview,
  /**
   * when the user taps the “Add” button on the add-on items list
   *
   * @param {AddOnItemAdd} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addOnItemAdd,
  /**
   * when the user taps the "Info" button on the add-on items list
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addOnItemInfoPrompt,
  /**
   * when the user dismisses the info prompt for an add-on item
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addOnItemInfoPromptDismiss,
  /**
   * when the user expands a section of more information for an add-on item
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addOnItemInfoPromptToggle,
  /**
   * when the user taps “Continue” on the add-on items list
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addOnItemsConfirm,
  /**
   * when the user taps the “No thanks” button on the add-on items list
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addOnItemsIgnore,
  /**
   * when a payment method has been added and confirmed
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addPaymentMethodConfirm,
  /**
   * when confirm is hit at the end of the add payment flow and it fails
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addPaymentMethodFailure,
  /**
   * when the first page of the add payment method flow is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addPaymentMethodPageview,
  /**
   * when the add phone page is viewed
   *
   * @param {AddPhonePageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addPhonePageview,
  /**
	 * when the user hits “Add” button for an add-on item
	
	 *
	 * @param {AddonsAdd} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  addonsAdd,
  /**
	 * when the user hits the "Continue" button on the add-on items list
	
	 *
	 * @param {AddonsConfirm} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  addonsConfirm,
  /**
	 * when the user hits the “No thanks” button on the add-on items list
	
	 *
	 * @param {AddonsIgnore} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  addonsIgnore,
  /**
	 * when the user hits “Info” button on the add-on items list
	
	 *
	 * @param {AddonsInfoPrompt} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  addonsInfoPrompt,
  /**
   * when the user dismisses the info prompt for an add-on item
   *
   * @param {AddonsInfoPromptDismiss} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addonsInfoPromptDismiss,
  /**
   * when the user expands a section of more information for an add-on item
   *
   * @param {AddonsInfoPromptToggle} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  addonsInfoPromptToggle,
  /**
   * when the user hits continue after selecting any medication they want to accept
   *
   * @param {AskPatientAccept} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientAccept,
  /**
   * when the user is shown the barcode requested page during the ask patient flow
   *
   * @param {AskPatientBarcodeRequestedPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientBarcodeRequestedPageview,
  /**
   * when the user hits change address on the review page of the ask patient flow
   *
   * @param {AskPatientChangeAddress} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientChangeAddress,
  /**
   * when the user hits the 'collect from lloyds' button on the delivery method page in the ask patient flow
   *
   * @param {AskPatientCollectLloyds} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientCollectLloyds,
  /**
   * when the user opens the collect lloyds map on the delivery wedge screen on the ask patient flow
   *
   * @param {AskPatientCollectLloydsMap} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientCollectLloydsMap,
  /**
   * when the user hits confirm on the ask patient review page
   *
   * @param {AskPatientConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientConfirm,
  /**
   * when the user hits the 'deliver my medication' button on the delivery method page in the ask patient flow
   *
   * @param {AskPatientDeliver} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientDeliver,
  /**
   * when the user is shown the choose your delivery method page during the ask patient flow
   *
   * @param {AskPatientDeliveryMethodPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientDeliveryMethodPageview,
  /**
   * when the user is going through their first ask patient request flow and hits confirm to add an exemption
   *
   * @param {AskPatientExemptionAddConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientExemptionAddConfirm,
  /**
   * when the user has indicated they dont pay for rx's, and a list of exemptions to choose from is shown
   *
   * @param {AskPatientExemptionAddPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientExemptionAddPageview,
  /**
   * when the user hits the 'find a collection point' button on the delivery method page in the ask patient flow
   *
   * @param {AskPatientLocalCollect} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientLocalCollect,
  /**
   * when the user is shown the list of medications the gp has sent an rx for
   *
   * @param {AskPatientPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientPageview,
  /**
   * when the user hits continue after saying they dont want any of the medication
   *
   * @param {AskPatientReject} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientReject,
  /**
   * when the user hits the 'request perscription barcode' button on the delivery method page in the ask patient flow
   *
   * @param {AskPatientRequestBarcode} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientRequestBarcode,
  /**
   * when the user is shown the review page for their ask patient request
   *
   * @param {AskPatientReviewPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientReviewPageview,
  /**
   * when the confirm medicine button is hit on the dashboard to start the ask patient flow
   *
   * @param {AskPatientStart} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientStart,
  /**
   * when the user is shown the barcode requested page during the urgent ask patient flow
   *
   * @param {AskPatientUrgentBarcodeRequestedPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientUrgentBarcodeRequestedPageview,
  /**
   * when the user hits the 'find a lloyds pharmacy store' button or ‘collect at this store’ button on the delivery method page in the urgent ask patient flow
   *
   * @param {AskPatientUrgentCollectLloyds} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientUrgentCollectLloyds,
  /**
   * when the user opens the collect lloyds map on the delivery wedge screen on the urgent ask patient flow
   *
   * @param {AskPatientUrgentCollectLloydsMap} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientUrgentCollectLloydsMap,
  /**
   * when the user hits the 'choose delivery' button on the delivery method page in the urgent ask patient flow
   *
   * @param {AskPatientUrgentDeliver} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientUrgentDeliver,
  /**
   * when the user is shown the choose your delivery method page during the urgent ask patient flow
   *
   * @param {AskPatientUrgentDeliveryMethodPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientUrgentDeliveryMethodPageview,
  /**
   * when the user hits the 'find a collection point' button on the delivery method page in the urgent ask patient flow
   *
   * @param {AskPatientUrgentLocalCollect} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientUrgentLocalCollect,
  /**
   * when the user is shown the prescription confirmed page during the urgent ask patient flow
   *
   * @param {AskPatientUrgentPrescriptionConfirmedPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientUrgentPrescriptionConfirmedPageview,
  /**
   * when the user hits the 'request prescription barcode' button on the delivery method page in the urgent ask patient flow
   *
   * @param {AskPatientUrgentRequestBarcode} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientUrgentRequestBarcode,
  /**
   * when the confirm medicine button is hit on the dashboard to start the urgent ask patient flow
   *
   * @param {AskPatientUrgentStart} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  askPatientUrgentStart,
  /**
   * when the patient hits the 'switch to echo' button on the banner on the medicine tab
   *
   * @param {BannerNominateEcho} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  bannerNominateEcho,
  /**
   * when a patient see's the 'action needed' banner
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  brandSpecificActionNeededBanner,
  /**
   * When a patient lands on the Brand Warning page
   *
   * @param {BrandSpecificsBrandWarningPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  brandSpecificsBrandWarningPageview,
  /**
   * On the confirm prescription page when a patient selects in the drop down
   *
   * @param {BrandSpecificsConfirmPresDropDown} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  brandSpecificsConfirmPresDropDown,
  /**
   * When a patient selects 'I can take any medicine' on the confirm prescription page
   *
   * @param {BrandSpecificsConfirmPresDropDownIcantake} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  brandSpecificsConfirmPresDropDownIcantake,
  /**
   * When the patient lands on the Confirm prescription page
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  brandSpecificsConfirmPresPageview,
  /**
   * When a patient lands on the medicine tab
   *
   * @param {BrandSpecificsMedicineTabPageView} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  brandSpecificsMedicineTabPageView,
  /**
   * When a patient clicks on see available brands on the order prescription page
   *
   * @param {BrandSpecificsOrderPresNim1BrandClick} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  brandSpecificsOrderPresNim1BrandClick,
  /**
   * When a patient lands on the order prescription page
   *
   * @param {BrandSpecificsOrderPresNim1Pageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  brandSpecificsOrderPresNim1Pageview,
  /**
   * Brand Warning Interaction with drop down menu
   *
   * @param {BrandSpecificsSelectBrandWarning} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  brandSpecificsSelectBrandWarning,
  /**
   * when the button to call the patients gp is hit
   *
   * @param {CallGp} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  callGp,
  /**
   * when the user hits call lloydspharmacy on their request screen under their pending omnichannel order
   *
   * @param {CallLloydspharmacy} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  callLloydspharmacy,
  /**
   * When the prompt appears for cancelling pill review 'Do you want to cancel?'
   *
   * @param {CancelPillReviewPrompt} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  cancelPillReviewPrompt,
  /**
   * When the user clicks on 'cancel my review' on the 'Do you want to cancel?' prompt
   *
   * @param {CancelPillReviewPromptConfirm} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  cancelPillReviewPromptConfirm,
  /**
   * When the user clicks on 'Keep booking' on the 'Do you want to cancel?' prompt
   *
   * @param {CancelPillReviewPromptKeepBooking} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  cancelPillReviewPromptKeepBooking,
  /**
   * Why is the user cancelling the rx? Example 'getting medication elsewhere'
   *
   * @param {CancelRxCancellationReason} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  cancelRxCancellationReason,
  /**
   * Confirmation page of cancel rx
   *
   * @param {CancelRxConfirmationPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  cancelRxConfirmationPageview,
  /**
   * Selecting what medication to cancel page
   *
   * @param {CancelRxSelectMedicationPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  cancelRxSelectMedicationPageview,
  /**
   * when a user views the Medicine tab after the signup flow (where their gp is a N3P practice and we also don’t work with their IM1 provider)
   *
   * @param {CannotOrderGetMedicineDeliveredPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  cannotOrderGetMedicineDeliveredPageview,
  /**
   * when a new email is confirmed
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  changeEmailConfirm,
  /**
   * when the page to change your email is shown
   *
   * @param {ChangeEmailPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  changeEmailPageview,
  /**
   * when the patient has selected a gp from the change gp workflow and hits confirm
   *
   * @param {ChangeGpConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  changeGpConfirm,
  /**
   * when a patient clicks a link from the order review screen to change their gp (as the gp shown on the screen is not their correct gp)
   *
   * @param {ChangeGpOrderScreen} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  changeGpOrderScreen,
  /**
   * when the first page of the change gp flow is shown
   *
   * @param {ChangeGpPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  changeGpPageview,
  /**
   * when the page to change your password is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  changePasswordPageview,
  /**
   * when a ‘change password’ email is requested
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  changePasswordSendEmail,
  /**
   * when the user hits the button to send a confirmation text to another phone number
   *
   * @param {ChangePhoneConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  changePhoneConfirm,
  /**
   * when the page to change your phone no. is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  changePhonePageview,
  /**
   * when the user lands on the page saying there is a phone error.
   *
   * @param {CheckPhoneErrorPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkPhoneErrorPageview,
  /**
   * when the user lands on the page asking them to check their phone to verify their number
   *
   * @param {CheckPhonePageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  checkPhonePageview,
  /**
   * when a lloyds collection store is chosen from the search page
   *
   * @param {CollectLloydsAddressConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  collectLloydsAddressConfirm,
  /**
   * when the map is opened when choosing a lloyds to collect medication from
   *
   * @param {CollectLloydsMap} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  collectLloydsMap,
  /**
   * when the page to choose a lloyds to collect medication from is landed on
   *
   * @param {CollectLloydsPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  collectLloydsPageview,
  /**
   * when an LP pharmacy is searched when choosing a delivery option
   *
   * @param {CollectLloydsSearch} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  collectLloydsSearch,
  /**
   * when choosing a lloyds store to collect medication from, and a search is made, but no stores were found
   *
   * @param {CollectLloydsSearchError} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  collectLloydsSearchError,
  /**
   * when the user has added their linkage keys and hits confirm
   *
   * @param {ConnectGpConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  connectGpConfirm,
  /**
   * when the user adds their linkage keys, hits confirm, and an error is shown
   *
   * @param {ConnectGpError} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  connectGpError,
  /**
   * when the user has no medications and their gp is im1 enabled, and they hit the 'connect to gp' button on the tile on the dashboard, this page loads
   *
   * @param {ConnectGpPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  connectGpPageview,
  /**
   * when the user adds their linkage keys, hits confirm, and is successfully connected
   *
   * @param {ConnectGpSuccess} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  connectGpSuccess,
  /**
   * when the call button is hit on pages outside of the help centre (i.e. in account page or in signup)
   *
   * @param {ContactCallLloydsDirect} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  contactCallLloydsDirect,
  /**
   * when a user lands on contact details page
   *
   * @param {ContactDetailsPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  contactDetailsPageview,
  /**
   * When cookie consent is changed, log the categories consented to https://app-uk.onetrust.com/cookies/categorizations?tab=Categories
   *
   * @param {CookieConsentChanged} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  cookieConsentChanged,
  /**
   * when the dashboard home page loads
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  dashboardPageview,
  /**
   * when the prompt saying the user failed to be nominated to echo during signup is shown on the dashboard
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  dashboardPdsFail,
  /**
   * when the confirmation button on the delete account prompt is hit
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  deleteAccountConfirm,
  /**
   * when the confirm button is hit on a delivery address
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  deliveryAddressesDeleteConfirm,
  /**
   * when the user has open orders and we block a delivery type
   *
   * @param {DeliveryMethodBlock} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  deliveryMethodBlock,
  /**
   * Patient views the dose reminders page where they can change the day and view the dosage
   *
   * @param {DoseRemindersSummaryPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  doseRemindersSummaryPageview,
  /**
   * Patient changes the date and is viewing dose reminders for a day in the past or future
   *
   * @param {DoseRemindersSwitchDate} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  doseRemindersSwitchDate,
  /**
   * when the user hits the google play store or ios app store buttons to download the app
   *
   * @param {DownloadApp} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  downloadApp,
  /**
   * when the user clicks on the “x” to close the “download the LloydsDirect app” banner
   *
   * @param {DownloadAppClose} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  downloadAppClose,
  /**
   * when someone hits the confirmation button after editing their address
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  editAddressConfirm,
  /**
   * when the change button is hit on the medication reminder
   *
   * @param {EditReminder} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  editReminder,
  /**
   * when the ‘confirm’ button is hit after editing the medication's reminder
   *
   * @param {EditReminderConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  editReminderConfirm,
  /**
   * when the generic error modal is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  errorModal,
  /**
   * Patient clicks on the "Learn More" link next to the delivery options.]
   *
   * @param {ExemptPremiumDeliveryLearnMore} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  exemptPremiumDeliveryLearnMore,
  /**
   * Payment fails for exempt premium delivery
   *
   * @param {ExemptPremiumDeliveryPaymentFailed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  exemptPremiumDeliveryPaymentFailed,
  /**
   * Payment is successful for exempt premium delivery
   *
   * @param {ExemptPremiumDeliveryPaymentSuccess} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  exemptPremiumDeliveryPaymentSuccess,
  /**
   * Patient is shown exempt premium delivery card on main medications list view
   *
   * @param {ExemptPremiumDeliveryPrompt} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  exemptPremiumDeliveryPrompt,
  /**
   * Patient explicitly decides to stick with normal delivery instead of upgrading
   *
   * @param {ExemptPremiumDeliveryRejected} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  exemptPremiumDeliveryRejected,
  /**
   * Patient views exempt premium delivery upgrade and payment page
   *
   * @param {ExemptPremiumDeliveryReview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  exemptPremiumDeliveryReview,
  /**
   * Patient decides to upgrade their order to premium delivery
   *
   * @param {ExemptPremiumDeliveryUpgraded} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  exemptPremiumDeliveryUpgraded,
  /**
   * when the page explaining exemptions is loaded after hitting ‘add an exemption or prepayment certificate’
   *
   * @param {ExemptionsPageview} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  exemptionsPageview,
  /**
   * on load of a experiment page
   *
   * @param {Experiment} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  experiment,
  /**
   * when the user hits any link to an external page on the marketing website e.g. nhs app library, nhs exemption info etc
   *
   * @param {ExternalLink} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  externalLink,
  /**
   * When the patients first order is confirmed
   *
   * @param {FirstOrderConfirmed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  firstOrderConfirmed,
  /**
   * when the page after clicking forgotten password is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  forgotPasswordPageview,
  /**
   * when a user submits a response to the 'how did you hear about us' survey
   *
   * @param {HdyhauSurveyComplete} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  hdyhauSurveyComplete,
  /**
   * when a help article page is shown
   *
   * @param {HelpArticle} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  helpArticle,
  /**
   * when an action is hit on the help centre article
   *
   * @param {HelpArticleAction} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  helpArticleAction,
  /**
   * when a help article page loads
   *
   * @param {HelpArticlePageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  helpArticlePageview,
  /**
   * when the call button is hit on the contact us page of the help centre
   *
   * @param {HelpContactCall} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  helpContactCall,
  /**
   * when the message button is hit on the contact us page of the help centre
   *
   * @param {HelpContactMessage} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  helpContactMessage,
  /**
   * when the contact us page is shown in the help centre
   *
   * @param {HelpContactUsPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  helpContactUsPageview,
  /**
   * when the help centre page is shown with a list of help articles
   *
   * @param {HelpPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  helpPageview,
  /**
   * Fires a 'help_patient_picker_pageview' track call.
   *
   * @param {HelpPatientPickerPageview} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  helpPatientPickerPageview,
  /**
   * when a user clicks ‘Connect with your GP’ to connect via IM1 from the account page (they could be N3P or not)
   *
   * @param {Im1ConnectGp} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  im1ConnectGp,
  /**
   * when a user clicks ‘Connect manually’ button from the Connect with your GP page
   *
   * @param {Im1ConnectWithGpManually} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  im1ConnectWithGpManually,
  /**
   * when a user clicks ‘Connect with NHS login’ button from the Connect with your GP page
   *
   * @param {Im1ConnectWithGpNhsLogin} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  im1ConnectWithGpNhsLogin,
  /**
   * when a user views the the 'connect with gp' screen to connect via IM1
   *
   * @param {Im1ConnectWithGpPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  im1ConnectWithGpPageview,
  /**
   * when a user who is IM1 connected and requires their medications to be updated clicks the ‘call gp surgery’ button so their medicine list can be updated by their gp
   *
   * @param {Im1UpdateMedicineCallGp} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  im1UpdateMedicineCallGp,
  /**
   * when a user views the ‘Update your medicine list’ page for im1 patients
   *
   * @param {Im1UpdateMedicineListPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  im1UpdateMedicineListPageview,
  /**
   * when the ‘your account doesnt seem complete’ tile is shown
   *
   * @param {IncompleteAccount} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  incompleteAccount,
  /**
   * when collect from this address button is hit
   *
   * @param {LocalCollectAddressConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localCollectAddressConfirm,
  /**
   * when the user is shown the page to find a local collect point
   *
   * @param {LocalCollectPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localCollectPageview,
  /**
   * when the user searches for an address and is shown no collection points found
   *
   * @param {LocalCollectSearchError} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  localCollectSearchError,
  /**
   * when the login button is hit
   *
   * @param {Login} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  login,
  /**
   * when the user hits a login button on the marketing website
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  loginCta,
  /**
   * when a failed login occurs because of an invalid password
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  loginFailureNotValid,
  /**
   * when the login page is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  loginPageview,
  /**
   * when a successful login occurs
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  loginSuccessful,
  /**
   * when the page showing the patients addresses is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  manageAddressesPageview,
  /**
   * When an account views the 'Something went wrong unsubscribing' page after clicking to unsubscribe on a marketing email
   *
   * @param {MarketingUnsubscribeFailurePageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  marketingUnsubscribeFailurePageview,
  /**
   * When an account views the 'Unsubscribe Successful' page after clicking to unsubscribe on a marketing email
   *
   * @param {MarketingUnsubscribeSuccessPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  marketingUnsubscribeSuccessPageview,
  /**
   * when the user hits the "Add exemption" link on the prescription payment card
   *
   * @param {MedicationAddExemption} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  medicationAddExemption,
  /**
   * when the user hits the "Confirm prescription" button in the ask patient card on the medicine screen
   *
   * @param {MedicationConfirmPrescription} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  medicationConfirmPrescription,
  /**
   * where the user clicks through to the details of a certain medication including dosage instructions and reminders
   *
   * @param {MedicationDetailsPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  medicationDetailsPageview,
  /**
   * when the medication page is rendered
   *
   * @param {MedicationPageview} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  medicationPageview,
  /**
   * when the user hits the "Pay now" button on the prescription payment card
   *
   * @param {MedicationPayNow} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  medicationPayNow,
  /**
   * when the edit to the medication supply has been confirmed
   *
   * @param {MedicationSupplyChangeConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  medicationSupplyChangeConfirm,
  /**
   * when the page to change a medications supply details is shown
   *
   * @param {MedicationSupplyPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  medicationSupplyPageview,
  /**
   * When a patient clicks 'remind me later' on the prompt to migrate to P2U
   *
   * @param {MigrationRemindLater} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  migrationRemindLater,
  /**
   * when a user’s gp changes to n3p post-signup (and the user is prevented from ordering) and the user clicks ‘Connect with your GP’ to connect via IM1
   *
   * @param {N3PIm1ConnectGp} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  n3PIm1ConnectGp,
  /**
   * when the account button is hit on the nav bar
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  navAccount,
  /**
   * when the help button is hit on the nav bar
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  navHelp,
  /**
   * when the home button is hit on the nav bar
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  navHome,
  /**
   * when the user clicks the "Shop" link in the main navigation
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  navLloydsShop,
  /**
   * when the logout button is hit on the nav bar
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  navLogout,
  /**
   * when the medication button is hit on the nav bar
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  navMedication,
  /**
   * when the user hits the invite a friend button on the nav bar
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  navReferrals,
  /**
   * when the request button is hit on the nav bar
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  navRequest,
  /**
   * when the user clicks the "What's new" link in the main navigation
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  navWhatsNew,
  /**
   * when the patients order is waiting for stock and the 'i need it sooner' button is hit, showing the bottom sheet
   *
   * @param {NeedMedicationSoonerPrompt} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  needMedicationSoonerPrompt,
  /**
   * User cancels email change
   *
   * @param {NewEmailCancelIntent} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newEmailCancelIntent,
  /**
   * User updates the address on intent from the "Check your email" page
   *
   * @param {NewEmailChangeEmail} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newEmailChangeEmail,
  /**
   * User visits "check your email" page after starting update flow
   *
   * @param {NewEmailCheckEmailPageview} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newEmailCheckEmailPageview,
  /**
   * User clicks \"I have already confirmed my email address\" when viewing email change review screen
   *
   * @param {NewEmailConfirmedEmailContinue} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newEmailConfirmedEmailContinue,
  /**
   * User corrects email address from suggested typo fix
   *
   * @param {NewEmailEmailTypoFix} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newEmailEmailTypoFix,
  /**
   * User tries to change email address to one which exists on another account
   *
   * @param {NewEmailErrorAccountDuplicate} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newEmailErrorAccountDuplicate,
  /**
   * User clicks "I've already confirmed by address" but address has not been confirmed
   *
   * @param {NewEmailErrorEmailVerification} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newEmailErrorEmailVerification,
  /**
   * User tries to change email address to the address already used for another account
   *
   * @param {NewEmailErrorExisting} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newEmailErrorExisting,
  /**
   * User follows verification link in email but link is invalid (most likely expired)
   *
   * @param {NewEmailErrorLinkInvalid} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newEmailErrorLinkInvalid,
  /**
   * User tries to set a new email address but an unexpected error occurs
   *
   * @param {NewEmailErrorOther} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newEmailErrorOther,
  /**
   * User views update email form
   *
   * @param {NewEmailPageview} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newEmailPageview,
  /**
   * User resends verification email
   *
   * @param {NewEmailResendEmail} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newEmailResendEmail,
  /**
   * User sets a new email address, but has not yet verified
   *
   * @param {NewEmailSetIntent} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newEmailSetIntent,
  /**
   * User clicks on link offering more information on avoiding spam
   *
   * @param {NewEmailSpamEmailInfo} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newEmailSpamEmailInfo,
  /**
   * User successfully verifies a new email address
   *
   * @param {NewEmailVerified} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  newEmailVerified,
  /**
   * page shown when there is an error creating an account for a user after logging in with nhs login
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  nhsLoginAccountCreationErrorPageview,
  /**
	 * Screen shown while the account creation process is being completed
	
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  nhsLoginAccountCreationPageview,
  /**
	 * Terms and consent screen for NHS login signups
	
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  nhsLoginAccountCreationTermsPageview,
  /**
   * page shown when there is an error when logging into nhs login
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  nhsLoginErrorPageview,
  /**
	 * Terms/consent when an existing Echo user uses NHS login for the first time
	
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  nhsLoginExistingUserConsentPageview,
  /**
   * track when there is a successful login to nhs login
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  nhsLoginSuccess,
  /**
   * page shown when nhs login is unavailable
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  nhsLoginUnavaliablePageview,
  /**
	 * view the page for confirming the nok address
	
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  nokConfirmAddressPageview,
  /**
   * when the patient details page is shown on the add nok flow
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  nokDetailsPageview,
  /**
   * when the first page of the add nok flow is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  nokSearchGpPageview,
  /**
   * when the terms page is shown on the add nok flow
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  nokTermsPageview,
  /**
   * when a patient who placed three orders sees the ‘your order is in’ screen, with the option to continue to the new noks screen
   *
   * @param {NoksOrderConfirmed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  noksOrderConfirmed,
  /**
   * when a patient sees the screen encouraging them to add a nok at the end of the order flow (when they’ve placed three orders)
   *
   * @param {NoksPopup} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  noksPopup,
  /**
   * when a patient hits ‘add another person’ on the noks popup screen at the end of the order flow (when they’ve placed three orders)
   *
   * @param {NoksPopupAddAnotherPerson} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  noksPopupAddAnotherPerson,
  /**
   * when a patient closes the noks popup screen at the end of the order flow (when they’ve placed three orders)
   *
   * @param {NoksPopupClose} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  noksPopupClose,
  /**
   * when a patient hits ‘not right now’ on the noks popup screen at the end of the order flow (when they’ve placed three orders)
   *
   * @param {NoksPopupNotNow} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  noksPopupNotNow,
  /**
   * after hitting the button on the 'switch to echo' banner, and they hit the switch to echo confirmation button on that page
   *
   * @param {NominateEchoConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  nominateEchoConfirm,
  /**
   * after hitting the button on the 'switch to echo' banner and the page  after loads
   *
   * @param {NominateEchoPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  nominateEchoPageview,
  /**
   * when the not found error page is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  notFoundPageview,
  /**
   * when a user lands on the notification preferences pages
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  notificationsPageview,
  /**
   * When the user intentionally opens intercom. This should not be fired when intercom is opened as a result of a new message being sent to the patient
   *
   * @param {OpenIntercom} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  openIntercom,
  /**
   * Fires a 'partial_dispensing_confirmation_pageview' track call.
   *
   * @param {PartialDispensingConfirmationPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  partialDispensingConfirmationPageview,
  /**
   * Fires a 'partial_dispensing_intro_pageview' track call.
   *
   * @param {PartialDispensingIntroPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  partialDispensingIntroPageview,
  /**
   * Fires a 'partial_dispensing_review_pageview' track call.
   *
   * @param {PartialDispensingReviewPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  partialDispensingReviewPageview,
  /**
   * on the partner request flow when a warning stating its faster to add medication via the mygp app
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  partnerAddMedicationWarningPageview,
  /**
   * on the partner request flow when the delivery method is confirmed
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  partnerArrangeDeliveryConfirmationPageview,
  /**
   * on the partner request flow when the choose delivery method page is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  partnerArrangeDeliveryReviewPageview,
  /**
   * when the partner user tries to make a request but it fails
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  partnerConfirmRequestFail,
  /**
   * when the partner user tries to pay for their rx but there is an error
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  partnerPaymentError,
  /**
   * on the partner request flow when a warning stating its faster to request medication via the mygp app
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  partnerRequestPrescriptionWarningPageview,
  /**
   * on the partner request flow when the list of their requestable medications is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  partnerSelectMedicationsPageview,
  /**
   * on the partner signup flow when the page asking for account details is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  partnerSignupAccountPageview,
  /**
   * on the partner signup flow when the page asking for address is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  partnerSignupAddressPageview,
  /**
   * when the partner signup flow is completed
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  partnerSignupComplete,
  /**
   * first intro page of the partner signup flow
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  partnerSignupIntroPageview,
  /**
   * on the partner signup flow when the terms page is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  partnerSignupTermsPageview,
  /**
   * on the partner signup flow when the page asking for email verification is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  partnerSignupVerifyEmailPageview,
  /**
   * when a patient is chosen from the drop down on the sidebar of patients on that account
   *
   * @param {PatientChosen} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  patientChosen,
  /**
   * when the patient hits the switch to echo button confirmation button on the patient details page
   *
   * @param {PatientDetailsNominateEchoConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  patientDetailsNominateEchoConfirm,
  /**
   * when the patient details page loads in the account tab
   *
   * @param {PatientDetailsPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  patientDetailsPageview,
  /**
   * when the drop down on the sidebar showing the list of patients for the account is hit
   *
   * @param {PatientsListOpen} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  patientsListOpen,
  /**
   * when the confirm button is hit on the payment flow
   *
   * @param {PaymentConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentConfirm,
  /**
   * when payment has been made successfully and the confirmation page is shown
   *
   * @param {PaymentConfirmationPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentConfirmationPageview,
  /**
   * when a payment failure occurs
   *
   * @param {PaymentFailed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentFailed,
  /**
   * patient changes the method they would like to pay with and adds a payment method
   *
   * @param {PaymentMethodChanged} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentMethodChanged,
  /**
   * when someone hits the confirmation button after choosing to delete a payment method
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentMethodsDeleteConfirm,
  /**
   * when someone picks their primary payment method
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentMethodsMakePrimary,
  /**
   * when the page showing the patients payment methods is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentMethodsPageview,
  /**
   * when the learn more button is shown on the payment screen with the premium delivery option
   *
   * @param {PaymentPremiumDeliveryLearnMore} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentPremiumDeliveryLearnMore,
  /**
	 * Patient reviews order before pressing 'pay' button
	
	 *
	 * @param {PaymentReviewPageview} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  paymentReviewPageview,
  /**
   * when the premium delivery upgrade option is shown on the payment screen
   *
   * @param {PaymentShownPremiumDelivery} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentShownPremiumDelivery,
  /**
   * the patient successfully pays for their order
   *
   * @param {PaymentSuccessful} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  paymentSuccessful,
  /**
   * page shown asking user to contact support after too many failed trace attempts
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pdsContactSupportPageview,
  /**
   * page shown with input fields after a user hits update details after a failed pds trace during signup
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pdsUpdateDetailsPageview,
  /**
   * when the prompt saying a pds trace attempt failed is shown on the update details page
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pdsUpdateFail,
  /**
   * page shown after a user successfully updated their pds info
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pdsUpdateSuccessPageview,
  /**
   * when the banner “phone number confirmed” is shown
   *
   * @param {PhoneConfirmedPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  phoneConfirmedPageview,
  /**
   * when the user hits 'call me instead' on the page for phone verification
   *
   * @param {PhoneVerifyCall} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  phoneVerifyCall,
  /**
   * When the page loads for the 'Can you measure your blood pressure' page
   *
   * @param {PillReviewBloodPressurePageview} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pillReviewBloodPressurePageview,
  /**
   * When the page loads for the 'your pill review is booked' page
   *
   * @param {PillReviewBookedPageview} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pillReviewBookedPageview,
  /**
   * When the page loads for the 'When would you like to have your pill review' page
   *
   * @param {PillReviewCalendarBookingPageview} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pillReviewCalendarBookingPageview,
  /**
   * When the prompt appears for the combined pill instructions
   *
   * @param {PillReviewCombinedPrompt} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pillReviewCombinedPrompt,
  /**
   * When the user clicks on 'Order without booking' on the 'Sorry, we cannot do your pill review this time?' in the measure blood pressure prompt
   *
   * @param {PillReviewNoBloodPressureConfirm} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pillReviewNoBloodPressureConfirm,
  /**
   * When the page loads for the 'Sorry, we cannot do your pill review this time?' in the measure blood pressure prompt
   *
   * @param {PillReviewNoBloodPressurePageview} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pillReviewNoBloodPressurePageview,
  /**
   * When the user clicks on 'Order without booking' on the 'Sorry, we cannot do your pill review this time' in the pill schedule prompt
   *
   * @param {PillReviewNoPillScheduleConfirm} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pillReviewNoPillScheduleConfirm,
  /**
   * When the page loads for the 'Sorry, we cannot do your pill review this time?' in the pill schedule prompt
   *
   * @param {PillReviewNoPillSchedulePageview} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pillReviewNoPillSchedulePageview,
  /**
   * When the page loads for the 'book a pill review' page
   *
   * @param {PillReviewPageview} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pillReviewPageview,
  /**
   * When the page loads for the 'what is your phone number' page
   *
   * @param {PillReviewPhoneNumberPageview} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pillReviewPhoneNumberPageview,
  /**
   * When the page loads for the 'have you been sticking to your pill schedule' page
   *
   * @param {PillReviewPillSchedulePageview} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pillReviewPillSchedulePageview,
  /**
   * When the prompt appears for the progesterone only pill instructions
   *
   * @param {PillReviewProgesteronePrompt} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  pillReviewProgesteronePrompt,
  /**
   * Fires a 'privacy_policy_external_pageview' track call.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  privacyPolicyExternalPageview,
  /**
   * when lloyds collects is chosen on the delivery wedge screen on the rds request flow
   *
   * @param {RdsRequestCollectLloyds} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  rdsRequestCollectLloyds,
  /**
   * when lloyds collect map is opened on the delivery wedge screen on the rds request flow
   *
   * @param {RdsRequestCollectLloydsMap} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  rdsRequestCollectLloydsMap,
  /**
   * when the confirm button is hit on the end of the rds request flow
   *
   * @param {RdsRequestConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  rdsRequestConfirm,
  /**
   * when delivery is chosen on the delivery wedge screen on the rds request flow
   *
   * @param {RdsRequestDeliver} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  rdsRequestDeliver,
  /**
   * when the delivery method wedge is viewed on the rds request flow
   *
   * @param {RdsRequestDeliveryMethodPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  rdsRequestDeliveryMethodPageview,
  /**
   * when the user states yes there has been a change, writes the change, and hits continue in the rds flow
   *
   * @param {RdsRequestHealthChange} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  rdsRequestHealthChange,
  /**
   * when the page asking if anything has changed with their health is hit in the rds flow
   *
   * @param {RdsRequestHealthCheckPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  rdsRequestHealthCheckPageview,
  /**
   * when lloyds collect option is chosen on the delivery wedge screen on the rds request flow
   *
   * @param {RdsRequestLocalCollect} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  rdsRequestLocalCollect,
  /**
   * when the first page of the rds request flow is hit to select medications to order
   *
   * @param {RdsRequestPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  rdsRequestPageview,
  /**
   * when the review page is hit on the rds flow
   *
   * @param {RdsRequestReviewPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  rdsRequestReviewPageview,
  /**
   * when the request next batch button is hit on the medication dashboard tile
   *
   * @param {RdsRequestStart} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  rdsRequestStart,
  /**
   * when the warning modal pops up for an rds request when the user doesnt select all available medications
   *
   * @param {RdsRequestWarningModal} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  rdsRequestWarningModal,
  /**
   * When a patient clicks on the 'Read about the changes to LP collection' banner
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  readAboutLpChangesCollectionTrack,
  /**
   * when the first signup page is shown with info on referrals
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  referralsAccountInfoPageview,
  /**
   * when the banner on referrals is shown on the marketing page
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  referralsBannerWebsite,
  /**
   * when the user hits the choose reward button
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  referralsChooseReward,
  /**
   * when the user hits the confirm button after choosing a reward
   *
   * @param {ReferralsChooseRewardConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  referralsChooseRewardConfirm,
  /**
   * when the page loads after a reward has successfully been chosen
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  referralsChooseRewardConfirmPageview,
  /**
   * when the user hits the copy button to copy the referrals link
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  referralsCopyLink,
  /**
   * when the user hits the email share button
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  referralsEmail,
  /**
   * when the user hits the fb share button
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  referralsFacebook,
  /**
   * when the sms and more button is hit for choosing the platform to share the referral link on
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  referralsMoreOptions,
  /**
   * when the referrals nav is hit and the page is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  referralsPageview,
  /**
   * when the user hits the twitter share button
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  referralsTwitter,
  /**
   * when the confirm button is hit for removing an exemption from the account tab
   *
   * @param {RemoveExemptionConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  removeExemptionConfirm,
  /**
   * when the confirmation button is hit after choosing to remove a medication
   *
   * @param {RemoveMedicationConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  removeMedicationConfirm,
  /**
   * when the page loads showing all the details of a request e.g. timeline of status'
   *
   * @param {RequestDetailsPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestDetailsPageview,
  /**
   * when the page showing the request history for a medication is shown
   *
   * @param {RequestHistoryPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestHistoryPageview,
  /**
   * Fires a 'request_medication_add_on_items_pageview' track call.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestMedicationAddOnItemsPageview,
  /**
   * when the page offering add ons in the request flow is shown
   *
   * @param {RequestMedicationAddonsPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestMedicationAddonsPageview,
  /**
   * on the review request screen when the user chooses to change their address
   *
   * @param {RequestMedicationChangeAddress} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestMedicationChangeAddress,
  /**
   * when the collect from lloyds option is selected as the order delivery option
   *
   * @param {RequestMedicationCollectLloyds} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestMedicationCollectLloyds,
  /**
   * when google maps is accessed to see the lloyds location
   *
   * @param {RequestMedicationCollectLloydsMap} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestMedicationCollectLloydsMap,
  /**
	 * when a user hits request now on the review your request screen
	
	 *
	 * @param {RequestMedicationConfirm} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  requestMedicationConfirm,
  /**
   * when the deliver to an address option is selected for the delivery method
   *
   * @param {RequestMedicationDeliver} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestMedicationDeliver,
  /**
   * when a user has selected the medication they want to order and is taken to the screen to choose their delivery method
   *
   * @param {RequestMedicationDeliveryMethodPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestMedicationDeliveryMethodPageview,
  /**
   * when the user lands on the edit address page in the request medication flow
   *
   * @param {RequestMedicationEditAddressPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestMedicationEditAddressPageview,
  /**
   * when the user is going through their first request flow and hits confirm to add an exemption
   *
   * @param {RequestMedicationExemptionAddConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestMedicationExemptionAddConfirm,
  /**
   * when the user is going through their first request flow and hits confirm to add an exemption
   *
   * @param {RequestMedicationExemptionAddPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestMedicationExemptionAddPageview,
  /**
   * when the user hits the find a collection point on the delivery wedge of the request flow
   *
   * @param {RequestMedicationLocalCollect} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestMedicationLocalCollect,
  /**
   * after the user has hit request medication, and the page showing a list of their medications they can request is shown
   *
   * @param {RequestMedicationPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestMedicationPageview,
  /**
   * when the user selects unavailable and available medication, is notified about partials, and confirms they would like partials
   *
   * @param {RequestMedicationPartialAccept} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestMedicationPartialAccept,
  /**
   * after a user selects unavailable and available medication to request, and the prompt is shown checking theyre ok with partials
   *
   * @param {RequestMedicationPartialPrompt} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestMedicationPartialPrompt,
  /**
   * when the user selects unavailable and available medication, is notified about partials, and rejects partials
   *
   * @param {RequestMedicationPartialReject} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestMedicationPartialReject,
  /**
	 * when the review your request page is shown
	
	 *
	 * @param {RequestMedicationReviewPageview} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  requestMedicationReviewPageview,
  /**
   * when the user hits 'request prescription' either from the requests tab or the medication tab
   *
   * @param {RequestMedicationStart} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestMedicationStart,
  /**
   * when the 'learn more' button is hit after being shown on a medication with an unavailable stock warning
   *
   * @param {RequestMedicationUnavailableLearnMore} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestMedicationUnavailableLearnMore,
  /**
   * after a user selects an unavailable medication to request, and the prompt is shown explaining the order may be delayed
   *
   * @param {RequestMedicationUnavailablePrompt} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  requestMedicationUnavailablePrompt,
  /**
   * when the user hits the button to resend a phone verification text
   *
   * @param {ResendPhone} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  resendPhone,
  /**
   * when the confirm button is hit for resetting a password from the account tab
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  resetPasswordConfirm,
  /**
   * when someone attempts to reset their password but it fails
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  resetPasswordError,
  /**
   * when the reset password page is accessed from the account tab
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  resetPasswordPageview,
  /**
   * Fires a 'return_rx_complete_pageview' track call.
   *
   * @param {ReturnRxCompletePageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  returnRxCompletePageview,
  /**
   * Fires a 'return_rx_intro_pageview' track call.
   *
   * @param {ReturnRxIntroPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  returnRxIntroPageview,
  /**
   * Fires a 'return_rx_patient_picker_pageview' track call.
   *
   * @param {ReturnRxPatientPickerPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  returnRxPatientPickerPageview,
  /**
   * Fires a 'return_rx_review_pageview' track call.
   *
   * @param {ReturnRxReviewPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  returnRxReviewPageview,
  /**
   * Fires a 'return_rx_select_pageview' track call.
   *
   * @param {ReturnRxSelectPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  returnRxSelectPageview,
  /**
   * Patient presses the help tab and reaches this page where they either press 'call us' or 'send us a message' to either contact the phone team or intercom team.
   *
   * @param {SelfHelpContactUs} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  selfHelpContactUs,
  /**
   * after 'i need it sooner' is hit on an order waiting for stock, and 'send available medicine' is hit
   *
   * @param {SendPartialConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sendPartialConfirm,
  /**
   * when a user has forgotten their password and requests a password reset email
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sendPasswordResetEmail,
  /**
   * when the user lands on the page asking for an email and password
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupAccountInfoPageview,
  /**
   * when the user lands on the page asking them for their phone number
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupAddPhonePageview,
  /**
   * when the user hits the button to send a confirmation email to another email address
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupChangeEmail,
  /**
   * when the user hits the button to send a confirmation text to another phone number
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupChangePhone,
  /**
   * when the user lands on the page asking them to check their email
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupCheckEmailPageview,
  /**
   * when the user lands on the page asking them to check their phone to verify their number
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupCheckPhonePageview,
  /**
   * Fires a 'signup_complete' track call.
   *
   * @param {SignupComplete} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupComplete,
  /**
   * when the user lands on the page asking if the pds trace has chosen the correct gp
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupConfirmGpPageview,
  /**
   * when the user lands on the page to confirm their manually chosen gp
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupConfirmManualGpPageview,
  /**
   * when ‘ive confirmed my email’ is hit on the check email page
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupConfirmedEmailContinue,
  /**
   * when the user hits a signup button on the marketing website
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupCta,
  /**
   * when the user hits the signup with nhs login button on the marketing page
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupCtaNhsLogin,
  /**
   * when the user hits the suggested fix for an email typo
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupEmailTypoFix,
  /**
   * when the user is shown the error 'something went wrong' when they try to sign up with an email that already exists
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupErrorAccountDuplicate,
  /**
	 * when the modal pops up with 
	the error the user hasnt verified their email
	 *
	 * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
  signupErrorEmailVerification,
  /**
   * when the error modal is shown after a user uses an invalid verification link
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupErrorLinkInvalid,
  /**
   * when the error stating another patient from the pds trace already exists is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupErrorPatientDuplicate,
  /**
   * when the error stating another patient from the pds trace already exists is shown and the user creates the call to patient care from the popup
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupErrorPatientDuplicateCall,
  /**
   * when the error stating another patient from the pds trace already exists is shown and the user hits 'send us a message'
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupErrorPatientDuplicateIntercom,
  /**
   * when the error stating the users details couldnt be found on the nhs system is shown
   *
   * @param {SignupErrorPdsFail} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupErrorPdsFail,
  /**
   * when the user creates the call to patient care from the popup on the error page shown after failing their pds trace too many times
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupErrorPdsFailCall,
  /**
   * when the user opens intercom from the error page shown after failing their pds trace too many times
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupErrorPdsFailIntercom,
  /**
   * when the page asking the user to contact support is shown after 3 failed pds attempts
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupErrorPdsFailPageview,
  /**
   * when the user lands on the page for manually searching their gp because the pds trace didnt choose the right one
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupManualGpSearchPageview,
  /**
   * when a user in the signup flow confirms their gp where their gp is a N3P practice, and we work with their gp’s IM1 provider
   *
   * @param {SignupN3PConnectGpPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupN3PConnectGpPageview,
  /**
   * when a user in the signup flow confirms their gp where their gp is a N3P practice, clicks the ‘continue signing up’ button to connect manually to LD (via linkage keys), where we work with GP's IM1 provider
   *
   * @param {SignupN3PConnectManuallyContinue} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupN3PConnectManuallyContinue,
  /**
   * when a user in the signup flow confirms their gp where their gp is a N3P practice, clicks the ‘continue with nhs login’ button, where we work with GP's IM1 provider
   *
   * @param {SignupN3PNhsLoginContinue} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupN3PNhsLoginContinue,
  /**
   * when the user hits 'continue with nhs login' on the popup explaining nhs login accessed from the initial account info page
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupNhsLoginContinue,
  /**
   * when the user hits 'use your nhs login' on the initial account info page
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupNhsLoginInfo,
  /**
   * when the user hits 'learn more' on the popup explaining nhs login accessed from the initial account info page
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupNhsLoginLearnMore,
  /**
   * when the page loads after a user confirms a no third party gp as their practice
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupNoThirdPartyGpPageview,
  /**
   * when someone chooses yes nominate echo and hits continue
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupNominateEchoConfirm,
  /**
   * when the user lands on the page asking if theyd like to nominate echo
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupNominateEchoPageview,
  /**
   * when the page loads after a user confirms a non eps gp as their practice
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupNonEpsGpPageview,
  /**
   * Fires a 'signup_pageview' track call.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupPageview,
  /**
   * when a user in the signup flow is on the pds error screen and clicks the ‘call us’ button to contact PC to continue signing up
   *
   * @param {SignupPdsFailCall} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupPdsFailCall,
  /**
   * when a user in the signup flow reaches the screen to connect manually via linkage keys (after failing pds trace) and hits the ‘connect to gp surgery’ button
   *
   * @param {SignupPdsFailConnectGpConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupPdsFailConnectGpConfirm,
  /**
   * when a user in the signup flow reaches this screen to connect with linkage keys, after failing pds and choosing to connect manually
   *
   * @param {SignupPdsFailConnectGpPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupPdsFailConnectGpPageview,
  /**
   * when a user in the signup flow is on the pds error screen and clicks the ‘connect manually’ button to connect manually to LP (via linkage keys)
   *
   * @param {SignupPdsFailConnectManuallyContinue} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupPdsFailConnectManuallyContinue,
  /**
   * when a user in the signup flow is on the pds error screen and clicks the ‘continue with nhs login’ button
   *
   * @param {SignupPdsFailNhsLoginContinue} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupPdsFailNhsLoginContinue,
  /**
   * when a user in the signup flow reaches an error screen after failing their pds trace 3 times
   *
   * @param {SignupPdsFailPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupPdsFailPageview,
  /**
   * when a user in the signup flow is on the pds error screen and clicks the ‘send us a message' button to contact PC to continue signing up
   *
   * @param {SignupPdsFailSendMessage} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupPdsFailSendMessage,
  /**
   * when the user lands on the page to manually input their details for a pds trace
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupPdsManualTracePageview,
  /**
   * when the user lands on the page for adding their nhs number for a pds trace
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupPdsNhsNumberPageview,
  /**
   * when the user lands on the page asking whether they know their nhs number or whether they want to manually add their details
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupPdsOptionsPageview,
  /**
   * when the user has a successful pds trace via nhs number or manual trace
   *
   * @param {SignupPdsTraceSuccess} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupPdsTraceSuccess,
  /**
   * when the user lands on the page stating their phone number was verified and we need a few more details from them
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupPhoneConfirmedPageview,
  /**
   * when the user hits 'call me instead' on the page for phone verification
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupPhoneVerifyCall,
  /**
   * when the user hits the button to resend the verification email
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupResendEmail,
  /**
   * when the user hits the button to resend a phone verification text
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupResendPhone,
  /**
   * when the user hits 'change spam settings' on the email verification page showing a popup on how to debug
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupSpamEmailInfo,
  /**
   * when either signup via email or signup via nhs login is hit
   *
   * @param {SignupStarted} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupStarted,
  /**
   * when the user lands on the signup flow page after the gp confirmation page which states their gp is either not in england or is not in our database
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  signupUnsupportedGpPageview,
  /**
   * When a sponsored article is closed by the patient
   *
   * @param {SponsoredcontentDemand01Close} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredcontentDemand01Close,
  /**
   * Patient was shown  a list of sponsored content articles
   *
   * @param {SponsoredcontentDemand01ListMedicines} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredcontentDemand01ListMedicines,
  /**
   * When a patient dismisses sponsored content
   *
   * @param {SponsoredcontentDemand01NotInterested} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredcontentDemand01NotInterested,
  /**
   * Patient was shown the sponsored content call to action
   *
   * @param {SponsoredcontentDemand01Prompt} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredcontentDemand01Prompt,
  /**
   * Patient reads sponsored content, and how much they read
   *
   * @param {SponsoredcontentDemand01Read} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  sponsoredcontentDemand01Read,
  /**
   * Fires a 'terms_and_conditions_external_pageview' track call.
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  termsAndConditionsExternalPageview,
  /**
   * when a user hits 'track via royal mail' for their in flight order
   *
   * @param {TrackDelivery} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  trackDelivery,
  /**
   * when a user chooses to edit an existing delivery address
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  updateAddressPageview,
  /**
   * when the confirm button is hit on the update email page
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  updateEmailConfirm,
  /**
   * when the update email page is accessed from the account tab
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  updateEmailPageview,
  /**
   * when the page with the option to update a medicines supply is shown
   *
   * @param {UpdateNextDueDatePageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  updateNextDueDatePageview,
  /**
   * when the update phone page is accessed via the account tab
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  updatePhonePageview,
  /**
   * when the “confirm email” button is clicks on verify phone number flow
   *
   * @param {VerifyEmailConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  verifyEmailConfirm,
  /**
   * when a user see’s the screen sayinng an email has been verified
   *
   * @param {VerifyEmailConfirmationPageview} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  verifyEmailConfirmationPageview,
  /**
   * when the test modal is closed
   *
   * @param {VitaminsTestClosed} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  vitaminsTestClosed,
  /**
   * when confirming interest / no interest in experiment
   *
   * @param {VitaminsTestConfirm} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  vitaminsTestConfirm,
  /**
   * when the “find out more” button is clicked and modal is opened
   *
   * @param {VitaminsTestOpened} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  vitaminsTestOpened,
  /**
   * when the vitamins and supplements experiment is shown to a patient
   *
   * @param {VitaminsTestPrompt} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  vitaminsTestPrompt,
  /**
   * when survey is opened
   *
   * @param {VitaminsTestSurvey} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  vitaminsTestSurvey,
  /**
   * when the user clicks a "watch video" CTA on the marketing site
   *
   * @param {WatchVideo} props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  watchVideo,
  /**
   * when the whats new tab is hit and the page is shown
   *
   * @param {Record<string, any>} [props] - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 		call is fired.
   */
  whatsNewPageview
};

export default new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === "string" && target.hasOwnProperty(method)) {
      return target[method as keyof typeof clientAPI];
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(
        method
      )}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/echo-healthcare/protocols/tracking-plans/rs_1VALBMtFY875lXZ9G9sRE3TOl4w`);
      const a = analytics();
      if (a) {
        a.track(
          "Unknown Analytics Call Fired",
          {
            method
          },
          withTypewriterContext()
        );
      }
    };
  }
});
