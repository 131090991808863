export function formatPrice(cents: number) {
  return `£${(cents / 100).toFixed(2)}`;
}

export function formatMoney(cents: number) {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP"
  })
    .format(cents / 100)
    .replace(/(\.|,)00$/g, "");
}
