// AUTO-GENERATED - DO NOT EDIT!
import gql from "graphql-tag";
import {
  useQuery,
  useLazyQuery,
  QueryHookOptions,
  LazyQueryHookOptions
} from "@apollo/client";
import {
  getMigrationStatus,
  getMigrationStatusVariables
} from "../types/getMigrationStatus";

export const useGetMigrationStatusQuery = (
  opts: QueryHookOptions<getMigrationStatus, getMigrationStatusVariables>
) => {
  return useQuery<getMigrationStatus, getMigrationStatusVariables>(
    getMigrationStatusQuery,
    opts
  );
};

export const getMigrationStatusQuery = gql`
  query getMigrationStatus($patientId: ID!) {
    account {
      id

      migrationInfo {
        status
        experiment {
          name
          isEnabled
          events {
            name
            count
          }
        }
      }
    }
    migrationActivationDetails {
      link
      status
    }
    patient(id: $patientId) {
      id
      isNominated
      nominationStatus
      isNominatedWithP2U
      nominatedPharmacyCheckedAt {
        seconds
      }
      nominatedPharmacyUpdatedAt {
        seconds
      }
    }
  }
`;

export function refetchGetMigrationStatusQuery(
  variables: getMigrationStatusVariables
) {
  return {
    query: getMigrationStatusQuery,
    variables
  };
}

export const useLazyGetMigrationStatusQuery = (
  opts: LazyQueryHookOptions<getMigrationStatus, getMigrationStatusVariables>
) => {
  return useLazyQuery<getMigrationStatus, getMigrationStatusVariables>(
    getMigrationStatusQuery,
    opts
  );
};
