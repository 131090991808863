import React, { createContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useGetExperimentsQuery } from "../../graphql/hooks/useGetExperimentsQuery";
import { getExperiments_experiments as experimentType } from "../../graphql/types/getExperiments";
import { State } from "../../store/types";

// Type and create our context to give us a global state containing all
// experiment data and flags

type ExperimentsContextType = {
  experiments: experimentType[];
  reFetchExperiments: () => void;
};

type Props = {
  children?: React.ReactNode;
};

export const ExperimentsContext = createContext<ExperimentsContextType>({
  experiments: [],
  reFetchExperiments: () => {}
});

// This is our context provider which we will wrap around the entire app
export const ExperimentsProvider: React.FC<Props> = ({ children }) => {
  const isAuthenticated = useSelector((state: State) => {
    return state.auth.authenticated;
  });

  const { data, refetch } = useGetExperimentsQuery({
    skip: !isAuthenticated,
    fetchPolicy: "no-cache"
  });

  const [experiments, setExperiments] = useState<experimentType[]>([]);

  useEffect(() => {
    // Store data from our experiment service in our global state
    if (data && data.experiments) {
      setExperiments(data.experiments);
    }
  }, [data, experiments]);

  return (
    <ExperimentsContext.Provider
      value={{
        experiments: experiments,
        reFetchExperiments: refetch
      }}
    >
      {children}
    </ExperimentsContext.Provider>
  );
};
