import React, { useMemo } from "react";
import { useLocation } from "react-router";
import { matchPath, Link } from "react-router-dom";
import { routes } from "~/breadcrumbs";
import styled, { css } from "styled-components";
import Icon from "./Icon";
import { ClearIcon } from "@echo-health/icons-web";
import { Text } from "~/design-system/components/Text";
import { TextLink } from "~/design-system/components/TextLink";
import useSwitchPatient from "~/hooks/useSwitchPatient";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  background-color: ${p => p.theme.color.gray10};
`;

const Content = styled.main<{ $variant: string }>`
  ${({ theme, $variant }) => css`
    flex: auto;
    box-sizing: border-box;
    font-size: ${theme.typography.size.m};
    line-height: ${theme.typography.lineHeight.m};
    font-family: ${theme.typography.bodyFamily};
    width: ${$variant === "narrow"
      ? "100%"
      : `calc(100% - ${theme.spacing.m} - ${theme.spacing.m})`};
    max-width: ${$variant === "narrow" ? "560px" : theme.breakpoints.m};
    margin: 0 auto;
    padding: ${theme.spacing.m} 0 ${theme.spacing.xl};

    @media screen and (min-width: ${theme.breakpoints.s}) {
      padding-top: ${theme.spacing.l};
    }

    @media screen and (min-width: ${theme.breakpoints.m}) {
      padding-top: ${theme.spacing.xl};
    }
  `};
`;

const Nav = styled.nav`
  display: flex;
  align-items: flex-start;
  padding: ${p => p.theme.spacing.m};
  background-color: ${p => p.theme.color.white};
  box-shadow: ${p => p.theme.shadow.depth1};

  @media screen and (min-width: ${p => p.theme.breakpoints.m}) {
    padding: ${p => p.theme.spacing.m} ${p => p.theme.spacing.l};
    position: sticky;
    top: 0;
    z-index: 1;
  }
`;

const Breadcrumbs = styled.ul`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
`;

const Breadcrumb = styled.li`
  display: flex;
  align-items: center;

  &::after {
    padding: 0 ${p => p.theme.spacing.xs};
    color: ${p => p.theme.color.gray70};
    content: "/";
  }

  &:last-child::after {
    padding: 0;
    content: "";
  }
`;

const BreadcrumbLink = styled(TextLink)`
  color: ${p => p.theme.color.gray70};
  font-weight: ${p => p.theme.typography.weight.normal};
`;

const CloseButton = styled(Link)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.color.gray40};
  background: transparent;
  line-height: 1;
  text-decoration: none;
  transition: background-color 200ms ease-in-out, box-shadow 200ms ease-in-out,
    color 200ms ease-in-out;
  padding: ${p => p.theme.spacing.xs};
  margin: -${p => p.theme.spacing.xs};
  border-radius: 100%;

  &:hover,
  &:focus {
    color: ${p => p.theme.color.blue60};
  }

  &:hover {
    background: ${p => p.theme.color.blue10};
  }

  &:focus:not(:hover) {
    box-shadow: ${p => `${p.theme.shadow.focusRing} ${p.theme.color.blue20}`};
  }
`;

interface BreadcrumbLinkI {
  label: string;
  href: string;
}

/**
 * Given the current location and the apps routes,
 * this will return the appropriate breadcrumbs.
 */
const useBreadcrumbs = (): BreadcrumbLinkI[] => {
  const loc = useLocation();

  return useMemo(() => {
    const result: BreadcrumbLinkI[] = [];

    const pathParts = loc.pathname.split("/").filter(Boolean);

    for (let i = 0; i < pathParts.length; i++) {
      const subPath = `/${pathParts.slice(0, i + 1).join("/")}/`;

      for (let route of routes) {
        const m = matchPath(subPath, {
          path: route.path,
          exact: true
        });

        if (!m || !route.breadcrumb) {
          continue;
        }

        result.push({
          // TODO: this behaviour is a bit weird and un-documented but it basically
          // means that all earlier breadcrumbs will have the same query params.
          // This is quite a bad pattern since a later page can change the breadcrumbs of
          // pages early in the 'stack' so to speak. This is being implemented here to
          // preserver this behaviour since many of our flows rely on it.
          href: `${subPath}${loc.search}`,
          label: route.breadcrumb
        });
      }
    }

    return result;
  }, [loc]);
};

type Props = {
  children: React.ReactNode;
  closeRouterLink?: string;
  variant?: string;
};

const SecondaryLayout = ({ variant = "default", ...props }: Props) => {
  const breadcrumbs = useBreadcrumbs();

  const switchPatient = useSwitchPatient();

  return (
    <Wrapper>
      <Nav>
        <Breadcrumbs>
          {breadcrumbs.map((breadcrumb, i) => {
            const isLast = i === breadcrumbs.length - 1;

            if (isLast) {
              return (
                <Breadcrumb key={breadcrumb.href}>
                  <Text isInline>{breadcrumb.label}</Text>
                </Breadcrumb>
              );
            }

            return (
              <Breadcrumb key={breadcrumb.href}>
                <BreadcrumbLink
                  linkTo={breadcrumb.href}
                  label={breadcrumb.label}
                />
              </Breadcrumb>
            );
          })}
        </Breadcrumbs>

        <CloseButton
          to={props.closeRouterLink ?? switchPatient()}
          aria-label="Close"
        >
          <Icon type={ClearIcon} size="m" color="currentColor" />
        </CloseButton>
      </Nav>

      <Content $variant={variant}>{props.children}</Content>
    </Wrapper>
  );
};

export const withSecondaryLayout = (
  Component: any,
  variant: "default" | "narrow" = "default",
  closeRouterLink: any = undefined
) => {
  return ({ ...props }) => {
    return (
      <SecondaryLayout closeRouterLink={closeRouterLink}>
        <Component {...props} />
      </SecondaryLayout>
    );
  };
};
