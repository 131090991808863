// AUTO-GENERATED - DO NOT EDIT!
import gql from "graphql-tag";
import {
  useQuery,
  useLazyQuery,
  QueryHookOptions,
  LazyQueryHookOptions
} from "@apollo/client";
import { getExperiments } from "../types/getExperiments";

export const useGetExperimentsQuery = (
  opts: QueryHookOptions<getExperiments, {}>
) => {
  return useQuery<getExperiments, {}>(getExperimentsQuery, opts);
};

export const getExperimentsQuery = gql`
  query getExperiments {
    experiments {
      name
      isEnabled
    }
  }
`;

export function refetchGetExperimentsQuery() {
  return {
    query: getExperimentsQuery
  };
}

export const useLazyGetExperimentsQuery = (
  opts: LazyQueryHookOptions<getExperiments, {}>
) => {
  return useLazyQuery<getExperiments, {}>(getExperimentsQuery, opts);
};
