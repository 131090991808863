import { createStandardAction } from "typesafe-actions";
import { Reducer } from "redux";
import { ActionsUnion } from "./types";

export interface SetDetailsPayload {
  readonly phone?: string;
}

export interface SignUpState {
  phone: string;

  [propName: string]: string;
}

export const signUpInitialState: SignUpState = {
  phone: ""
};

export const signUpActions = {
  setSignUpDetails: createStandardAction(
    "SIGN_UP/SET_DETAILS"
  )<SetDetailsPayload>(),
  clearSignUpDetails: createStandardAction("SIGN_UP/CLEAR")()
};

export const signUpReducer: Reducer<SignUpState, ActionsUnion> = (
  state = signUpInitialState,
  action
): SignUpState => {
  switch (action.type) {
    case "SIGN_UP/CLEAR": {
      return {
        ...signUpInitialState
      };
    }
    case "SIGN_UP/SET_DETAILS": {
      return {
        ...state,
        ...action.payload
      };
    }
  }
  return state;
};
