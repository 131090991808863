import * as React from "react";
import styled, { css } from "styled-components";
import { Text } from "../design-system/components/Text";
import AnchorLink from "./AnchorLink";
import { Stack } from "./Stack";
import Icon from "./Icon";
import NHS from "../icons/NHS";
import { BcorpBlackIcon } from "@echo-health/icons-web";
import CookiePreferencesButton from "~/components/CookiePreferencesButton";

const Wrapper = styled.footer`
  grid-area: footer;
  display: grid;
  grid-template-columns: max-content;
  margin-right: ${p => p.theme.spacing.s};

  /* Approximate footer toolbar height so that the footer isn't obscured by bottom sticky toolbars */
  padding-bottom: calc(80px + env(safe-area-inset-bottom));
  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.s}) {
      padding-bottom: 0;
    }
  `}
`;

const NHSLogo = styled.span`
  grid-column: 3;
  display: flex;
  flex-direction: row-reverse;
`;

const BCorpLogo = styled.span`
  grid-column: 1;
`;

const Links = styled.div`
  grid-column-end: span 3;
  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.m}) {
      grid-column-end: auto;
      grid-column: 2;
      grid-row: 1;
    }
  `}
`;

const LinksWrapper = styled.ul`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    list-style-type: none;

    li {
      padding: 0 ${theme.spacing.xxs};
      border-left: 1px solid ${theme.color.gray10};

      @media screen and (min-width: ${theme.breakpoints.m}) {
        padding: 0 ${theme.spacing.xs};
      }

      &:first-child {
        border-left: none;
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  `}
`;

const LinkStack = styled(Stack)`
  align-items: center;
  width: 100%;
  text-align: center;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.s}) {
      align-items: flex-start;
      width: auto;
      text-align: left;
    }
  `}
`;

export const PrimaryFooter: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Wrapper>
      <Links>
        <LinkStack alignment="leading" vertical={true} margin="0 0 0 s">
          <LinksWrapper>
            <li>
              <AnchorLink size="s" href="https://lloydsdirect.co.uk/contact-us">
                Help
              </AnchorLink>
            </li>
            <li>
              <AnchorLink
                size="s"
                href="https://lloydsdirect.co.uk/p/terms-and-conditions"
              >
                Terms &amp; conditions
              </AnchorLink>
            </li>
            <li>
              <AnchorLink
                size="s"
                href="https://lloydsdirect.co.uk/p/privacy-policy"
              >
                Privacy policy
              </AnchorLink>
            </li>
            <li>
              <CookiePreferencesButton>Cookie settings</CookiePreferencesButton>
            </li>
          </LinksWrapper>
          <Text nonSensitive size="xs" margin="xxs 0 0 0" color="gray70">
            © {currentYear} Metabolic Healthcare Ltd
          </Text>
        </LinkStack>
      </Links>
      <AnchorLink href="https://www.bcorporation.net/en-us/find-a-b-corp/company/lloydsdirect">
        <BCorpLogo role="img" aria-label="B-Corp Logo">
          <Icon type={BcorpBlackIcon} size="xl" />
        </BCorpLogo>
      </AnchorLink>
      <NHSLogo>
        <Icon type={NHS} size="xl" />
      </NHSLogo>
    </Wrapper>
  );
};
