import {
  colors,
  space,
  sizes,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  borders,
  radii,
  shadows,
  breakpoints,
  mediaQueries
} from "@echo-health/tokens";

const Theme = {
  colors,
  space,
  sizes,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  borders,
  radii,
  shadows,
  mediaQueries,
  /**
   * @deprecated Use theme.mediaQueries instead
   */
  breakpoints: {
    l: "1024px",
    m: "800px",
    s: "425px",
    map: (fn: any) => {
      return breakpoints.map(fn);
    }
  },
  spacing: {
    xxs: "4px",
    xs: "8px",
    s: "12px",
    m: "16px",
    l: "24px",
    xl: "32px",
    xxl: "40px",
    xxxl: "56px"
  },
  typography: {
    displayFamily: "Cahuenga, sans-serif",
    bodyFamily: "IBM Plex Sans, sans-serif",
    bodySize: fontSizes.m,
    bodyLineHeight: lineHeights.m,
    bodyColor: colors.text.default,
    /**
     * @deprecated Use theme.fontWeights instead
     */
    weight: {
      extrabold: "600",
      bold: "500",
      normal: "400"
    },
    /**
     * @deprecated Use theme.fontSizes instead
     */
    size: {
      xs: "0.75rem",
      s: "0.875rem",
      m: "1rem",
      l: "1.25rem",
      xl: "1.5rem",
      xxl: "2rem"
    },
    /**
     * @deprecated Use theme.lineHeights instead
     */
    lineHeight: {
      xs: "1.5",
      s: "1.42",
      m: "1.5",
      l: "1.4",
      xl: "1.5",
      xxl: "1.5"
    }
  },
  color: {
    ...colors,
    // New brand colors
    sea: "#A5E0F1"
  },
  nhslogincolor: {
    blue: "#005EB8",
    highlight: "#0973dc"
  },
  misc: {
    borderRadius: "4px"
  },
  shadow: {
    depth1: "0px 0px 1px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.08)",
    depth2: "0px 1px 4px rgba(0, 0, 0, 0.16), 0px 2px 16px rgba(0, 0, 0, 0.08)",
    depth3: "0px 1px 4px rgba(0, 0, 0, 0.16), 0px 8px 40px rgba(0, 0, 0, 0.16)",
    focusRing: "0 0 0 4px"
  }
};

export default Theme;
