import * as React from "react";
import styled, { css } from "styled-components";
import { Spacing } from "~/styled";
import withMargin, { WithMarginProp } from "../styled/withMargin";

type Alignment = "leading" | "center" | "trailing" | "stretch";
type Distribution =
  | "leading"
  | "center"
  | "trailing"
  | "even"
  | "between"
  | "stretch";

export type Props = WithMarginProp & {
  vertical?: boolean;
  distribution?: Distribution;
  alignment?: Alignment;
  spaceBetween?: Spacing;
  width?: string;
  height?: string;
  as?: keyof JSX.IntrinsicElements;
  children?: React.ReactNode;
};

const alignmentMap: { [key: string]: string } = {
  leading: "flex-start",
  center: "center",
  trailing: "flex-end",
  stretch: "stretch"
};

const distributionMap: { [key: string]: string } = {
  leading: "flex-start",
  center: "center",
  trailing: "flex-end",
  even: "space-evenly",
  between: "space-between",
  stretch: "stretch"
};

const StackWrapper = styled("div")<Props>`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? "column" : "row")};
  justify-content: ${({ distribution = "center" }) => {
    return distributionMap[distribution];
  }};
  align-items: ${({ alignment = "center" }) => alignmentMap[alignment]};
  height: ${({ height }) => (height ? "100%" : "auto")};
  ${withMargin}
`;

const StackItem = styled("div")<Props>`
  ${({ spaceBetween, distribution, vertical, theme, width }) => {
    if (!spaceBetween) {
      return "";
    }

    const noMargin = distribution === "trailing" ? "first-child" : "last-child";

    let marginProp =
      distribution === "trailing" ? "margin-left" : "margin-right";
    if (vertical) {
      marginProp = distribution === "trailing" ? "margin-top" : "margin-bottom";
    }

    return css`
      ${marginProp}: ${theme.spacing[spaceBetween]};

      :${noMargin} {
        ${marginProp}: 0;
      }
      width: ${width ? "100%" : "auto"};
    `;
  }}
`;

export const Stack: React.FunctionComponent<Props> = props => {
  return (
    <StackWrapper {...props}>
      {React.Children.map(props.children, child => {
        // we don't want to render a stack item with associated margins etc. if the child is null/falsy
        if (child) {
          return <StackItem {...props}>{child}</StackItem>;
        }

        return child;
      })}
    </StackWrapper>
  );
};
