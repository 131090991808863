import React from "react";
import loadable from "@loadable/component";
import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { withSecondaryLayout } from "~/components/SecondaryLayout";
import { withPrimaryLayout } from "~/components/PrimaryLayout";
const routes: RouteProps[] = [
  {
    path: "account/details/confirm-email",
    component: loadable(
      () => import("~/pages/Account/VerifyEmail/VerifyToken")
    ),
    exact: true
  },
  {
    path: "account/connections",
    component: loadable(() => import("~/pages/Account/Connections")),
    exact: true
  },
  {
    path: "account",
    component: loadable(() => import("~/pages/Account/AccountRouter")),
    exact: false
  },
  {
    path: "cancel-requests/:patientId",
    component: loadable(
      () => import("~/pages/CancelOrders/CancelOrdersRouter")
    ),
    exact: false
  },
  {
    path: "set-delivery-preference/:patientId",
    component: loadable(
      () => import("~/pages/SetDeliveryPreference/DeliveryPreferenceRouter")
    ),
    exact: false
  },
  {
    path: "patient/:patientId/connect-gp",
    component: loadable(() => import("~/pages/Account/ConnectGP")),
    exact: true
  },
  {
    path: "patient/:patientId/order/:orderId",
    component: loadable(() => import("~/pages/Medication/RequestDetails")),
    exact: true
  },
  {
    path: "patient/:patientId/confirm-prescription",
    component: loadable(() => import("~/pages/AskPatient/AskPatientRouter")),
    exact: false
  },
  {
    path: "patient/:patientId/medication",
    component: loadable(() => import("../pages/Medication/MedicationRouter")),
    exact: false
  },
  {
    path: "patient/:patientId/requests",
    component: loadable(() => import("../components/pages/RequestsRouter")),
    exact: false
  },
  {
    path: "patient/:patientId/arrange-delivery",
    component: loadable(
      () => import("~/pages/Partners/ArrangeDelivery/ArrangeDeliveryRouter")
    ),
    exact: false
  },
  {
    path: "patient/:patientId/pill-review/:patientMedicationId",
    component: loadable(() => import("../pages/Consultations/PCS/PcsRouter")),
    exact: false
  },
  {
    path: "connect",
    component: loadable(() => import("~/pages/Partners/Connect/ConnectRouter")),
    exact: false
  },
  {
    path: "v2/sign-up/confirm-email-redirect",
    component: loadable(() => import("../pages/SignUp/VerifyEmailRedirect"))
  },
  {
    path: "sign-up/confirm-email-redirect",
    component: loadable(() => import("../pages/SignUp/VerifyEmailRedirect"))
  },
  {
    path: "verify-email",
    component: loadable(() => import("../pages/SignUp/VerifyEmailRedirect"))
  },
  {
    path: "sign-up",
    component: loadable(() => import("../pages/SignUp/SignUpRouter")),
    exact: false
  },
  {
    path: "login",
    component: loadable(() => import("../pages/Anonymous/Login")),
    exact: true
  },
  {
    path: "reset-password",
    component: loadable(() => import("../components/pages/ResetPasswordPage")),
    exact: true
  },
  {
    path: "forgot-password",
    component: loadable(() => import("../components/pages/ForgotPasswordPage")),
    exact: true
  },
  {
    path: "account-deleted",
    component: loadable(() => import("../pages/Account/DeleteAccount")),
    exact: true
  },
  {
    path: "marketing/unsubscribe",
    component: loadable(() => import("../pages/Anonymous/UnsubscribeFromMapp")),
    exact: true
  },
  {
    path: "payment-reminder",
    component: loadable(
      () => import("../pages/PaymentReminder/PaymentReminderConfirmation")
    ),
    exact: true
  },
  {
    path: "auth/token",
    component: loadable(() => import("../pages/Anonymous/TokenAuth")),
    exact: true
  },
  {
    path: "auth/nhs-login",
    component: loadable(() => import("../pages/NHSLogin/NHSLoginAuth")),
    exact: true
  },
  {
    path: "nhs-login/confirm",
    component: loadable(
      () => import("../pages/NHSLogin/NHSLoginExistingAccount")
    ),
    exact: true
  },
  {
    path: "nhs-login/create",
    component: loadable(
      () => import("../pages/NHSLogin/NHSLoginCreateAccount")
    ),
    exact: true
  },
  {
    path: "universal",
    component: () => <Redirect to="/login" />,
    exact: true
  },
  {
    path: "whats-new",
    component: loadable(() => import("../pages/WhatsNew/WhatsNewRouter")),
    exact: false
  },
  {
    path: "whats-a-batch",
    component: loadable(() => import("../pages/RDS/BatchPrescriptionInfo"))
  },
  {
    path: "return-prescriptions",
    component: withPrimaryLayout(
      loadable(() => import("~/pages/ReturnPrescriptions/PatientPicker"))
    ),
    exact: true
  },
  {
    path: "help/:patientId/return-prescriptions",
    component: withSecondaryLayout(
      loadable(
        () => import("~/pages/ReturnPrescriptions/ReturnPrescriptionsRouter")
      )
    ),
    exact: false
  },
  {
    path: "help",
    component: loadable(() => import("../pages/SelfHelp/HelpRouter")),
    exact: false
  },

  {
    path: "(home)*",
    component: loadable(() => import("../pages/Anonymous/Root")),
    exact: true
  },
  {
    path: "*",
    component: loadable(() => import("../components/pages/NotFoundPage"))
  }
];
export const Router = () => {
  return (
    <Switch>
      <Redirect
        from="/patient/:patientId"
        to="/patient/:patientId/medication"
        exact
      />
      {/*This redirect handles URLs we use in the email renderer to get us to select the right patient*/}
      <Redirect
        from="/return-prescriptions/:patientId"
        to="/help/:patientId/return-prescriptions"
        exact
      />
      {routes.map((route, i) => (
        <Route
          key={`app_root_${i}`}
          path={`/${route.path}`}
          component={route.component}
          exact={route.exact}
        />
      ))}
    </Switch>
  );
};
