import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();
  const { action } = useHistory();

  useEffect(() => {
    if (action === "PUSH") {
      window.scrollTo(0, 0);
    }
  }, [pathname, action]);

  return null;
}

export default ScrollToTop;
