/**
 * This is the entry file for the bundle we send to the browser.
 *
 */
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, matchPath } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import {
  ApolloClient,
  ApolloProvider,
  ApolloLink,
  createHttpLink
} from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { onError } from "@apollo/client/link/error";
import App from "./layout/App";

import config from "./config";
import { isAuthError, isExpectedError } from "./utils/auth";
import * as analytics from "./analytics";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import { getGraphQLError } from "./utils/graphql";
import { createPersistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { authActions } from "./store/auth";

// Start up Sentry
// This createBrowserHistory stuff can be removed once we migrate to React Router 6:
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#parameterized-transaction-names

const history = createBrowserHistory();
const routes = [
  { path: "/patient/:patientid" },
  { path: "/account" },
  { path: "/account/patient/:patientid" }
];

Sentry.init({
  dsn: config.SENTRY_DSN,
  allowUrls: [
    "https://storage.googleapis.com/echo-webapp/",
    "https://web.staging.echo.co.uk/",
    "https://web.echo.co.uk/",
    "https://web.staging.lloydsdirect.co.uk/",
    "https://web.lloydsdirect.co.uk/"
  ],
  release: config.APP_VERSION,
  environment: config.SENTRY_ENVIRONMENT || "development",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(
        history,
        routes,
        matchPath
      )
    })
  ],
  normalizeDepth: 10,
  tracesSampleRate: 0,
  ignoreErrors: [
    "Non-Error promise rejection captured", // https://github.com/getsentry/sentry-javascript/issues/3440
    "Load failed",
    "Failed to fetch",
    "Not authenticated",
    "Authentication session has expired",
    "Invalid postcode or latitude/longitude provided",
    "Failed to load Stripe.js"
  ]
});

const httpLink = createHttpLink({
  uri: config.CLIENT_API_URL,
  credentials: "include",
  headers: {
    "X-Client-ID": "web",
    "X-Client-Version": config.APP_VERSION
  },
  fetch: async (input: RequestInfo | URL, init?: any) => {
    // types are a bit weird for init so typing as any and being defensive
    if (init && init.headers && typeof init.headers === "object") {
      init.headers["X-Device-ID"] = analytics.deviceId();
    }
    return fetch(input, init);
  }
});

const errorLink = onError(({ operation, graphQLErrors }) => {
  const { location } = window;

  if (isAuthError(graphQLErrors) && location.pathname !== "/login") {
    store.dispatch(authActions.clearAuthentication());
  }

  // We only want to log the unexpected, most errors from GraphQL with a code
  // specified will be handled by the frontend, by the user or otherwise. We can
  // safely ignore these are they are a normal part of app usage, and not
  // something that'd indicate Bad Things Happening
  if (!isExpectedError(graphQLErrors) && graphQLErrors) {
    const response = operation.getContext().response as Response | null;
    let traceId: string | null = null;
    if (response) {
      traceId = response.headers.get("X-Trace-Id");
    }
    graphQLErrors.forEach(error => {
      Sentry.withScope(scope => {
        scope.setExtras(operation.variables);
        scope.setTag("operation", operation.operationName);
        scope.setTag("ErrorScope", "GraphQL");
        scope.setTag("trace_id", traceId);
        scope.setTransactionName(operation.operationName);
        Sentry.captureException(getGraphQLError(error));
      });
    });
  }
});

// Ordering is important here, errorLink needs to be first.
const apolloLink = ApolloLink.from([errorLink, httpLink]);

export const apolloClient = new ApolloClient({
  link: apolloLink,
  cache: new InMemoryCache().restore((window as any).__APOLLO_STATE__),
  ...(config.DISABLE_APOLLO_CACHE === "true"
    ? {
        defaultOptions: {
          watchQuery: {
            fetchPolicy: "no-cache"
          },
          query: {
            fetchPolicy: "no-cache"
          }
        }
      }
    : {})
});

// Set the store in the analytics module to be able to access the intercomId
analytics.setStore(store);

const tree = (
  <>
    <HelmetProvider>
      <BrowserRouter>
        <CompatRouter>
          <Provider store={store}>
            <PersistGate loading={null} persistor={createPersistor(store)}>
              <ApolloProvider client={apolloClient}>
                <App />
              </ApolloProvider>
            </PersistGate>
          </Provider>
        </CompatRouter>
      </BrowserRouter>
    </HelmetProvider>
  </>
);

const container = document.getElementById("app");
const root = createRoot(container!);
root.render(tree);
