// AUTO-GENERATED - DO NOT EDIT!
import gql from "graphql-tag";
import {
  useQuery,
  useLazyQuery,
  QueryHookOptions,
  LazyQueryHookOptions
} from "@apollo/client";
import { getFeatures } from "../types/getFeatures";

export const useGetFeaturesQuery = (
  opts: QueryHookOptions<getFeatures, {}>
) => {
  return useQuery<getFeatures, {}>(getFeaturesQuery, opts);
};

export const getFeaturesQuery = gql`
  query getFeatures {
    features {
      name
      isEnabled
    }
  }
`;

export function refetchGetFeaturesQuery() {
  return {
    query: getFeaturesQuery
  };
}

export const useLazyGetFeaturesQuery = (
  opts: LazyQueryHookOptions<getFeatures, {}>
) => {
  return useLazyQuery<getFeatures, {}>(getFeaturesQuery, opts);
};
