import React from "react";
import ReactModal from "react-modal";
import styled, { css } from "styled-components";

interface Props extends ReactModal.Props {
  className?: string;
  children?: React.ReactNode;
}

// CSS classes for overriding the react-modal classes referenced here: https://github.com/reactjs/react-modal/issues/603#issuecomment-433210653
const ReactModalAdapter = ({ className, ...modalProps }: Props) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;

  return (
    <ReactModal
      closeTimeoutMS={500}
      portalClassName={className}
      overlayClassName={overlayClassName}
      className={contentClassName}
      {...modalProps}
    />
  );
};

const StyledReactModal = styled(ReactModalAdapter)`
  ${({ theme }) => {
    return css`
      &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 40;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(1, 50, 63, 0.8);
        animation-name: overlayFadescale;
        animation-duration: 300ms;
        animation-iteration-count: 1;
        animation-timing-function: ease-out;
        @keyframes overlayFadescale {
          0% {
            opacity: 0;
          }
          80% {
            opacity: 1;
          }
        }
        /* required transition for fade out */
        transition: opacity 500ms ease-in-out;
        &.ReactModal__Overlay--before-close {
          opacity: 0;
        }
      }
      &__content {
        @media screen and (min-width: ${theme.breakpoints.m}) {
          max-width: 452px;
          margin: 0;
        }
        width: 100%;
        margin: 0 1em;
        background: ${theme.color.white};
        z-index: 41;
        justify-content: center;
        box-sizing: border-box;
        overflow: hidden;
        outline: none;
        padding: ${theme.spacing.m};
        @media screen and (min-width: ${theme.breakpoints.m}) {
          padding: ${theme.spacing.xl};
        }

        border-radius: 5px;
        box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.05);
        animation-name: contentFadescale;
        animation-duration: 500ms;
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(1, 1, 2, 1.5);
        @keyframes contentFadescale {
          0% {
            transform: scale(0.8);
            opacity: 0;
          }
          40% {
            opacity: 1;
          }
          50% {
            transform: scale(1.1);
          }
          80% {
            opacity: 1;
          }
          100% {
            opacity: 1;
            transform: scale(1);
          }
        }
        /* required transition for fade out */
        transition: opacity 500ms ease-in-out;
        &.ReactModal__Content--before-close {
          opacity: 0;
          animation-name: closeFadescale;
          animation-duration: 500ms;
          animation-iteration-count: 1;
          animation-timing-function: cubic-bezier(1, 1, 1.5, 1.5);
          @keyframes closeFadescale {
            0% {
              opacity: 1;
            }
            80% {
              transform: scale(0.8);
              opacity: 0;
            }
            100% {
              transform: scale(0.8);
              opacity: 0;
            }
          }
        }
      }
    `;
  }}
`;

interface ModalProps {
  isOpen: boolean;
  onAfterOpen?: () => void;
  onRequestClose: () => void;
  children: React.ReactNode;
}

export const DeprecatedModal = ({
  children,
  className,
  ...props
}: ModalProps & ReactModal.Props) => {
  let appElement;

  appElement = document.querySelector("#app") as HTMLElement;

  return (
    <StyledReactModal
      shouldCloseOnOverlayClick={true}
      className=""
      appElement={appElement}
      {...props}
    >
      {children}
    </StyledReactModal>
  );
};

export default DeprecatedModal;
