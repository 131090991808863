import React, { useState } from "react";
import { NavLink, useRouteMatch, Link } from "react-router-dom";
import styled, { css } from "styled-components";
import theme from "~/styled/theme";
import { Text } from "../design-system/components/Text";
import { timestampSeconds, daysAgo } from "../utils/date";
import { useGetPatientTabsQuery } from "../graphql/hooks/useGetPatientTabsQuery";
import { useLogoutMutation } from "../graphql/hooks/useLogoutMutation";
import { trackEvent } from "../analytics";
import DeprecatedErrorModal from "./DeprecatedErrorModal";
import Divider from "./Divider";
import Icon from "./Icon";
import {
  HeadsetMicIcon,
  AccountCircleIcon,
  ExitToAppIcon,
  ClearIcon,
  CampaignIcon,
  CapsuleIcon
} from "@echo-health/icons-web";
import { Button } from "../design-system/components/Button";
import { ActionNeededType } from "../graphql/types/global";
import { PatientListSwitcher } from "./PatientListSwitcher";
import useSwitchPatient from "~/hooks/useSwitchPatient";
import { authActions } from "~/store/auth";
import { store } from "~/store";
import { Logo } from "@echo-health/design-system";

export const DESKTOP_NAVIGATION_WIDTH = "240px";

const NavigationLayout = styled.nav<{ isMobileNavExpanded: boolean }>`
  width: 100%;
  min-height: ${p => (p.isMobileNavExpanded ? "100vh" : "auto")};
  background: ${p => p.theme.color.white};
  box-shadow: ${p => p.theme.shadow.depth1};
  overflow: auto;

  @media screen and (min-width: ${theme.breakpoints.m}) {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    width: ${DESKTOP_NAVIGATION_WIDTH};
  }
`;

const NavigationBar = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: ${p => p.theme.spacing.xs};
  padding: ${p => p.theme.spacing.m};
  align-items: center;

  @media screen and (min-width: ${theme.breakpoints.m}) {
    grid-template-columns: 1fr;
  }
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;

  @media screen and (min-width: ${theme.breakpoints.m}) {
    margin: ${p => p.theme.spacing.m} 0;
  }
`;

const MenuToggleButton = styled(Button)`
  @media screen and (min-width: ${theme.breakpoints.m}) {
    display: none;
  }
`;

const NavigationContent = styled.div<{ isMobileNavExpanded: boolean }>`
  display: ${p => (p.isMobileNavExpanded ? "grid" : "none")};
  grid-template-columns: 1fr;
  grid-gap: ${p => p.theme.spacing.xs};
  padding: ${p => p.theme.spacing.m};
  padding-top: 0;

  @media screen and (min-width: ${theme.breakpoints.m}) {
    display: grid;
    padding: ${p => `${p.theme.spacing.xl} ${p.theme.spacing.m}`};
    padding-top: 0;
  }
`;

const navigationButtonBaseStyle = css`
  display: grid;
  grid-template-columns: 24px 1fr max-content;
  grid-gap: ${p => p.theme.spacing.xs};
  align-items: center;
  text-decoration: none;
  padding: ${p => p.theme.spacing.m};
  margin-left: ${p => `-${p.theme.spacing.m}`};
  margin-right: ${p => `-${p.theme.spacing.m}`};
  transition: background-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
  outline: none;

  &:hover {
    background-color: ${p => p.theme.color.gray10};
  }

  &:focus:not(:hover) {
    background-color: ${p => p.theme.color.blue10};
    box-shadow: ${p => `${p.theme.shadow.focusRing} ${p.theme.color.blue20}`};
  }

  @media screen and (min-width: ${theme.breakpoints.m}) {
    padding: ${p => p.theme.spacing.xs};
    margin-left: ${p => `-${p.theme.spacing.xs}`};
    margin-right: ${p => `-${p.theme.spacing.xs}`};
    border-radius: ${p => p.theme.spacing.xl};
  }
`;

const navigationLinkBaseStyles = css`
  /* Icon */
  & :nth-child(1) {
    color: ${p => p.theme.color.gray40};
  }

  /* Text */
  & :nth-child(2) {
    color: ${p => p.theme.color.gray70};
  }

  /* Active state */
  &.active {
    background-color: ${p => p.theme.color.blue10};
    & :nth-child(1) {
      color: ${p => p.theme.color.blue60};
    }
    & :nth-child(2) {
      color: ${p => p.theme.color.blue70};
      font-weight: ${p => p.theme.typography.weight.bold};
    }
  }
`;

const NavigationButton = styled.button`
  ${navigationButtonBaseStyle};
  ${navigationLinkBaseStyles};
`;

const NavigationLink = styled(NavLink)`
  ${navigationButtonBaseStyle};
  ${navigationLinkBaseStyles};
`;

const ItemText = styled(Text).attrs({ nonSensitive: true })`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export function PrimaryNav() {
  const { params } = useRouteMatch<{ patientId: string }>();
  const patientId = params.patientId;

  const [isMobileNavExpanded, setIsMobileNavExpanded] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const { data, loading, error } = useGetPatientTabsQuery({
    variables: { updateTimeAfter: timestampSeconds(daysAgo(7)) }
  });

  const switchPatient = useSwitchPatient(true);

  const [logout, { error: logoutError }] = useLogoutMutation({
    onError: () => {
      return setIsErrorModalOpen(true);
    },
    onCompleted: response => {
      if (!response) return;
      trackEvent("nav_logout", {
        patientid: patientId
      });
      if (response.logout.errors.length > 0) {
        setIsErrorModalOpen(true);
        return;
      } else {
        store.dispatch(authActions.clearAuthentication());
      }
    }
  });

  if (loading || error || !data || !data.account) {
    return null;
  }

  const { patients } = data.account;

  const accountHolder = patients.edges.find(i => i.node.isAccountHolder);

  const selectedPatient =
    patients.edges.find(i => i.node.id === patientId) || accountHolder;

  function onNavigate() {
    // Close the mobile nav and patient picker
    setIsMobileNavExpanded(false);
  }

  const isAccountIncomplete = data.account.actionNeeded.actions.some(
    action => action.id === ActionNeededType.ACTION_NEEDED_ACCOUNT_INCOMPLETE
  );

  const hasAccountHolderTraceFailed =
    accountHolder &&
    accountHolder.node.actionNeeded.actions.some(action => {
      return action.id === ActionNeededType.ACTION_NEEDED_UPDATE_DEMOGRAPHICS;
    });

  return (
    <>
      <NavigationLayout isMobileNavExpanded={isMobileNavExpanded}>
        <NavigationBar>
          <LogoLink to={switchPatient()} aria-label={"Home"}>
            <Logo />
          </LogoLink>
          <MenuToggleButton
            label={isMobileNavExpanded ? "Close" : "Menu"}
            iconLeading={isMobileNavExpanded ? ClearIcon : undefined}
            variant="secondary"
            size="s"
            onClick={() => {
              setIsMobileNavExpanded(!isMobileNavExpanded);
            }}
          />
        </NavigationBar>

        <NavigationContent isMobileNavExpanded={isMobileNavExpanded}>
          <Divider margin="0 0 xs 0" />
          {selectedPatient &&
          !isAccountIncomplete &&
          !hasAccountHolderTraceFailed ? (
            <>
              <PatientListSwitcher />
              <Divider margin="xs 0" />
            </>
          ) : null}

          {selectedPatient ? (
            <>
              <NavigationLink
                to={`/patient/${selectedPatient.node.id}/medication`}
                onClick={() => {
                  onNavigate();
                }}
              >
                <Icon type={CapsuleIcon} />
                <ItemText>Medicine</ItemText>
              </NavigationLink>
            </>
          ) : null}

          <NavigationLink
            to={selectedPatient ? `/help/${selectedPatient.node.id}` : "/help"}
            onClick={() => {
              onNavigate();
            }}
          >
            <Icon type={HeadsetMicIcon} />
            <ItemText>Help</ItemText>
          </NavigationLink>

          <NavigationLink
            to="/account"
            onClick={() => {
              onNavigate();
            }}
          >
            <Icon type={AccountCircleIcon} />
            <ItemText>Account</ItemText>
          </NavigationLink>

          <Divider margin="xs 0" />

          <NavigationLink
            to="/whats-new"
            onClick={() => {
              onNavigate();
            }}
          >
            <Icon type={CampaignIcon} />
            <ItemText>What’s new</ItemText>
          </NavigationLink>

          <NavigationButton
            onClick={() => {
              logout();
            }}
          >
            <Icon type={ExitToAppIcon} />
            <ItemText>Log out</ItemText>
          </NavigationButton>
        </NavigationContent>
      </NavigationLayout>
      <DeprecatedErrorModal
        isOpen={isErrorModalOpen}
        error={logoutError}
        onDismiss={() => setIsErrorModalOpen(false)}
      />
    </>
  );
}
