import React, { createContext, useContext } from "react";
import { Modal } from "../../design-system/components/Modal";
import { Text } from "../../design-system/components/Text";
import { DisplayHeading } from "../../design-system/components/DisplayHeading";
import { Button } from "../../design-system/components/Button";
import { ApolloError } from "@apollo/client";
import { DeprecatedButtonGroup } from "../DeprecatedButton";
import styled, { css } from "styled-components";
import { Illustration } from "../../design-system/components/Illustration";
import { isEnabled as isAnalyticsEnabled, trackEvent } from "../../analytics";
import { getErrorMessage } from "~/utils/error";

const StyledButton = styled(Button)<{ $isCentered: boolean }>`
  width: fill;

  @media screen and (min-width: ${p => p.theme.breakpoints.m}) {
    width: min-content;

    ${p =>
      p.$isCentered &&
      css`
        margin: 0 ${p.theme.spacing.xs};
      `};
  }
`;

const IllustrationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${p => p.theme.spacing.m};
`;

type ErrorVariant = "generic" | "internet_connection" | "payment_failed";

interface ModalOptions {
  error?: Error | ApolloError | string;
  variant?: ErrorVariant;
  onRetry?: () => void;
}

export interface ErrorModalContextProps {
  handleError: (options?: ModalOptions) => void;
}

const ErrorModalContext = createContext<ErrorModalContextProps>({
  handleError: () => ""
});

interface ErrorModalProviderProps {
  children: React.ReactNode;
}

interface ErrorModalProviderState {
  error: ModalOptions | null;
}

export class ErrorModalProvider extends React.Component<
  ErrorModalProviderProps,
  ErrorModalProviderState
> {
  state: ErrorModalProviderState = {
    error: null
  };

  // Default the modal variant to generic if no variant is specified
  handleError = ({
    variant = "generic",
    error,
    ...options
  }: ModalOptions = {}) => {
    const { isGraphQLAuthError } = getErrorMessage(error);

    // Auth errors are logged out and redirected, no need for a modal
    if (isGraphQLAuthError) {
      return;
    }

    if (isAnalyticsEnabled()) {
      trackEvent("error_modal");
    }

    this.setState({ error: { variant, ...options } });
  };

  getModalTitle = (variant?: ErrorVariant) => {
    switch (variant) {
      case "generic":
        return "Something went wrong";
      case "internet_connection":
        return "Check your connection";
      case "payment_failed":
        return "Payment failed";
      default:
        return "";
    }
  };

  getModalText = (variant?: ErrorVariant) => {
    switch (variant) {
      case "generic":
        return "Please try again.";
      case "internet_connection":
        return "Looks like you might be offline. Make sure you’re connected to the internet and try again.";
      case "payment_failed":
        return "Please check your card details and try again";
      default:
        return "";
    }
  };

  render() {
    const { children } = this.props;
    const { handleError } = this;
    const { error } = this.state;

    return (
      <ErrorModalContext.Provider value={{ handleError }}>
        {children}

        {error && (
          <Modal
            isOpen={!!error}
            title={this.getModalTitle(error.variant)}
            isTitleVisible={error.variant !== "generic"}
            onClose={() => this.setState({ error: null })}
          >
            {error.variant === "generic" && (
              <IllustrationWrapper>
                <Illustration src="large-something-went-wrong-1" />
              </IllustrationWrapper>
            )}
            {error.variant === "generic" && (
              <DisplayHeading size="xl" align="center" margin="0 0 m">
                Something went wrong
              </DisplayHeading>
            )}
            <Text
              margin="0 0 m"
              align={error.variant === "generic" ? "center" : undefined}
            >
              {this.getModalText(error.variant)}
            </Text>
            <DeprecatedButtonGroup
              alignment={error.variant === "generic" ? "center" : undefined}
            >
              {!!error.onRetry && (
                <StyledButton
                  label="Try again"
                  onClick={() => {
                    this.setState({ error: null });
                    error.onRetry!();
                  }}
                  $isCentered={error.variant === "generic"}
                />
              )}
              <StyledButton
                label="Close"
                onClick={() => this.setState({ error: null })}
                variant="secondary"
                $isCentered={error.variant === "generic"}
              />
            </DeprecatedButtonGroup>
          </Modal>
        )}
      </ErrorModalContext.Provider>
    );
  }
}

export const getErrorVariant = (error: ApolloError): ErrorVariant =>
  !!error.networkError ? "internet_connection" : "generic";

export const useErrorModal = (): ErrorModalContextProps => {
  const ctx = useContext(ErrorModalContext);

  if (!ctx) {
    throw Error("Not inside `ErrorModalProvider`!");
  }

  return {
    handleError: ctx.handleError
  };
};

export default useErrorModal;
