// AUTO-GENERATED - DO NOT EDIT!
import gql from "graphql-tag";
import { useMutation, MutationHookOptions } from "@apollo/client";
import { logout } from "../types/logout";

export const useLogoutMutation = (opts: MutationHookOptions<logout, {}>) => {
  return useMutation<logout, {}>(logoutMutation, opts);
};

export const logoutMutation = gql`
  mutation logout {
    logout {
      errors {
        field
        message
      }
    }
  }
`;
