import { Reducer } from "redux";
import { createStandardAction } from "typesafe-actions";
import { ActionsUnion } from "./types";
import Cookies from "js-cookie";
import { resetIntercom } from "~/intercom";
import { reset } from "~/analytics";
import config from "../config";

export interface AuthState {
  authenticated: boolean;
  intercomId?: string;
  nhsSetupToken: string;
  nhsLoginIM1Enabled: boolean;
  usingStaffSession: boolean;
}

export const authActions = {
  authenticate: createStandardAction("AUTH/LOGIN")<{
    intercomId: string;
    staffSession?: boolean;
  }>(),
  setNHSSetupToken: createStandardAction("AUTH/SET_NHS_SETUP_TOKEN")<{
    token: string;
  }>(),
  setNHSLoginHasIM1Enabled: createStandardAction(
    "AUTH/SET_NHS_IM1_ENABLED"
  )<boolean>(),
  clearNHSLoginIM1Enabled: createStandardAction(
    "AUTH/CLEAR_NHS_IM1_ENABLED"
  )<void>(),
  clearAuthentication: createStandardAction("AUTH/CLEAR_AUTHENTICATION")()
};

export const authInitialState: AuthState = {
  authenticated: false,
  nhsSetupToken: "",
  nhsLoginIM1Enabled: false,
  usingStaffSession: false
};

export const authReducer: Reducer<AuthState, ActionsUnion> = (
  state = authInitialState,
  action
) => {
  switch (action.type) {
    case "AUTH/LOGIN":
      return {
        ...state,
        authenticated: true,
        intercomId: action.payload.intercomId,
        usingStaffSession: !!action.payload.staffSession
      };
    case "AUTH/SET_NHS_SETUP_TOKEN":
      return {
        ...state,
        nhsSetupToken: action.payload.token
      };
    case "AUTH/SET_NHS_IM1_ENABLED":
      return {
        ...state,
        nhsLoginIM1Enabled: action.payload
      };
    case "AUTH/CLEAR_NHS_IM1_ENABLED":
      return {
        ...state,
        nhsLoginIM1Enabled: false
      };
    case "AUTH/CLEAR_AUTHENTICATION":
      // Actual logging out is done on the API, this just updates our local sate
      reset();
      resetIntercom();
      Cookies.remove("staffSession", {
        domain: config.COOKIE_DOMAIN,
        secure: true
      });
      return {
        ...state,
        authenticated: false,
        intercomId: "",
        usingStaffSession: false
      };
  }
  return state;
};
