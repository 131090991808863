export function parseDate(
  year: string,
  month: string,
  day: string
): Date | null {
  const d = new Date(`${year}-${month}-${day}`);
  if (!Number.isFinite(d.getTime())) {
    return null;
  }

  return d;
}

export function validateDOB({
  day,
  month,
  year,
  allowUnder16s
}: {
  day: string;
  month: string;
  year: string;
  allowUnder16s?: boolean;
}): string | undefined {
  const minDate = yearsAgo(16);
  const maxDate = yearsAgo(115);
  const dob = parseDate(year, month, day);

  if (!dob || isDateOnOrBefore(maxDate, dob) || isDateInFuture(dob)) {
    return "Please enter a valid date of birth";
  }

  if (!isDateOnOrBefore(minDate, dob) && !allowUnder16s) {
    return "You must be over 16 years old";
  }
}

export function isDateInFuture(d: Date): boolean {
  const now = resetTime(new Date());
  const date = resetTime(new Date(d.getTime()));
  return date > now;
}

export function isDateOnOrBefore(minDate: Date, d: Date): boolean {
  const min = resetTime(minDate);
  const date = resetTime(new Date(d.getTime()));
  return date <= min;
}

export function isDateBefore(minDate: Date, d: Date): boolean {
  const min = resetTime(minDate);
  const date = resetTime(new Date(d.getTime()));

  return date < min;
}

export function timestampSeconds(d: Date): number {
  return Math.floor(d.getTime() / 1000);
}

export function yearsAgo(years: number): Date {
  const d = resetTime(new Date());
  d.setFullYear(d.getFullYear() - years);
  return d;
}

export function daysAgo(days: number): Date {
  return resetTime(new Date(Date.now() - 60 * 60 * 24 * days * 1000));
}

function resetTime(d: Date) {
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
}

export function getFormattedDate(date: string) {
  const d = new Date(date);
  const day = ("0" + d.getDate()).slice(-2);
  const month = ("0" + (d.getMonth() + 1)).slice(-2);
  const year = d.getFullYear().toString();
  return `${year}-${day}-${month}`;
}

export function getDifferenceInDays(epochSeconds: number): number {
  const today = new Date();
  const DAY_DIVIDER = 1000 * 3600 * 24;
  const diff = Math.round(epochSeconds * 1000 - today.getTime());
  const diffInDays = diff / DAY_DIVIDER;
  return Math.round(diffInDays);
}

export function getDaysString(days: number): string {
  if (days === 1) {
    return `${days} day`;
  }
  return `${days} days`;
}

/**
 * Get the number of days in any given month
 * @return {integer} The number of days in the month
 */
function daysInMonth(month: number, year: number) {
  switch (month) {
    // February
    case 1:
      const isLeapYear = (year % 4 === 0 && year % 100) || year % 400 === 0;
      return isLeapYear ? 29 : 28;
    case 3: // April
    case 5: // June
    case 8: // September
    case 10: // November
      return 30;
    default:
      return 31;
  }
}

/**
 * Checks if date is valid
 * @return true if valid
 */
export function isValidDate(day: number, month: number, year: number): boolean {
  const isMonthInRange = month >= 0 && month < 12;
  const isDayInRange = day > 0 && day <= daysInMonth(month, year);

  return isMonthInRange && isDayInRange;
}

/**
 * Checks if date is valid
 * @param {string} value date string in format dd/mm/yyyy
 * @return {Date} new Date instance
 */
export const parseSimpleDateStringToDate = (value: string) => {
  const [day, month, year] = value.split("/").map(Number);
  return new Date(year, month - 1, day);
};

export const removeTimezoneOffset = (date: Date) => {
  const time = date.getTime();
  const tzOffset = new Date().getTimezoneOffset() * 60000; // Offset in milliseconds
  return new Date(time - tzOffset);
};
