// AUTO-GENERATED - DO NOT EDIT!
import gql from "graphql-tag";
import { useMutation, MutationHookOptions } from "@apollo/client";
import {
  performPdsCheck,
  performPdsCheckVariables
} from "../types/performPdsCheck";

export const usePerformPdsCheckMutation = (
  opts: MutationHookOptions<performPdsCheck, performPdsCheckVariables>
) => {
  return useMutation<performPdsCheck, performPdsCheckVariables>(
    performPdsCheckMutation,
    opts
  );
};

export const performPdsCheckMutation = gql`
  mutation performPdsCheck($patientId: ID!, $isMigrationCheck: Boolean) {
    performPdsCheck(
      patientId: $patientId
      isMigrationCheck: $isMigrationCheck
    ) {
      patient {
        id
        isNominated
        nominationStatus
        isNominatedWithP2U
        nominatedPharmacyCheckedAt {
          seconds
        }
        nominatedPharmacyUpdatedAt {
          seconds
        }
      }
      patientDataSynced
    }
  }
`;
