import { ErrorResponse } from "@apollo/client/link/error";
import { GraphQLError as GQLErrorType } from "graphql";

export class GraphQLError extends Error {
  constructor(message: string, options: {}) {
    super(message, options);
    this.name = "GraphQLError";
  }
}

export function hasErrorCode(
  errors: ErrorResponse["graphQLErrors"],
  code: string
) {
  if (!errors) {
    return false;
  }

  for (const e of errors) {
    if ((e as any).code === code) {
      return true;
    }
  }

  return false;
}

export function getGraphQLError(error: GQLErrorType): Error | null {
  if (!error) {
    return null;
  }

  return new GraphQLError(error.message, { cause: (error as any).code });
}
