import { Store } from "redux";
import { ActionsUnion, State } from "./store/types";

type ReduxStore = Store<State, ActionsUnion> & {
  dispatch: {};
};

let store: ReduxStore | null = null;
export function setStore(s: ReduxStore) {
  store = s;
}

export function isEnabled() {
  return Boolean((window as any).analytics);
}

type EventName =
  | "account_change_password_pageview"
  | "account_deleted_pageview"
  | "account_pageview"
  | "account_preferred_name_confirm"
  | "account_preferred_name_pageview"
  | "add_address_confirm"
  | "add_address_pageview"
  | "add_exemption_confirm"
  | "add_exemption_expiry_pageview"
  | "add_exemption_failure"
  | "add_exemptions_pageview"
  | "add_medication_confirm"
  | "add_medication_due_date_pageview"
  | "add_medication_pageview"
  | "add_medication_pick_strength"
  | "add_on_item_add"
  | "add_on_item_info_prompt"
  | "add_on_item_info_prompt_dismiss"
  | "add_on_item_info_prompt_toggle"
  | "add_on_items_confirm"
  | "add_on_items_ignore"
  | "add_payment_method_confirm"
  | "add_payment_method_failure"
  | "add_payment_method_pageview"
  | "add_phone_pageview"
  | "addons_add"
  | "addons_confirm"
  | "addons_ignore"
  | "addons_info_prompt"
  | "addons_info_prompt_dismiss"
  | "addons_info_prompt_toggle"
  | "ask_patient_accept"
  | "ask_patient_change_address"
  | "ask_patient_collect_lloyds"
  | "ask_patient_collect_lloyds_map"
  | "ask_patient_confirm"
  | "ask_patient_deliver"
  | "ask_patient_delivery_method_pageview"
  | "ask_patient_exemption_add_confirm"
  | "ask_patient_exemption_add_pageview"
  | "ask_patient_local_collect"
  | "ask_patient_pageview"
  | "ask_patient_reject"
  | "ask_patient_review_pageview"
  | "ask_patient_start"
  | "banner_nominate_echo"
  | "call_gp"
  | "call_lloydspharmacy"
  | "cancel_rx_cancellation_reason"
  | "cancel_rx_confirmation_pageview"
  | "cancel_rx_select_medication_pageview"
  | "change_email_confirm"
  | "change_email_pageview"
  | "change_gp_confirm"
  | "change_gp_pageview"
  | "change_password_pageview"
  | "change_password_send_email"
  | "change_phone_confirm"
  | "change_phone_pageview"
  | "check_phone_error_pageview"
  | "check_phone_pageview"
  | "collect_lloyds_address_confirm"
  | "collect_lloyds_map"
  | "collect_lloyds_pageview"
  | "collect_lloyds_search_error"
  | "connect_gp_confirm"
  | "connect_gp_error"
  | "connect_gp_pageview"
  | "connect_gp_success"
  | "contact_details_pageview"
  | "dashboard_pageview"
  | "dashboard_pds_fail"
  | "delete_account_confirm"
  | "delivery_addresses_delete_confirm"
  | "delivery_method_block"
  | "dose_reminders_summary_pageview"
  | "dose_reminders_switch_date"
  | "download_app"
  | "download_app_close"
  | "edit_address_confirm"
  | "edit_reminder"
  | "edit_reminder_confirm"
  | "error_modal"
  | "exemptions_pageview"
  | "experiment"
  | "external_link"
  | "first_order_confirmed"
  | "forgot_password_pageview"
  | "hdyhau_survey_complete"
  | "help_article"
  | "help_article_action"
  | "help_article_pageview"
  | "help_contact_call"
  | "help_contact_message"
  | "help_contact_us_pageview"
  | "help_pageview"
  | "help_patient_picker_pageview"
  | "incomplete_account"
  | "local_collect_address_confirm"
  | "local_collect_pageview"
  | "local_collect_search_error"
  | "login"
  | "login_cta"
  | "login_failure_not_valid"
  | "login_pageview"
  | "login_successful"
  | "manage_addresses_pageview"
  | "medication_add_exemption"
  | "medication_confirm_prescription"
  | "medication_details_pageview"
  | "medication_pageview"
  | "medication_pay_now"
  | "medication_supply_change_confirm"
  | "medication_supply_pageview"
  | "nav_account"
  | "nav_help"
  | "nav_home"
  | "nav_lloyds_shop"
  | "nav_logout"
  | "nav_medication"
  | "nav_referrals"
  | "nav_request"
  | "nav_whats_new"
  | "need_medication_sooner_prompt"
  | "nhs_login_account_creation_error_pageview"
  | "nhs_login_account_creation_pageview"
  | "nhs_login_account_creation_terms_pageview"
  | "nhs_login_error_pageview"
  | "nhs_login_existing_user_consent_pageview"
  | "nhs_login_success"
  | "nhs_login_unavaliable_pageview"
  | "nok_confirm_address_pageview"
  | "nok_details_pageview"
  | "nok_search_gp_pageview"
  | "nok_terms_pageview"
  | "nominate_echo_confirm"
  | "nominate_echo_pageview"
  | "not_found_pageview"
  | "notifications_pageview"
  | "open_intercom"
  | "partial_dispensing_confirmation_pageview"
  | "partial_dispensing_intro_pageview"
  | "partial_dispensing_review_pageview"
  | "partner_add_medication_warning_pageview"
  | "partner_arrange_delivery_confirmation_pageview"
  | "partner_arrange_delivery_review_pageview"
  | "partner_confirm_request_fail"
  | "partner_payment_error"
  | "partner_request_prescription_warning_pageview"
  | "partner_select_medications_pageview"
  | "partner_signup_account_pageview"
  | "partner_signup_address_pageview"
  | "partner_signup_complete"
  | "partner_signup_intro_pageview"
  | "partner_signup_terms_pageview"
  | "partner_signup_verify_email_pageview"
  | "patient_chosen"
  | "patient_details_nominate_echo_confirm"
  | "patient_details_pageview"
  | "patients_list_open"
  | "payment_confirm"
  | "payment_confirmation_pageview"
  | "payment_failed"
  | "payment_method_changed"
  | "payment_methods_delete_confirm"
  | "payment_methods_make_primary"
  | "payment_methods_pageview"
  | "payment_premium_delivery_learn_more"
  | "payment_review_pageview"
  | "payment_shown_premium_delivery"
  | "payment_successful"
  | "pds_contact_support_pageview"
  | "pds_update_details_pageview"
  | "pds_update_fail"
  | "pds_update_success_pageview"
  | "phone_confirmed_pageview"
  | "phone_verify_call"
  | "privacy_policy_external_pageview"
  | "rds_request_collect_lloyds"
  | "rds_request_collect_lloyds_map"
  | "rds_request_confirm"
  | "rds_request_deliver"
  | "rds_request_delivery_method_pageview"
  | "rds_request_health_change"
  | "rds_request_health_check_pageview"
  | "rds_request_local_collect"
  | "rds_request_pageview"
  | "rds_request_review_pageview"
  | "rds_request_start"
  | "rds_request_warning_modal"
  | "referrals_account_info_pageview"
  | "referrals_banner_website"
  | "referrals_choose_reward"
  | "referrals_choose_reward_confirm"
  | "referrals_choose_reward_confirm_pageview"
  | "referrals_copy_link"
  | "referrals_email"
  | "referrals_facebook"
  | "referrals_more_options"
  | "referrals_pageview"
  | "referrals_twitter"
  | "remove_exemption_confirm"
  | "remove_medication_confirm"
  | "request_details_pageview"
  | "request_history_pageview"
  | "request_medication_add_on_items_pageview"
  | "request_medication_addons_pageview"
  | "request_medication_change_address"
  | "request_medication_collect_lloyds"
  | "request_medication_collect_lloyds_map"
  | "request_medication_confirm"
  | "request_medication_deliver"
  | "request_medication_delivery_method_pageview"
  | "request_medication_edit_address_pageview"
  | "request_medication_exemption_add_confirm"
  | "request_medication_exemption_add_pageview"
  | "request_medication_local_collect"
  | "request_medication_pageview"
  | "request_medication_partial_accept"
  | "request_medication_partial_prompt"
  | "request_medication_partial_reject"
  | "request_medication_review_pageview"
  | "request_medication_start"
  | "request_medication_unavailable_learn_more"
  | "request_medication_unavailable_prompt"
  | "resend_phone"
  | "reset_password_confirm"
  | "reset_password_error"
  | "reset_password_pageview"
  | "return_rx_complete_pageview"
  | "return_rx_intro_pageview"
  | "return_rx_patient_picker_pageview"
  | "return_rx_review_pageview"
  | "return_rx_select_pageview"
  | "self_help_contact_us"
  | "send_partial_confirm"
  | "send_password_reset_email"
  | "signup_account_info_pageview"
  | "signup_add_phone_pageview"
  | "signup_change_email"
  | "signup_change_phone"
  | "signup_check_email_pageview"
  | "signup_check_phone_pageview"
  | "signup_complete"
  | "signup_confirm_gp_pageview"
  | "signup_confirm_manual_gp_pageview"
  | "signup_confirmed_email_continue"
  | "signup_cta"
  | "signup_cta_nhs_login"
  | "signup_email_typo_fix"
  | "signup_error_account_duplicate"
  | "signup_error_email_verification"
  | "signup_error_link_invalid"
  | "signup_error_patient_duplicate"
  | "signup_error_patient_duplicate_call"
  | "signup_error_patient_duplicate_intercom"
  | "signup_error_pds_fail"
  | "signup_error_pds_fail_call"
  | "signup_error_pds_fail_intercom"
  | "signup_error_pds_fail_pageview"
  | "signup_manual_gp_search_pageview"
  | "signup_nhs_login_continue"
  | "signup_nhs_login_info"
  | "signup_nhs_login_learn_more"
  | "signup_no_third_party_gp_pageview"
  | "signup_nominate_echo_confirm"
  | "signup_nominate_echo_pageview"
  | "signup_non_eps_gp_pageview"
  | "signup_pageview"
  | "signup_pds_manual_trace_pageview"
  | "signup_pds_nhs_number_pageview"
  | "signup_pds_options_pageview"
  | "signup_pds_trace_success"
  | "signup_phone_confirmed_pageview"
  | "signup_phone_verify_call"
  | "signup_resend_email"
  | "signup_resend_phone"
  | "signup_spam_email_info"
  | "signup_started"
  | "signup_unsupported_gp_pageview"
  | "terms_and_conditions_external_pageview"
  | "track_delivery"
  | "update_address_pageview"
  | "update_email_confirm"
  | "update_email_pageview"
  | "update_next_due_date_pageview"
  | "update_phone_pageview"
  | "verify_email_confirm"
  | "verify_email_confirmation_pageview"
  | "watch_video"
  | "whats_new_pageview"
  | "new_email_check_email_pageview"
  | "new_email_resend_email"
  | "new_email_spam_email_info"
  | "new_email_error_email_verification"
  | "new_email_error_account_duplicate"
  | "new_email_email_typo_fix"
  | "new_email_change_email"
  | "new_email_confirmed_email_continue";

/**
 * Deprecated method for tracking
 *
 * N.B. this does not refer to the functionality we are tracking being deprecated or a candidate for deprecation.
 *
 * @deprecated
 * @see {@link segment}
 * @example segment.vitaminsTestOpened({patientid: "pat_123"})
 */
export const trackEvent: (
  eventName: EventName,
  props?: any,
  options?: any,
  callback?: () => void
) => any = (
  eventName: EventName,
  props?: any,
  options?: any,
  callback?: () => void
) => {
  if (!isEnabled()) {
    return;
  }
  window.analytics.track(eventName, props, options, callback);
};

/**
 * Deprecated method for tracking
 *
 * N.B. this does not refer to the functionality we are tracking being deprecated or a candidate for deprecation.
 *
 * @deprecated
 * @see {@link trackEvent}
 */
export const deprecatedTrack = (event: string, properties?: object) => {
  if (!isEnabled()) {
    return;
  }

  let intercomId;
  if (store) {
    intercomId = store.getState().auth.intercomId;
  }

  (window.analytics as any).track(event, {
    ...properties,
    intercom_id: intercomId
  });
};

export const identify = (userId: string, traits?: object) => {
  if (!isEnabled()) {
    return;
  }

  window.analytics.identify(userId, traits);
};

export const deviceId = () => {
  try {
    if (!window.analytics) {
      return "";
    }

    let user;
    if (window.analytics.user) {
      user = window.analytics.user();
    }

    if (!user) {
      return "";
    }

    return user.anonymousId();
  } catch (err) {
    console.error("unable to get segment anonymous id", err);
    return "";
  }
};

export const reset = () => {
  if (!isEnabled()) {
    return;
  }

  window.analytics.reset();
};

interface PageProperties {
  [key: string]: string | number;
}

export const page = (name?: string, properties?: PageProperties) => {
  if (!isEnabled()) {
    return;
  }

  let intercomId;
  if (store) {
    intercomId = store.getState().auth.intercomId;
  }

  const props = { ...properties, intercom_id: intercomId };

  window.analytics.page(name, props);
};
