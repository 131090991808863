import React from "react";
import styled from "styled-components";
import { Illustration } from "~/design-system/components/Illustration";

const IllustrationWrapper = styled.div`
  width: 160px;
`;

interface Props {
  margin?: string;
}

export const ErrorIllustration = ({ margin }: Props) => (
  <IllustrationWrapper>
    <Illustration src="large-something-went-wrong-1" margin={margin} />
  </IllustrationWrapper>
);
