import { trackEvent } from "./analytics";
import config from "./config";
import Cookies from "js-cookie";

export const resetIntercom = () => {
  if (window && window.Intercom) {
    window.Intercom("shutdown");
  }
};

export const intercomReady = () => {
  return !!window && !!window.Intercom;
};

export const rebootIntercom = () => {
  // Ensure intercom ids are removed in case the Intercom Javascript hasn't loaded yet
  Cookies.remove(`intercom-session-${config.INTERCOM_APP_ID}`);
  Cookies.remove(`intercom-id-${config.INTERCOM_APP_ID}`);

  if (window && window.Intercom) {
    // Ensure any existing intercom session is shutdown
    window.Intercom("shutdown");
    window.Intercom("boot", {
      app_id: config.INTERCOM_APP_ID,
      custom_launcher_selector: ".openIntercom",
      vertical_padding: 60
    });
    window.Intercom("onShow", () => {
      trackEvent("open_intercom");
    });
  }
};

export const showIntercom = () => {
  if (window && window.Intercom) {
    window.Intercom("show");
  }
};

export const onHideIntercom = (onHide: () => void) => {
  if (window && window.Intercom) {
    window.Intercom("onHide", onHide);
  }
};

export const onShowIntercom = (onShow: () => void) => {
  if (window && window.Intercom) {
    window.Intercom("onShow", onShow);
  }
};

export const updateIntercom = ({
  intercomId,
  email,
  firstName,
  lastName,
  accountId,
  intercomUserHash,
  digitalMarketing = false
}: {
  intercomId: string;
  email: string;
  firstName: string;
  lastName: string;
  accountId: string;
  intercomUserHash: string;
  digitalMarketing?: boolean;
}) => {
  if (window && window.Intercom) {
    let IntercomAttributes: { [key: string]: string | boolean } = {
      user_id: intercomId,
      email,
      name: firstName + " " + lastName,
      digital_marketing: digitalMarketing,
      user_hash: intercomUserHash
    };

    // We don't use the same attribute name for staging and production for account_id,
    // because, while we have historically used `account_id` on our production
    // Intercom account, it is now a reserved attribute name, so there is no way to
    // add account_id as a 'people data attribute' on our staging account. At time
    // of writing the complexity and risk involved in renaming this attribute across
    // our codebase and data stores / tools is considered too high, so we will use
    // staging_account_id to work around the lack of ID in our test environments.
    if (config.IS_STAGING === "true") {
      IntercomAttributes["staging_account_id"] = accountId;
    } else {
      IntercomAttributes["account_id"] = accountId;
    }

    window.Intercom("update", IntercomAttributes);
  } else {
  }
};

export const updateUser = ({
  intercomId,
  email
}: {
  intercomId: string;
  email: string;
}) => {
  if (window && window.Intercom) {
    window.Intercom("update", {
      user_id: intercomId,
      email
    });
  }
};
