import { Reducer } from "redux";
import { createStandardAction } from "typesafe-actions";
import { ActionsUnion } from "./types";
import { getFeatures_features } from "../graphql/types/getFeatures";

export interface FeaturesState {
  flags: getFeatures_features[] | null;
}

export const featuresActions = {
  setFlags: createStandardAction("FEATURES/SET_FLAGS")<{
    flags: getFeatures_features[];
  }>()
};

export const featuresInitialState: FeaturesState = {
  flags: null
};

export const featuresReducer: Reducer<FeaturesState, ActionsUnion> = (
  state = featuresInitialState,
  action
) => {
  switch (action.type) {
    case "FEATURES/SET_FLAGS":
      return {
        ...state,
        flags: action.payload.flags
      };
  }
  return state;
};
