import { useContext, useCallback } from "react";
import { getExperiments_experiments as experimentType } from "../../../graphql/types/getExperiments";
import { useUpdateExperimentMutation } from "../../../graphql/hooks/useUpdateExperimentMutation";
import { ExperimentsContext } from "../ExperimentsContext";
import { ExperimentName } from "../Experiments";

// This is our experiment hook which will let us access a specific experiment's
// data or update that experiment in our service from anywhere wrapped in the
// ExperimentsContext provider (which at the moment is everywhere, as we wrap it
// around the <RoutesLayoutSwitch /> in App.tsx)

export const useExperiment = (
  name: ExperimentName
): {
  experiment: experimentType | undefined;
  trackExperimentEvents: (apiEventNames: string[]) => void;
} => {
  const { experiments, reFetchExperiments } = useContext(ExperimentsContext);

  let experiment = experiments.find(e => e.name === name);

  // Setup the mutation for event logging
  const [updateExperiment] = useUpdateExperimentMutation({
    onError: err => console.log(err),
    onCompleted: _ => reFetchExperiments(),
    refetchQueries: ["getMigrationStatus"]
  });

  const trackExperimentEvents = useCallback(
    (apiEventNames: string[]) => {
      if (experiment) {
        updateExperiment({
          variables: {
            experimentName: experiment!.name,
            events: apiEventNames
          }
        });
      }
    },
    [experiment, updateExperiment]
  );

  return {
    experiment,
    trackExperimentEvents
  };
};
