import { useRouteMatch } from "react-router-dom";

export const useSwitchPatient = (fromNavigation?: boolean) => {
  const currentPath = useRouteMatch<{ patientId: string }>();

  const currentPatientID = currentPath.params.patientId;

  // if no patient ID is provided use the current patient ID from the URL
  return (patientID: string = currentPatientID) => {
    // if no patient ID is provided or was in the original URL return to the home page
    if (!patientID) {
      return "/";
    }
    const fragments = currentPath.path.split("/:");
    if (fragments.length >= 2) {
      if (fromNavigation) {
        return "/patient/" + patientID;
      }
      // if there is another fragment after the patient ID trim the URL after the patient ID.
      // and replace with the new patient ID
      //
      // For example /patient/:patientId/medication/details becomes /patient/pat_123
      // this redirects us to the start of the patient journey but keeps the context of medications or help etc
      return fragments[0] + "/" + patientID;
    } else {
      if (currentPath.path.includes(":patientId")) {
        // if there are no other fragments after the patient ID replace the patient ID
        return currentPath.path.replace(":patientId", patientID);
      } else {
        // if there is no patient ID in the URL add navigate to medicine tab
        return "/patient/" + patientID;
      }
    }
  };
};

export default useSwitchPatient;
