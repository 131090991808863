import { ErrorResponse } from "@apollo/client/link/error";
import { encode, QueryParams } from "./queryString";
import { hasErrorCode } from "./graphql";
import { ApiErrors } from "../apierrors";

export function isAuthError(errors: ErrorResponse["graphQLErrors"]) {
  if (!errors) {
    return false;
  }

  for (const code of [
    ApiErrors.ERR_NOT_AUTHENTICATED,
    ApiErrors.ERR_AUTH_EXPIRED,
    ApiErrors.ERR_NOT_ALLOWED
  ]) {
    if (hasErrorCode(errors, code)) {
      return true;
    }
  }

  return false;
}

export function isExpectedError(errors: ErrorResponse["graphQLErrors"]) {
  if (!errors) {
    return false;
  }

  for (const code of [
    ApiErrors.ERR_ALREADY_EXISTS,
    ApiErrors.ERR_RESOURCE_EXHAUSTED,
    ApiErrors.ERR_NOT_AUTHENTICATED,
    ApiErrors.ERR_AUTH_EXPIRED,
    ApiErrors.ERR_NOT_ALLOWED,
    ApiErrors.ERR_NOT_FOUND,
    ApiErrors.ERR_UNDELIVERABLE_POSTCODE
  ]) {
    if (hasErrorCode(errors, code)) {
      return true;
    }
  }

  return false;
}

export function getLoginRedirectUrl(url: string) {
  const queryParams: QueryParams = {
    returnUrl: [url]
  };

  const encodedParams = encode(queryParams);
  return `/login${encodedParams}`;
}
