import React, { useEffect, useState } from "react";
import config from "../config";
import { oneTrustCookies } from "./oneTrustCookies";
import segment from "~/segment";

import Cookies from "js-cookie";
import { decode } from "~/utils/queryString";
import { useLocation } from "react-router";

export const Tracking = () => {
  // We listen for the OneTrust event and load the cookie categories consented
  // to into state, by default we pull the active groups of of cookies rather
  // than depending on the rather flakey callbacks from OneTrust themselves for
  // first load. At time of writing, OneTrust autoblocking scripts fail quite a
  // lot of the time, so doing manual blocking is the most reliable and
  // predictable way to handle our cookies at present. See this list for our
  // category codes:
  // https://app-uk.onetrust.com/cookies/categorizations?tab=Categories

  const { groups } = oneTrustCookies();

  const [hasConsentForCategories, setHasConsentForCategories] = useState<
    string[]
  >(groups || []);

  const [segmentLoaded, setSegmentLoaded] = useState<boolean>(false);
  const [mappLoaded, setMappLoaded] = useState(false);

  const { search } = useLocation();
  const queryParams = decode(search);

  const consentChange = (event: any) => {
    if (event.detail && event.detail.length > 0) {
      segment.cookieConsentChanged({ consent: event.detail });
      setHasConsentForCategories(event.detail);
    }
  };

  useEffect(() => {
    window.addEventListener("consent.onetrust", consentChange);
    return () => {
      window.removeEventListener("consent.onetrust", consentChange);
    };
  }, []);

  if (
    hasConsentForCategories.includes("C0002") ||
    hasConsentForCategories.includes("C0004")
  ) {
    // if the traffic is coming from affiliate window, set a cookie to capture the lead id
    if (queryParams.awc) {
      Cookies.set("awc", queryParams.awc[0], {
        expires: 30,
        domain: config.COOKIE_DOMAIN,
        secure: true
      });
    }
  }

  // "Performance" cookies, defined as: "These cookies allow us to count
  // visits and traffic sources so we can measure and improve the
  // performance of our site. They help us to know which pages are the most
  // and least popular and see how visitors move around the site."
  // https://app-uk.onetrust.com/cookies/categorizations?tab=Categories

  if (hasConsentForCategories.includes("C0002")) {
    const trackingUrl = config.MAPP_TRACKING_URL;
    if (!mappLoaded && trackingUrl !== "") {
      mappTrackingScript(trackingUrl);
      setMappLoaded(true);
    }

    // Boot up the Segment client if not already there.
    // Warning: Segment loads a lot of 3rd party scripts, so we should only load
    // it if the user has consented to performance cookies
    if (!segmentLoaded && Boolean((window as any).analytics)) {
      setSegmentLoaded(true);
      window.analytics.load(config.SEGMENT_WRITE_KEY, {});
      window.analytics.addSourceMiddleware((middleware: any) => {
        // All event types are processed by the middleware, we should only intercept the 'track' one
        if (middleware.payload.type() !== "track") {
          // middleware.next contains the next function to call, so either another middleware or the final call to segment,
          // not calling next drops the event
          middleware.next(middleware.payload);
        }

        middleware.next(middleware.payload);
      });
    }
  }

  // "Marketing" cookies, defined as: "These cookies may be set through our
  // side by our advertising partners. They may be used by those companies
  // to build a profile of your interests and show you relevant adverts on
  // other sites. If you do not allow these cookies, you will experience
  // less targeted advertising."
  // https://app-uk.onetrust.com/cookies/categorizations?tab=Categories
  let consentedAdvertisingScripts = <></>;

  if (hasConsentForCategories.includes("C0004")) {
    consentedAdvertisingScripts = (
      <>
        <script
          src={`https://www.googletagmanager.com/gtag/js?id=GTM-K67DQVNX`}
          async={true}
        ></script>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'GTM-K67DQVNX');
                  `
          }}
        ></script>
      </>
    );
  }

  // These will ultimately be rendered into the body as we can't load into the
  // <head> using <Helmet> after the server render has happened (and we
  // obviously can't render during SSR as we don't have access to the client
  // cookies)

  return <>{consentedAdvertisingScripts}</>;
};

/**
 * This is the script Mapp sent us to configure tracking once a user has consented to cookies on the app.
 * They sent it to us minified, but I've expanded it to something vaguely typescript, and we should only
 * include it once the user has consented to performance cookies.
 *
 * I know this goes against the flow of this component to an extent as it included the tracking in the
 * head of the document rather than the body like the rest of the attributes here. But I'd like to
 * test it works as Mapp gave it to us and we can look at moving it to work with the rest of the
 * component once we know it does.
 *
 * @param url
 */
export const mappTrackingScript = (url: string): void => {
  const t = document,
    n = t.createElement("script");
  n.async = true;
  n.defer = true;
  n.src = url;
  t.getElementsByTagName("head")[0].appendChild(n);
};
