import React, { useState, useCallback, useMemo } from "react";
import styled from "styled-components";
import theme from "~/styled/theme";
import Icon from "./Icon";
import { HelpOutlineIcon, ChevronRightIcon } from "@echo-health/icons-web";
import { Modal } from "../design-system/components/Modal";
import Divider from "./Divider";
import { Button } from "../design-system/components/Button";
import useCookie from "../hooks/useCookie";
import { trackEvent } from "../analytics";
import { Heading } from "../design-system/components/Heading";
import { Text } from "../design-system/components/Text";
import { Illustration } from "../design-system/components/Illustration";
import Form from "./Form";
import OptionsGroup from "../design-system/components/OptionsGroup";
import useIntercom from "./IntercomProvider";
import { useSelector } from "react-redux";
import { State } from "../store/types";
import { shuffle } from "../utils/misc";
import useForm from "../hooks/useForm";
import { TextInput } from "@echo-health/design-system";

enum AttributionSource {
  searchEngines = "search engines",
  socialMedia = "social media",
  tvRadio = "tv or radio",
  letterFlyer = "letter or flyer",
  friendsFamily = "family, friends or colleagues",
  lloydspharmacy = "lloydspharmacy",
  gpSurgery = "gp surgery",
  other = "other"
}

const surveyFormOptions = [
  {
    label: "Search engines",
    value: AttributionSource.searchEngines
  },
  {
    label: "Social media",
    value: AttributionSource.socialMedia
  },
  {
    label: "TV or Radio advert",
    value: AttributionSource.tvRadio
  },
  {
    label: "Letter or flyer sent to home",
    value: AttributionSource.letterFlyer
  },
  {
    label: "Family, friends, colleagues",
    value: AttributionSource.friendsFamily
  },
  {
    label: "LloydsPharmacy",
    value: AttributionSource.lloydspharmacy
  },
  {
    label: "GP surgery",
    value: AttributionSource.gpSurgery
  }
];

const BannerWrapper = styled.button`
  display: flex;

  bottom: 0;
  position: fixed;
  z-index: 1000;

  font-family: ${theme.typography.bodyFamily};
  font-weight: ${theme.typography.weight.bold};
  font-size: ${theme.typography.size.m};
  line-height: ${theme.typography.lineHeight.m};

  margin-bottom: ${theme.spacing.m};
  margin-right: ${theme.spacing.m};

  border-radius: ${theme.misc.borderRadius};
  background-color: ${theme.color.purple60};
  color: ${theme.color.white};

  box-shadow: ${theme.shadow.depth1};

  &:focus:not(:hover) {
    box-shadow: ${p => `${p.theme.shadow.focusRing} ${p.theme.color.purple20}`};
  }

  @media screen and (min-width: ${theme.breakpoints.s}) {
    right: 0;
  }

  @media screen and (max-width: ${theme.breakpoints.s}) {
    justify-content: space-around;

    width: 95%;
    margin: 0 2.5% ${theme.spacing.m};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  @media screen and (max-width: ${theme.breakpoints.s}) {
    display: none;
  }
`;

const BannerContent = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;

  padding: ${theme.spacing.m};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: ${theme.spacing.m};
`;

const AllDoneWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

interface FormState {
  attributionSources: AttributionSource[];
  otherReason: string;
}

const initialFormState = {
  attributionSources: [],
  otherReason: ""
};

export const SurveyBanner = () => {
  const features = useSelector((state: State) => state.features.flags);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const options = useMemo(() => shuffle(surveyFormOptions), []);

  const selectCookieValue = useCallback((value: any) => {
    const data = value.split(";");
    return {
      isDismissed: data[0]
    };
  }, []);

  const [cookie, setCookie] = useCookie("survey_banner", {
    persistLocalStorage: true,
    select: selectCookieValue,
    initialValue: "false"
  });

  const { formErrors, formValues, formFieldProps, handleSubmit } =
    useForm<FormState>({
      initialValues: initialFormState,
      validate: values => ({
        otherReason:
          values.attributionSources.includes(AttributionSource.other) &&
          !values.otherReason.length
            ? "Please tell us how you heard about us"
            : ""
      }),
      onSubmitValid: ({ attributionSources, otherReason }) => {
        trackEvent("hdyhau_survey_complete", {
          sources: attributionSources.join("::"),
          comment: otherReason
        });

        setHasSubmitted(true);
      }
    });

  const { attributionSources } = formValues;
  const { otherReason: otherReasonError } = formErrors;

  const {
    onChange: handleAttributionSourcesChange,
    ...attributionSourcesFieldProps
  } = formFieldProps("attributionSources");

  const { isShown: intercomIsShown } = useIntercom();

  const isEnabled =
    !!features &&
    !!features.find(
      feature =>
        feature.name === "attribution_questionnaire" && feature.isEnabled
    );

  const dismissed = !!cookie && cookie.isDismissed === "true";

  /**
   * Don't render anything _when_:
   * 1. there is no cookie at all, this mitigates a 'flicker' before the initial value for the cookie is set
   * 2. when the banner has been dismissed/ completed previously, based on the cookie value if it is present
   * 3. while the Intercom chat modal is shown
   * 4. if the feature flag is not enabled, we only want to show this to new sign-ups
   */
  if (!cookie || dismissed || intercomIsShown || !isEnabled) {
    return null;
  }

  let content;
  if (hasSubmitted) {
    content = (
      <AllDoneWrapper>
        <div>
          <Heading>All done</Heading>
          <Text>Thanks for letting us know</Text>
        </div>
        <Illustration src="small-positive" />
      </AllDoneWrapper>
    );
  } else {
    content = (
      <>
        <Form onSubmit={handleSubmit}>
          <OptionsGroup
            label="How did you hear about us?"
            options={[
              ...options,
              {
                label: "Other (please tell us how)",
                value: AttributionSource.other
              }
            ]}
            selectionMode="multi"
            onChange={(event, value, checked) => {
              if (checked) {
                handleAttributionSourcesChange(event, [
                  ...attributionSources,
                  value
                ] as AttributionSource[]);
              } else {
                const newValues = attributionSources.filter(v => v !== value);
                handleAttributionSourcesChange(event, newValues);
              }
            }}
            {...attributionSourcesFieldProps}
            labelHidden
          />

          {attributionSources.includes(AttributionSource.other) && (
            <TextInput
              margin="m 0 m 0"
              label="How did you hear about us?"
              as="textarea"
              {...formFieldProps("otherReason", {
                validate: value =>
                  attributionSources.includes(AttributionSource.other) &&
                  !value.length
                    ? "Please tell us how you heard about us"
                    : ""
              })}
              errorMessage={otherReasonError}
            />
          )}

          <Divider />

          <ButtonWrapper>
            <Button
              onClick={() => setCookie("survey_banner", "true")}
              label="Don't ask again"
              width="min-content"
              variant="secondary"
            />
            <Button shouldSubmit label="Submit" width="min-content" />
          </ButtonWrapper>
        </Form>
      </>
    );
  }

  return (
    <>
      {!isModalOpen && (
        <BannerWrapper onClick={() => setIsModalOpen(true)}>
          <BannerContent>
            <Icon type={HelpOutlineIcon} color="white" margin="0 m 0 0" />
            How did you hear about LloydsDirect?
            <IconWrapper>
              <Icon type={ChevronRightIcon} color="white" margin="0 0 0 m" />
            </IconWrapper>
          </BannerContent>
        </BannerWrapper>
      )}
      <Modal
        isOpen={isModalOpen}
        title="How did you hear about LloydsDirect?"
        isTitleVisible={true}
        onClose={() => {
          if (hasSubmitted) {
            setCookie("survey_banner", "true");
          } else {
            setIsModalOpen(false);
          }
        }}
      >
        {content}
      </Modal>
    </>
  );
};
