import * as React from "react";
import styled from "styled-components";

const Wrap = styled.div`
  display: contents;
`;

type Props = {
  children: React.ReactNode;
};

export const Sensitive: React.FunctionComponent<Props> = props => {
  return <Wrap className="fs-exclude">{props.children}</Wrap>;
};
