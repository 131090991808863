import React, { createContext, useContext, useState, useEffect } from "react";
import {
  resetIntercom,
  rebootIntercom,
  showIntercom,
  updateIntercom,
  updateUser,
  intercomReady,
  onHideIntercom,
  onShowIntercom
} from "../intercom";

export interface IntercomContextProps {
  shutdown: typeof resetIntercom;
  reboot: typeof rebootIntercom;
  show: typeof showIntercom;
  updateIntercom: typeof updateIntercom;
  updateUser: typeof updateUser;
  isShown: boolean;
}

const intercomContextDefaultProps = {
  shutdown: resetIntercom,
  reboot: rebootIntercom,
  show: showIntercom,
  updateIntercom: updateIntercom,
  updateUser: updateUser,
  isShown: false
};

const IntercomContext = createContext<IntercomContextProps>(
  intercomContextDefaultProps
);

interface IntercomProviderProps {
  children: React.ReactNode;
}

export const IntercomProvider = ({ children }: IntercomProviderProps) => {
  const [isShown, setIsShown] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (intercomReady()) {
      setIsReady(true);

      onShowIntercom(() => {
        setIsShown(true);
      });

      onHideIntercom(() => {
        setIsShown(false);
      });
    }
  }, [isReady]);

  return (
    <IntercomContext.Provider
      value={{ ...intercomContextDefaultProps, isShown }}
    >
      {children}
    </IntercomContext.Provider>
  );
};

export const useIntercom = (): IntercomContextProps => {
  const ctx = useContext(IntercomContext);

  if (!ctx) {
    throw Error("Not inside `IntercomProvider`!");
  }

  return ctx;
};

export default useIntercom;
