import { Button, Link } from "@echo-health/design-system";
import { ButtonProps } from "@echo-health/design-system/default/components/Button";
import styled, { css } from "styled-components";
import { LinkButton } from "./LinkButton";

export const P2UButton = styled(Button)<ButtonProps>`
  background-color: #005b8f;
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  &:hover,
  &:active,
  &:focus {
    filter: none;
    background-color: #004e7b !important;
  }
  &:focus {
    outline: 3px solid #c9ebff;
  }
  ${p =>
    p.variant === "text" &&
    css`
      background: transparent;
      color: ${p.theme.muted.base};
      font-weight: 500;
      &:hover,
      &:active,
      &:focus {
        filter: brightness(50%);
        background-color: transparent !important;
      }
    `}
  ${p =>
    p.isLoading &&
    css`
      background: ${p.theme.muted.subdued};
      color: ${p.theme.disabled.base};
      &:hover,
      &:active,
      &:focus {
        filter: brightness(100%);
        border-color: ${p.theme.muted.subdued};
        background: ${p.theme.muted.subdued};
        cursor: not-allowed;
      }
      &:focus:not(:hover) {
        box-shadow: none;
      }
    `}
  ${p =>
    p.isDisabled &&
    css`
      background: ${p.theme.muted.subdued};
      color: ${p.theme.disabled.base};
      &:hover,
      &:active,
      &:focus {
        filter: brightness(100%);
        border-color: ${p.theme.muted.subdued};
        background: ${p.theme.muted.subdued} !important;
        cursor: not-allowed;
      }
      &:focus:not(:hover) {
        box-shadow: none;
      }
    `}
`;

export const P2ULinkButton = styled(LinkButton)`
  background-color: #005b8f;
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  &:hover,
  &:active,
  &:focus {
    filter: none;
    background-color: #004e7b !important;
  }
  &:focus {
    outline: 3px solid #c9ebff;
  }
`;

export const P2ULink = styled(Link)`
  color: #005b8f;
`;
