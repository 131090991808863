import { Reducer } from "redux";
import { createStandardAction } from "typesafe-actions";
import { ActionsUnion } from "./types";
import { patientPrescriptions_patientPrescriptions } from "../graphql/types/patientPrescriptions";

export interface PrescriptionsState {
  prescriptions: patientPrescriptions_patientPrescriptions[];
}

export const prescriptionsActions = {
  setPrescriptions: createStandardAction("PRESCRIPTIONS/SET_PRESCRIPTIONS")<{
    prescriptions: patientPrescriptions_patientPrescriptions[];
  }>(),
  clear: createStandardAction("PRESCRIPTIONS/CLEAR")()
};

export const prescriptionsInitialState: PrescriptionsState = {
  prescriptions: []
};

export const prescriptionsReducer: Reducer<PrescriptionsState, ActionsUnion> = (
  state = prescriptionsInitialState,
  action
) => {
  switch (action.type) {
    case "PRESCRIPTIONS/SET_PRESCRIPTIONS":
      return {
        ...state,
        prescriptions: action.payload.prescriptions
      };
    case "PRESCRIPTIONS/CLEAR":
      return {
        ...state,
        prescriptions: []
      };
  }

  return state;
};
