import Cookies from "js-cookie";
import { useState, useEffect, useCallback } from "react";
import { hasLocalStorage } from "../utils/features";

interface Config<SelectedValue> {
  initialValue?: string;
  persistLocalStorage?: boolean;
  select?: (value: string) => SelectedValue;
  skip?: boolean;
}

// Example usage:
// const selectCookieValue = useCallback(value => {
//   const data = value.split(";");
//   return { referralCodeId: data[0], referralName: data[1], referralValue: data[2] }
// }, [])

// const [referralCode] = useCookie("ref_meta", {
//   persistLocalStorage: true,
//   select: selectCookieValue
// });

function useCookie<SelectedValue = string>(
  name: string,
  config: Config<SelectedValue> = { persistLocalStorage: false, skip: false }
) {
  const { initialValue, persistLocalStorage, skip, select } = config;

  const [value, setValue] = useState<SelectedValue | undefined>(undefined);

  const setCookie = useCallback(
    (name: string, value: string) => {
      Cookies.set(name, value);

      // Try to pop the data in localstorage for safer keeping
      if (hasLocalStorage() && persistLocalStorage) {
        localStorage.setItem(name, value);
      }

      if (select) {
        const result = select(value);
        setValue(result);
      } else {
        setValue(value as unknown as SelectedValue);
      }
    },
    [select, persistLocalStorage]
  );

  useEffect(() => {
    if (skip) return;

    let c = Cookies.get(name);
    if (c === undefined) {
      // Prioritise the cookie but if nothing check in local storage
      if (hasLocalStorage() && persistLocalStorage) {
        const ls = localStorage.getItem(name);
        if (ls) {
          c = ls;
        }
      }
    }
    try {
      if (!c) {
        if (initialValue) {
          setCookie(name, initialValue);
        }
        return;
      }

      setCookie(name, c);
    } catch (err) {
      // fail silently
    }
  }, [initialValue, skip, name, persistLocalStorage, setCookie]);

  return [value, setCookie] as const;
}

export default useCookie;
