import { createStandardAction } from "typesafe-actions";
import { Reducer } from "redux";
import { ActionsUnion } from "./types";
import { Gender } from "../graphql/types/global";

export interface SetDetailsPayload {
  readonly token: string;
  readonly partnerName: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly password: string;
  readonly email: string;
  readonly birthDay: number;
  readonly birthMonth: number;
  readonly birthYear: number;
  readonly phone: string;
  readonly gender: Gender | null;
}

export interface SetAddressPayload {
  readonly addressLine1: string;
  readonly addressLine2: string;
  readonly addressCity: string;
  readonly addressPostcode: string;
}

export interface PartnerState {
  token: string;
  partnerName: string;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  addressCity: string;
  addressPostcode: string;
  birthDay: number;
  birthMonth: number;
  birthYear: number;
  phone: string;
  gender: Gender | null;

  [propName: string]: string | boolean | number | Gender | null;
}

export const partnerInitialState: PartnerState = {
  token: "",
  partnerName: "",
  firstName: "",
  lastName: "",
  password: "",
  email: "",
  addressLine1: "",
  addressLine2: "",
  addressCity: "",
  addressPostcode: "",
  phone: "",
  birthDay: 0,
  birthMonth: 0,
  birthYear: 0,
  gender: null
};

export const partnerActions = {
  setConnectDetails: createStandardAction(
    "CONNECT/SET_DETAILS"
  )<SetDetailsPayload>(),
  setAddress: createStandardAction("CONNECT/SET_ADDRESS")<SetAddressPayload>(),
  clearConnectDetails: createStandardAction("CONNECT/CLEAR")()
};

export const partnerReducer: Reducer<PartnerState, ActionsUnion> = (
  state = partnerInitialState,
  action
): PartnerState => {
  switch (action.type) {
    case "CONNECT/CLEAR": {
      return {
        ...partnerInitialState
      };
    }
    case "CONNECT/SET_DETAILS": {
      return {
        ...state,
        ...action.payload
      };
    }
    case "CONNECT/SET_ADDRESS": {
      return {
        ...state,
        ...action.payload
      };
    }
  }
  return state;
};
