import {
  Banner,
  Button,
  Heading,
  Stack,
  Text
} from "@echo-health/design-system";
import React from "react";
import { useContext } from "react";
import { MigrationContext } from "../context/MigrationContext";
import {
  AccountMigrationStatus,
  MigrationActivationStatus
} from "~/graphql/types/global";
import { P2ULinkButton } from "~/design-system/components/P2UButton";
import styled from "styled-components";
import { Illustration } from "~/design-system/components/Illustration";
import { showIntercom } from "~/intercom";

export const MigrationGlobalBanner = () => {
  const { migrationStatus, activationLink, activationStatus } =
    useContext(MigrationContext);

  if (
    migrationStatus ===
      AccountMigrationStatus.ACCOUNT_MIGRATION_STATUS_FAILED ||
    activationStatus ===
      MigrationActivationStatus.ACCOUNT_MIGRATION_ACTIVATION_STATUS_REGISTRATION_FAILURE
  ) {
    return <FailedBanner />;
  }

  if (
    activationStatus ===
      MigrationActivationStatus.ACCOUNT_MIGRATION_ACTIVATION_STATUS_PENDING_ACTIVATION &&
    activationLink
  ) {
    return (
      <P2UBanner
        mb="l"
        variant="success"
        withIcon={false}
        alignItems={"flex-start"}
        flexDirection={"column"}
      >
        <Stack gap="xs">
          <StyledIllustration src="P2UxLD-small" alt="Pharmacy2U logo" />

          <Heading size="l" typeface="display" mt="xs">
            Your new Pharmacy2U account is ready to activate
          </Heading>

          <Text>
            We have transferred your details to a new Pharmacy2U account where
            you can now manage your prescriptions and orders.
          </Text>

          <Text mb="m">
            Past orders will remain accessible on your LloydsDirect account. To
            place new orders, activate your Pharmacy2U account.
          </Text>

          <P2ULinkButton
            shouldOpenInNewTab
            linkTo={activationLink}
            label="Activate your Pharmacy2U account"
          />
        </Stack>
      </P2UBanner>
    );
  }

  if (
    activationStatus ===
    MigrationActivationStatus.ACCOUNT_MIGRATION_ACTIVATION_STATUS_ACTIVATED
  ) {
    return (
      <P2UBanner
        mb="l"
        variant="success"
        withIcon={false}
        alignItems={"flex-start"}
        flexDirection={"column"}
      >
        <Stack gap="xs">
          <StyledIllustration src="P2UxLD-small" alt="Pharmacy2U logo" />
          <Heading size="l" typeface="display" mt="xs">
            We have transferred your account to Pharmacy2U
          </Heading>
          <Text>
            We have transferred your details to a new Pharmacy2U account where
            you can now manage your prescriptions and orders.
          </Text>
          <Text mb="m">
            Your medicine list may not be visible on your new account until the
            next time you order. Your order history will remain accessible here.
          </Text>
          <P2ULinkButton
            shouldOpenInNewTab
            linkTo="https://www.pharmacy2u.co.uk/account"
            label="Access your Pharmacy2U account"
          />
        </Stack>
      </P2UBanner>
    );
  }

  // Completed (i.e. activated or pending_activation states) should have been caught
  // above, here we want to show a catch-all if our internal status is completed, but for
  // some reason we have no activation link or status from the P2U end
  if (
    migrationStatus ===
      AccountMigrationStatus.ACCOUNT_MIGRATION_STATUS_PENDING ||
    migrationStatus ===
      AccountMigrationStatus.ACCOUNT_MIGRATION_STATUS_COMPLETED
  ) {
    return (
      <P2UBanner
        mb="l"
        withIcon={false}
        alignItems={"flex-start"}
        flexDirection={"column"}
        variant="warning"
        action={
          migrationStatus ===
            AccountMigrationStatus.ACCOUNT_MIGRATION_STATUS_COMPLETED && (
            <Button
              type="success"
              label="Contact our support team"
              onClick={event => {
                showIntercom();
                event.preventDefault();
              }}
            />
          )
        }
      >
        <Stack gap="s" mb="xs">
          <Heading typeface="display" size="l">
            Your new Pharmacy2U account is almost ready
          </Heading>
          <Text>
            You should soon receive an email from Pharmacy2U to activate your
            new account. You do not need to register with Pharmacy2U, we will
            take care of everything for you.
          </Text>
        </Stack>
      </P2UBanner>
    );
  }

  if (
    migrationStatus ===
    AccountMigrationStatus.ACCOUNT_MIGRATION_STATUS_CONSENTED
  ) {
    return (
      <P2UBanner
        mb="l"
        withIcon={false}
        alignItems={"flex-start"}
        flexDirection={"column"}
        variant="warning"
      >
        <Stack gap="s" mb="xs">
          <Heading typeface="display" size="l">
            You've agreed to change your nominated pharmacy to Pharmacy2U the
            next time you order.
          </Heading>
          <Text>
            You can continue to use LloydsDirect as normal until you place your
            next order. Once you do, we will set up your new Pharmacy2U account
            and send you a welcome email when it's ready.
          </Text>
        </Stack>
      </P2UBanner>
    );
  }

  return <></>;
};

const FailedBanner = () => {
  return (
    <Banner
      mb="l"
      variant="warning"
      withIcon={false}
      alignItems={"flex-start"}
      flexDirection={"column"}
      action={
        <Button
          type="success"
          label="Contact our support team"
          onClick={event => {
            showIntercom();
            event.preventDefault();
          }}
        />
      }
    >
      <Stack gap="s" mb="xs">
        <Heading size="l" typeface="display">
          We were unable to set set up your new Pharmacy2U account
        </Heading>
        <Text>
          Your order will be processed as normal by LloydsDirect while we look
          into the issue. Your nominated pharmacy has not yet changed.
        </Text>
        <Text>
          Please check back later or contact us if you have any questions.
        </Text>
      </Stack>
    </Banner>
  );
};

const P2UBanner = styled(Banner)`
  border-color: #005b8f;
`;

const StyledIllustration = styled(Illustration)`
  min-width: 60%;
`;
