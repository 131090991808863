interface BaseRouteOptions {
  path: string;
  breadcrumb?: string;
}
export const routes: BaseRouteOptions[] = [
  /**
   * Account Routes
   */
  {
    path: "/account",
    breadcrumb: "Account"
  },
  {
    path: "/account/wallet",
    breadcrumb: "Payment methods"
  },
  {
    path: "/account/wallet/add",
    breadcrumb: "Add"
  },
  {
    path: "/account/address",
    breadcrumb: "Delivery addresses"
  },
  {
    path: "/account/address/add",
    breadcrumb: "Add"
  },
  {
    path: "/account/address/pharmacy-branch/add",
    breadcrumb: "Find a LloydsPharmacy"
  },
  {
    path: "/account/details/confirm-phone",
    breadcrumb: "Confirm phone number"
  },
  {
    path: "/account/details/update-phone",
    breadcrumb: "Enter phone number"
  },
  {
    path: "/account/details/phone-not-confirmed",
    breadcrumb: "Confirm phone number"
  },
  {
    path: "/account/details",
    breadcrumb: "Contact details"
  },
  {
    path: "/account/details/email",
    breadcrumb: "Change email"
  },
  {
    path: "/account/details/confirm-email-actions",
    breadcrumb: "Confirm email address"
  },
  {
    path: "/account/patient/:patientId/connect-gp",
    breadcrumb: "Connect to your GP surgery"
  },
  {
    path: "/patient/:patientId/connect-gp",
    breadcrumb: "Connect to your GP surgery"
  },
  {
    path: "/patient/:patientId/confirm-prescription",
    breadcrumb: "Confirm prescription"
  },
  {
    path: "/patient/:patientId/confirm-prescription/review",
    breadcrumb: "Review"
  },
  {
    path: "/patient/:patientId/confirm-prescription/confirmation",
    breadcrumb: "Confirmation"
  },
  {
    path: "/patient/:patientId/confirm-prescription/payment-method",
    breadcrumb: "Payment method"
  },
  {
    path: "/patient/:patientId/confirm-prescription/payment-method/add",
    breadcrumb: "Add"
  },
  {
    path: "/patient/:patientId/confirm-prescription/delivery",
    breadcrumb: "Delivery"
  },
  {
    path: "/patient/:patientId/confirm-prescription/address",
    breadcrumb: "Address"
  },
  {
    path: "/patient/:patientId/confirm-prescription/address/add",
    breadcrumb: "Add"
  },
  {
    path: "/patient/:patientId/confirm-prescription/address/update",
    breadcrumb: "Update delivery address"
  },
  {
    path: "/patient/:patientId/confirm-prescription/local-collect/add",
    breadcrumb: "Find a collection point"
  },
  {
    path: "/patient/:patientId/confirm-prescription/pharmacy-branch/add",
    breadcrumb: "Find a LloydsPharmacy"
  },
  {
    path: "/patient/:patientId/confirm-prescription/exemption",
    breadcrumb: "Select Exemption"
  },
  {
    path: "/patient/:patientId/confirm-prescription/exemption/add",
    breadcrumb: "Add"
  },
  {
    path: "/patient/:patientId/confirm-prescription/exemption/add/confirm",
    breadcrumb: "Confirm"
  },

  /**
   * Medications Routes
   */
  {
    path: "/patient/:patientId/medication/",
    breadcrumb: "Medicine"
  },
  {
    path: "/patient/:patientId/medication/search/",
    breadcrumb: "Search"
  },
  {
    path: "/patient/:patientId/medication/search/:medicationId/remaining",
    breadcrumb: "Remaining"
  },
  {
    path: "/patient/:patientId/medication/search/:medicationId/date",
    breadcrumb: "Date"
  },
  {
    path: "/patient/:patientId/medication/search/:medicationId/summary",
    breadcrumb: "Summary"
  },
  {
    path: "/patient/:patientId/medication/search/:medicationId/order",
    breadcrumb: "Order"
  },
  {
    path: "/patient/:patientId/medication/change-due-date",
    breadcrumb: "Remaining Supply"
  },
  {
    path: "/patient/:patientId/requests",
    breadcrumb: "Orders"
  },
  {
    path: "/patient/:patientId/requests/history/:orderId",
    breadcrumb: "Order details"
  },

  /**
   * Cancel Orders
   */
  {
    path: "/cancel-requests/:patientId",
    breadcrumb: "Cancel orders"
  },
  {
    path: "/cancel-requests/:patientId/review",
    breadcrumb: "Review"
  },
  {
    path: "/cancel-requests/:patientId/confirmation",
    breadcrumb: "Confirmation"
  },

  /**
   * Set delivery preference (partials)
   */
  {
    path: "/set-delivery-preference/:patientId",
    breadcrumb: "Send available items"
  },
  {
    path: "/set-delivery-preference/:patientId/review",
    breadcrumb: "Review"
  },
  {
    path: "/set-delivery-preference/:patientId/confirmation",
    breadcrumb: "Confirmation"
  },

  /**
   * Batch prescriptions (RDS)
   */
  {
    path: "/patient/:patientId/medication/request-batch",
    breadcrumb: "Order next batch"
  },
  {
    path: "/patient/:patientId/medication/request-batch/health-check-in",
    breadcrumb: "Health check-in"
  },
  {
    path: "/patient/:patientId/medication/request-batch/delivery",
    breadcrumb: "Choose delivery method"
  },
  {
    path: "/patient/:patientId/medication/request-batch/address",
    breadcrumb: "Address"
  },
  {
    path: "/patient/:patientId/medication/request-batch/address/add",
    breadcrumb: "Add"
  },
  {
    path: "/patient/:patientId/medication/request-batch/address/update",
    breadcrumb: "Update delivery address"
  },
  {
    path: "/patient/:patientId/medication/request-batch/local-collect/add",
    breadcrumb: "Find a collection point"
  },
  {
    path: "/patient/:patientId/medication/request-batch/pharmacy-branch/add",
    breadcrumb: "Find a LloydsPharmacy"
  },
  {
    path: "/patient/:patientId/medication/request-batch/review",
    breadcrumb: "Confirm order"
  },
  {
    path: "/patient/:patientId/medication/request-batch/exemption",
    breadcrumb: "Exemptions"
  },
  {
    path: "/patient/:patientId/medication/request-batch/exemption/add",
    breadcrumb: "Add"
  },
  {
    path: "/patient/:patientId/medication/request-batch/exemption/add/confirm",
    breadcrumb: "Confirm"
  },
  {
    path: "/patient/:patientId/medication/request-batch/confirmation",
    breadcrumb: "Confirmation"
  },

  /**
   * Return Prescriptions
   */
  {
    path: "/help/:patientId/return-prescriptions",
    breadcrumb: "Request barcode"
  },
  {
    path: "/help/:patientId/return-prescriptions/prescriptions",
    breadcrumb: "Select prescriptions"
  },
  {
    path: "/help/:patientId/return-prescriptions/review",
    breadcrumb: "Review"
  },
  {
    path: "/help/:patientId/return-prescriptions/confirmation",
    breadcrumb: "Confirmed"
  },
  /**
   * Return in Ask patient
   */
  {
    path: "/patient/:patientId/confirm-prescription/return-prescriptions",
    breadcrumb: "Request barcode"
  },
  {
    path: "/patient/:patientId/confirm-prescription/return-prescriptions/prescriptions",
    breadcrumb: "Select prescriptions"
  },
  {
    path: "/patient/:patientId/confirm-prescription/return-prescriptions/review",
    breadcrumb: "Review"
  },
  {
    path: "/patient/:patientId/confirm-prescription/return-prescriptions/confirmation",
    breadcrumb: "Confirmed"
  },

  /**
   * Request prescriptions
   */
  {
    path: "/patient/:patientId/medication/request-prescription",
    breadcrumb: "Order medicine"
  },
  {
    path: "/patient/:patientId/medication/request-prescription/review",
    breadcrumb: "Review"
  },
  {
    path: "/patient/:patientId/medication/request-prescription/confirmation",
    breadcrumb: "Confirmed"
  },
  {
    path: "/patient/:patientId/medication/request-prescription/confirmation/add-nok",
    breadcrumb: "Add NOK"
  },
  {
    path: "/patient/:patientId/medication/request-prescription/delivery",
    breadcrumb: "Delivery"
  },

  {
    path: "/patient/:patientId/medication/request-prescription/address",
    breadcrumb: "Delivery address"
  },
  {
    path: "/patient/:patientId/medication/request-prescription/address/add",
    breadcrumb: "Add"
  },
  {
    path: "/patient/:patientId/medication/request-prescription/address/update",
    breadcrumb: "Update delivery address"
  },
  {
    path: "/patient/:patientId/medication/request-prescription/local-collect/add",
    breadcrumb: "Find a collection point"
  },
  {
    path: "/patient/:patientId/medication/request-prescription/pharmacy-branch/add",
    breadcrumb: "Find a LloydsPharmacy"
  },
  {
    path: "/patient/:patientId/medication/request-prescription/exemption",
    breadcrumb: "Exemptions"
  },
  {
    path: "/patient/:patientId/medication/request-prescription/exemption/add",
    breadcrumb: "Add"
  },
  {
    path: "/patient/:patientId/medication/request-prescription/exemption/add/confirm",
    breadcrumb: "Confirm"
  },
  {
    path: "/patient/:patientId/medication/request-prescription/payment-method",
    breadcrumb: "Select payment method"
  },
  {
    path: "/patient/:patientId/medication/request-prescription/payment-method/add",
    breadcrumb: "Add"
  },

  /**
   * Requests Routes
   */
  {
    path: "/patient/:patientId/requests/pay",
    breadcrumb: "Payment"
  },
  {
    path: "/patient/:patientId/requests/:flow/payment-method",
    breadcrumb: "Select payment method"
  },
  {
    path: "/patient/:patientId/requests/:flow/payment-method/add",
    breadcrumb: "Add"
  },
  {
    path: "/patient/:patientId/requests/pay/confirmation",
    breadcrumb: "Confirmed"
  },
  {
    path: "/patient/:patientId/requests/premium-delivery",
    breadcrumb: "Delivery options"
  },
  {
    path: "/patient/:patientId/requests/premium-delivery/confirmation",
    breadcrumb: "Confirmed"
  },
  {
    path: "/patient/:patientId/requests/premium-delivery/upgraded",
    breadcrumb: "Upgraded"
  },

  /**
   * Pill review
   */
  {
    path: "/patient/:patientId/pill-review/:patientMedicationId",
    breadcrumb: "Book a Pill Review"
  },
  {
    path: "/patient/:patientId/pill-review/:patientMedicationId/eligibility",
    breadcrumb: "Eligibility"
  },
  {
    path: "/patient/:patientId/pill-review/:patientMedicationId/booking",
    breadcrumb: "Booking"
  },
  {
    path: "/patient/:patientId/pill-review/:patientMedicationId/ineligible",
    breadcrumb: "Ineligible"
  },
  {
    path: "/patient/:patientId/pill-review/:patientMedicationId/confirmation",
    breadcrumb: "Confirmation"
  }
];
