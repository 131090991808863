import * as React from "react";

import { ThemeProvider, createGlobalStyle } from "styled-components";
import { theme } from "@echo-health/design-system/";
import { ToastProvider } from "../design-system/components/Toaster";
import { ErrorModalProvider } from "../components/ErrorModal";
import { IntercomProvider } from "../components/IntercomProvider";

const ResetStyles = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  html {
    overflow-x: hidden;
  }

  body {
    font-size: 16px;
    font-family: ${p => p.theme.typography.bodyFamily};
    letter-spacing: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  button, input[type="submit"], input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  /* Reset heading sizes by default */
  h1, h2, h3, h4, h5, h6 {
    font-size: 1rem;
  }

  /* Reset for fieldset browser styles: https://thatemil.com/blog/2015/01/03/reset-your-fieldset/ */
  fieldset {
    border: 0;
    padding: 0.01em 0 0 0;
    margin: 0;
    min-width: 0;
  }
  body:not(:-moz-handler-blocked) fieldset {
    display: table-cell;
  }

`;

interface AppWrapperProps {
  children: React.ReactNode;
}

const backwardsCompatTheme = {
  ...theme,
  nhslogincolor: {
    blue: "#005EB8",
    highlight: "#0973dc"
  }
};

const AppWrapper = (props: AppWrapperProps) => (
  <ThemeProvider theme={backwardsCompatTheme}>
    <ToastProvider>
      <ErrorModalProvider>
        <IntercomProvider>
          <>
            <ResetStyles />
            {props.children}
          </>
        </IntercomProvider>
      </ErrorModalProvider>
    </ToastProvider>
  </ThemeProvider>
);

export default AppWrapper;
