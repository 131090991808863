import { useEffect, useState } from "react";

type FormErrors<T> = { [K in keyof T]?: string };

type FormTouched<T> = { [K in keyof T]?: boolean };

type SubmitEvent =
  | React.FormEvent
  | React.SyntheticEvent<HTMLAnchorElement | HTMLButtonElement>;

type InputElement = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

type SubmitHandler<T> = (data: T, event?: SubmitEvent) => void | Promise<void>;

type SubmitErrorHandler<T> = (
  errors: FormErrors<T>,
  event?: SubmitEvent
) => void | Promise<void>;

interface FormConfig<T> {
  /**
   * Initial field values of the form which populate formValues returned by the hook. These
   * must confirm to generic type T supplied for the hook to describe the form state.
   */
  initialValues: T;

  /**
   * If supplied, this function is called in the handleSubmit function returned
   * by the hook when the form has no errors. You can pass an async function for asynchronous work.
   */
  onSubmitValid?: SubmitHandler<T>;

  /**
   * If supplied, this function is called in the handleSubmit function returned
   * by the hook when the form has errors. You can pass an async function for asynchronous work.
   */
  onSubmitInvalid?: SubmitErrorHandler<T>;

  /**
   * Validate the entire form. This function is called with all of the forms values when the form is
   * submitted and it should return an object conforming to FormErrors<T> where there is a key
   * for each form value which can be an error string, empty string or undefined. Empty string or undefined
   * is treated as no error.
   */
  validate?: (values: T) => FormErrors<T>;

  // Provides the ability to opt out of the default behaviour of validating the
  // form fields on blur and only doing so on submission of the form.
  validateOnBlur?: boolean;
}

interface FieldConfig<T, V, E, InitialValue> {
  /**
   * An optional handler to hook into the change event for the given input.
   *
   * The formFieldProps function handles updating the formValues state and validating the input value on change
   * of the given input, so this is only needed for any other additional work. If supplied, it is called
   * *after* the new value has been set in the formValues state.
   */
  onChange?: (event: React.FormEvent<E>, value: V | InitialValue) => void;

  /**
   * An optional handler to hook into the blur event for the given input.
   *
   * The formFieldProps function handles validating the input value on blur of the given input, so this is only
   * needed for any other additional work. If supplied, it is called *after* the input has been set to touched
   * in the formTouched state.
   */
  onBlur?: (event: React.FocusEvent<E>) => void;

  /**
   * Validate the value for the specific field. This function is called with the value for the given field
   * as well as with all of the forms values and it should return an error string or undefined. Note: if you
   * return an empty string it will be treated as an error on submit.
   *
   * If supplied, validate is called on change and on blur of the given input.
   */
  validate?: (value: V | InitialValue, allValues?: T) => string | undefined;
}

const hasErrors = <T>(errors: FormErrors<T>) => {
  for (const property in errors) {
    if (errors[property]) {
      return true;
    }
  }

  return false;
};

export function useForm<T>({
  validateOnBlur = true,
  ...formConfig
}: FormConfig<T>) {
  const { onSubmitValid, onSubmitInvalid, initialValues, validate } =
    formConfig;
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState<FormErrors<T>>({});
  const [formTouched, setFormTouched] = useState<FormTouched<T>>({});
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasUpdated, setHasUpdated] = useState(false);

  useEffect(() => {
    if (!hasUpdated && formValues !== initialValues) {
      setFormValues(initialValues);
      setHasUpdated(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const formFieldProps = <K extends keyof T, E extends InputElement>(
    /**
     * The name of the input, this should be one of the keys in the formValues state T.
     *
     * This is used to access the value for the given input from the formValues state and is
     * also spread in as the `name` prop.
     */
    name: K,
    config: FieldConfig<
      T,
      T[K],
      E,
      (typeof formConfig.initialValues)[typeof name]
    > = {}
  ) => ({
    onChange: (
      event: React.FormEvent<E>,
      value:
        | T[K]
        | (typeof formConfig.initialValues)[typeof name] = initialValues[name]
    ) => {
      const newState = { ...formValues, [name]: value };

      setFormValues(prev => ({ ...prev, [name]: value }));

      if (config.onChange) {
        config.onChange(event, value);
      }

      // Once the input has been blurred (touched) or the form has been submitted i.e.
      // when we initially validate, continuously validate afterward so that the user
      // gets live feedback of errors on change
      const shouldValidate = formTouched[name] || hasSubmitted;

      if (shouldValidate && config.validate) {
        const fieldError = config.validate(value, newState);

        setFormErrors(prev => ({ ...prev, [name]: fieldError }));
      }
    },
    onBlur: (event: React.FocusEvent<E>) => {
      setFormTouched(prev => ({ ...prev, [name]: true }));

      if (config.onBlur) {
        config.onBlur(event);
      }

      if (config.validate && validateOnBlur) {
        const fieldError = config.validate(
          event.currentTarget.value as unknown as T[K],
          formValues
        );

        setFormErrors(prev => ({ ...prev, [name]: fieldError }));
      }
    },
    value: formValues[name],
    name
  });

  const handleSubmit = async (event: SubmitEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    setHasSubmitted(true);

    // State change won't have propogated by the time we assert if the form has errors below,
    // so we need to define this variable containing the latest errors and assert based on it
    // instead of formErrors itself
    let newFormErrors = formErrors;
    if (validate) {
      newFormErrors = validate(formValues);

      setFormErrors(newFormErrors);
    }

    if (!hasErrors(newFormErrors)) {
      if (onSubmitValid) {
        await onSubmitValid(formValues, event);
      }
    } else {
      if (onSubmitInvalid) {
        await onSubmitInvalid(formErrors, event);
      }
    }
  };

  return { formValues, formErrors, formTouched, formFieldProps, handleSubmit };
}

export default useForm;
