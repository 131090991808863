import { ApolloError } from "@apollo/client";
import { isAuthError } from "./auth";

export function getErrorMessage(error?: Error | ApolloError | string) {
  let isNetworkError = false;
  let isGraphQLAuthError = false;
  let message =
    "Please try again or get in touch so we can help you if the issue persists.";

  if (typeof error == "string") {
    message = error;
  } else if (error instanceof ApolloError) {
    // Currently our graphql errors are displayable to the user. Until we improve
    // them we can't use anything from the back-end. That's why we are not using
    // error.message here and only handling network errors
    if (error.networkError) {
      isNetworkError = true;
      message =
        "It seems like there's an issue with your internet connection. Please double check you're online and that you can access the internet.";
    }

    if (error.graphQLErrors && isAuthError(error.graphQLErrors)) {
      message = "You have been logged out. Please log in again.";
      isGraphQLAuthError = true;
    }
  } else if (error) {
    message = error.message;
  }

  return {
    isNetworkError,
    message,
    isGraphQLAuthError
  };
}
