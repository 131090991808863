export type QueryParams = { [key: string]: string[] | undefined };

export function decode(s: string): QueryParams {
  // remove leading ? if present
  s = s.replace(/^\?/, "");

  if (!s) {
    return {};
  }

  return s.split("&").reduce((res: { [key: string]: string[] }, v: string) => {
    const parts = v.split("=");
    const key = decodeURIComponent((parts[0] || "").replace(/\+/g, " ")).trim();
    const value = decodeURIComponent(
      (parts[1] || "").replace(/\+/g, " ")
    ).trim();

    if (key === "" || value === "") {
      return res;
    }

    if (!Array.isArray(res[parts[0]])) {
      res[key] = [];
    }

    res[key].push(value);
    return res;
  }, {});
}

export function encode(query: QueryParams): string {
  const result = Object.keys(query)
    .reduce<string[]>((memo, key) => {
      const values = query[key];
      if (!values) {
        return memo;
      }

      for (const value of values) {
        if (value.trim() === "") {
          continue;
        }
        memo.push(`${key}=${encodeURIComponent(value)}`);
      }
      return memo;
    }, [])
    .join("&");

  return result ? `?${result}` : "";
}
