import { Flex, Icon } from "@echo-health/design-system";
import {
  AddIcon,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon
} from "@echo-health/icons-web";
import React, { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { trackEvent } from "~/analytics";
import { Avatar } from "~/design-system/components/Avatar";
import { VisuallyHidden } from "~/design-system/components/VisuallyHidden";
import theme from "~/styled/theme";
import { Text } from "~/design-system/components/Text";
import { LinkButton } from "~/design-system/components/LinkButton";
import { NavLink, useRouteMatch } from "react-router-dom";
import { useGetPatientTabsQuery } from "~/graphql/hooks/useGetPatientTabsQuery";
import { daysAgo, timestampSeconds } from "~/utils/date";
import segment from "~/segment";
import Divider from "./Divider";
import useSwitchPatient from "~/hooks/useSwitchPatient";
import { MigrationContext } from "~/migration/context/MigrationContext";
import { AccountMigrationStatus } from "~/graphql/types/global";

const navigationButtonBaseStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  padding: ${p => p.theme.spacing.m};
  transition: background-color 250ms ease-in-out, box-shadow 250ms ease-in-out;
  outline: none;
  width: 100%;

  &:hover {
    background-color: ${p => p.theme.color.gray10};
  }

  &:focus:not(:hover) {
    background-color: ${p => p.theme.color.blue10};
    box-shadow: ${p => `${p.theme.shadow.focusRing} ${p.theme.color.blue20}`};
  }

  @media screen and (min-width: ${theme.breakpoints.m}) {
    grid-template-columns: 24px 1fr max-content;
    padding: ${p => p.theme.spacing.xs};
    margin-left: ${p => `-${p.theme.spacing.xs}`};
    margin-right: ${p => `-${p.theme.spacing.xs}`};
    border-radius: ${p => p.theme.spacing.xl};
  }
`;

const PatientListToggle = styled.button<{
  isOpen: boolean;
  hasNok: boolean;
  isMobileSwitcher: boolean;
}>`
  ${navigationButtonBaseStyle};
  ${p =>
    p.isOpen &&
    p.hasNok &&
    css`
      background-color: ${p => p.theme.color.blue10};
    `}

  ${p =>
    p.isMobileSwitcher &&
    css`
      &:hover {
        background-color: ${p => p.theme.color.white};
      }
    `}
`;

const PatientLink = styled(NavLink)<{ isMobileSwitcher: boolean }>`
  ${navigationButtonBaseStyle};
  ${p =>
    p.isMobileSwitcher &&
    css`
      border-bottom: 1px solid ${p => p.theme.color.gray10};
    `}
`;

const NameText = styled(Text)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ItemText = styled(Text).attrs({ nonSensitive: true })`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Badge = styled.div`
  background: ${p => p.theme.color.purple60};
  color: ${p => p.theme.color.white};
  font-size: ${p => p.theme.typography.size.xs};
  font-weight: ${p => p.theme.typography.weight.bold};
  padding: ${p => p.theme.spacing.xxs} ${p => p.theme.spacing.xs};
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: ${p => p.theme.spacing.l};
  width: fit-content;
  margin-left: ${p => p.theme.spacing.xxs};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ToggleBadgeWrapper = styled.div`
  padding: 0 ${p => p.theme.spacing.s};
  display: flex;
  align-items: center;
  height: 100%;
  border-left: 1px solid ${p => p.theme.color.gray10};
`;

const LinkButtonWrapper = styled.div<{ isMobileSwitcher: boolean }>`
  padding: ${p => p.theme.spacing.s};

  @media screen and (min-width: ${theme.breakpoints.m}) {
    padding: 0;
  }
`;

export const PatientListSwitcher = ({
  isMobileSwitcher
}: {
  isMobileSwitcher?: boolean;
}) => {
  const [isPatientPickerExpanded, setIsPatientPickerExpanded] = useState(false);
  let hasNok: boolean = false;

  const { migrationStatus, nominatedWithP2U } = useContext(MigrationContext);

  useEffect(() => {
    if (!hasNok && !isMobileSwitcher) {
      setIsPatientPickerExpanded(true);
    }
  }, [hasNok, isMobileSwitcher]);

  const { params } = useRouteMatch<{ patientId: string }>();
  const patientId = params.patientId;

  const switchPatient = useSwitchPatient();

  const { data, error } = useGetPatientTabsQuery({
    variables: { updateTimeAfter: timestampSeconds(daysAgo(7)) }
  });

  if (error || !data || !data.account) {
    return null;
  }

  const { patients } = data.account;

  const accountHolder = patients.edges.find(i => i.node.isAccountHolder);

  const selectedPatient =
    patients.edges.find(i => i.node.id === patientId) || accountHolder;

  if (!selectedPatient) {
    return null;
  }

  const patientList = selectedPatient
    ? patients.edges.filter(i => i.node.id !== selectedPatient.node.id)
    : patients.edges;

  hasNok = patientList.length > 0;

  function onNavigate() {
    setIsPatientPickerExpanded(false);
  }

  const selectedPatientActiveOrdersTotal =
    selectedPatient.node.activeOrders.totalCount +
    selectedPatient.node.recentHistoryOrders.totalCount;

  return (
    <>
      <VisuallyHidden id="patientToggleDesc">
        Switch or add new patients
      </VisuallyHidden>
      <PatientListToggle
        aria-describedby="patientToggleDesc"
        isOpen={isPatientPickerExpanded}
        hasNok={hasNok}
        isMobileSwitcher={isMobileSwitcher || false}
        onClick={() => {
          if (!isPatientPickerExpanded) {
            trackEvent("patients_list_open", {
              patientid: patientId
            });
          }

          setIsPatientPickerExpanded(!isPatientPickerExpanded);
        }}
      >
        <Flex gap="m">
          <Avatar
            name={`${selectedPatient.node.firstName} ${selectedPatient.node.lastName}`}
            aria-hidden={true}
          />
          <TextWrapper>
            {" "}
            <NameText>
              {selectedPatient.node.firstName} {selectedPatient.node.lastName}
            </NameText>
            <Text size="s" color="gray70">
              {"Switch Patient"}
            </Text>
          </TextWrapper>
        </Flex>

        <Flex gap="m">
          <Icon
            type={
              isPatientPickerExpanded
                ? KeyboardArrowUpIcon
                : KeyboardArrowDownIcon
            }
            color="gray40"
          />

          {isMobileSwitcher && selectedPatientActiveOrdersTotal > 0 ? (
            <ToggleBadgeWrapper>
              <Badge>{selectedPatientActiveOrdersTotal}</Badge>
            </ToggleBadgeWrapper>
          ) : null}
        </Flex>
      </PatientListToggle>
      {isPatientPickerExpanded && isMobileSwitcher && <Divider margin="0" />}
      {isPatientPickerExpanded ? (
        <>
          {patientList.map(edge => {
            const patient = edge.node;
            const activeOrdersTotal =
              patient.activeOrders.totalCount +
              patient.recentHistoryOrders.totalCount;
            return (
              <PatientLink
                isMobileSwitcher={isMobileSwitcher || false}
                key={patient.id}
                to={switchPatient(patient.id)}
                onClick={() => {
                  //onSelectPatient(patient.id);
                  trackEvent("patient_chosen", {
                    patientid: patientId
                  });
                  onNavigate();
                }}
              >
                <Flex gap="m">
                  <Avatar
                    name={`${patient.firstName} ${patient.lastName}`}
                    aria-hidden={true}
                  />
                  <ItemText>
                    {patient.firstName} {patient.lastName}
                  </ItemText>
                </Flex>
                {activeOrdersTotal > 0 ? (
                  <Badge>{activeOrdersTotal}</Badge>
                ) : null}
              </PatientLink>
            );
          })}
          {!hasNok &&
            (isMobileSwitcher ? (
              <>
                <Text margin="s" isBold>
                  You can manage medicine for other people
                </Text>
                <Text margin="0 s">
                  Order medicine for your family and friends you care for, and
                  get it delivered wherever works best for you.
                </Text>
              </>
            ) : (
              <Text size="s" margin="xs 0 0" color="gray70">
                Manage medicine for other people by adding them here.
              </Text>
            ))}
          {!nominatedWithP2U &&
            migrationStatus !==
              AccountMigrationStatus.ACCOUNT_MIGRATION_STATUS_COMPLETED &&
            migrationStatus !==
              AccountMigrationStatus.ACCOUNT_MIGRATION_STATUS_PENDING &&
            migrationStatus !==
              AccountMigrationStatus.ACCOUNT_MIGRATION_STATUS_CONSENTED && (
              <LinkButtonWrapper isMobileSwitcher>
                <LinkButton
                  variant="secondary"
                  label="Add another person"
                  linkTo="/account/add-patient"
                  margin="0"
                  iconLeading={isMobileSwitcher ? AddIcon : undefined}
                  onClick={() => {
                    if (isMobileSwitcher) {
                      segment.addAnotherPerson({ patientid: patientId });
                    } else {
                      segment.addAnotherPersonSidebar({ patientid: patientId });
                    }
                    onNavigate();
                  }}
                />
              </LinkButtonWrapper>
            )}
        </>
      ) : null}
    </>
  );
};
