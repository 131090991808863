// AUTO-GENERATED - DO NOT EDIT!
import gql from "graphql-tag";
import {
  useQuery,
  useLazyQuery,
  QueryHookOptions,
  LazyQueryHookOptions
} from "@apollo/client";
import {
  getPatientTabs,
  getPatientTabsVariables
} from "../types/getPatientTabs";

export const useGetPatientTabsQuery = (
  opts: QueryHookOptions<getPatientTabs, getPatientTabsVariables>
) => {
  return useQuery<getPatientTabs, getPatientTabsVariables>(
    getPatientTabsQuery,
    opts
  );
};

export const getPatientTabsQuery = gql`
  query getPatientTabs($updateTimeAfter: Int) {
    account {
      id
      patients {
        edges {
          node {
            id
            isAccountHolder
            firstName
            lastName
            activeOrders: orders(
              statuses: [
                STATUS_NEW
                STATUS_AWAITING_PAYMENT
                STATUS_AWAITING_RX
                STATUS_AWAITING_STOCK
                STATUS_OUT_OF_STOCK
                STATUS_AWAITING_DEPENDENT_ORDERS
                STATUS_AWAITING_DEPENDENT_STOCK
                STATUS_LABELLING
                STATUS_ASSEMBLING
                STATUS_CHECKING
                STATUS_PICKING
                STATUS_DISPATCHING
              ]
            ) {
              edges {
                node {
                  id
                }
              }
              totalCount
            }
            recentHistoryOrders: orders(
              statuses: [STATUS_CANCELLED, STATUS_DISPATCHED]
              updateTimeAfter: $updateTimeAfter
            ) {
              edges {
                node {
                  id
                }
              }
              totalCount
            }
            actionNeeded {
              count
              actions {
                id
                isBlocking
              }
            }
          }
        }
      }
      actionNeeded {
        count
        actions {
          id
          isBlocking
        }
      }
    }
    features {
      name
      isEnabled
    }
  }
`;

export function refetchGetPatientTabsQuery(variables: getPatientTabsVariables) {
  return {
    query: getPatientTabsQuery,
    variables
  };
}

export const useLazyGetPatientTabsQuery = (
  opts: LazyQueryHookOptions<getPatientTabs, getPatientTabsVariables>
) => {
  return useLazyQuery<getPatientTabs, getPatientTabsVariables>(
    getPatientTabsQuery,
    opts
  );
};
