import * as React from "react";
import styled, { css } from "styled-components";
import withMargin, { WithMarginProp } from "../styled/withMargin";
import { deprecatedTrack } from "../analytics";
import { Link } from "react-router-dom";
import { LocationDescriptor } from "history";
import { Theme } from "@echo-health/design-system";

const ButtonGroupWrapper = styled.div<ButtonGroupWrapperProps>`
  ${({ theme, reverse, alignment }) => css`
    @media screen and (min-width: ${theme.breakpoints.m}) {
      display: flex;
      justify-content: ${alignment ? alignment : "space-between"};
      flex-direction: ${reverse ? "row" : "row-reverse"};
    }
  `}
  ${withMargin};
`;

const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.m};
  `}

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.m}) {
    margin: 0;
  }

  &:last-child {
    margin: 0;
  }
`;

type ButtonGroupWrapperProps = WithMarginProp & {
  alignment?: "center" | "flex-end" | "end";
  reverse?: boolean;
};

type ButtonGroupProps = WithMarginProp & {
  children: React.ReactNode;
  reverse?: boolean;
  alignment?: "center" | "flex-end" | "end";
};

/**
 * @deprecated
 * @see {@link ButtonGroup}
 */
export const DeprecatedButtonGroup: React.FC<ButtonGroupProps> = props => {
  return (
    <ButtonGroupWrapper
      margin={props.margin}
      reverse={props.reverse}
      alignment={props.alignment}
    >
      {React.Children.map(props.children, child => {
        // We don't want to render a button wrapper with associated margins etc. if the child is null/falsy
        if (child) {
          return <ButtonWrapper>{child}</ButtonWrapper>;
        }

        return child;
      })}
    </ButtonGroupWrapper>
  );
};

type ButtonVariants = "primary" | "secondary" | "danger" | "tertiary";

type ThemeProp = {
  theme: Theme;
};

const buttonStyles = ({ theme, ...props }: ButtonProps & ThemeProp) => css`
  font-family: ${theme.typography.bodyFamily};
  font-weight: ${theme.typography.weight.bold};
  font-size: ${theme.typography.size.m};
  line-height: ${theme.typography.lineHeight.m};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 170px;
  width: 100%;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  border-radius: 5px;
  height: 50px;
  padding: 0 ${theme.spacing.m};
  transition: background-color 0.2s;

  &:disabled {
    cursor: not-allowed;
    background-color: ${theme.color.gray20};
    color: ${theme.color.gray70};
  }

  ${props.variant === "primary" &&
  css`
    background: ${theme.color.green40};
    color: ${theme.color.green90};

    &:hover:not(:disabled) {
      background: ${theme.color.green30};
    }

    &:active:not(:disabled) {
      background: ${theme.color.green40};
    }

    &:focus {
      background: ${theme.color.green40};
    }
  `};

  ${props.variant === "secondary" &&
  css`
    color: ${theme.color.gray90};
    background: none;
    border: 2px solid ${theme.color.gray90};

    &:hover:not(:disabled) {
      color: ${theme.color.gray50};
      border: 2px solid ${theme.color.gray50};
    }
    &:focus {
      border: 2px solid ${theme.color.gray50};
    }

    &:disabled {
      border: 2px solid ${theme.color.gray30};
    }

    ${props.color === "blue" &&
    css`
      color: ${theme.color.blue70};
      background: none;
      border: 2px solid ${theme.color.blue60};

      &:hover:not(:disabled) {
        background: ${theme.color.blue10};
      }
      &:focus {
        border: 2px solid ${theme.color.blue50};
      }

      &:disabled {
        border: 2px solid ${theme.color.blue30};
      }
    `}

    ${props.dashed === true &&
    css`
      background: none;
      border: 2px dashed ${theme.color.gray20};
    `}
  `};

  ${props.variant === "danger" &&
  css`
    background: ${theme.color.red60};
    color: ${theme.color.white};

    &:hover:not(:disabled) {
      background: ${theme.color.red70};
    }
    &:active:not(:disabled) {
      background: ${theme.color.red70};
    }
    &:focus {
      background: ${theme.color.red70};
      box-shadow: 0 0 0 3px, ${theme.color.red20};
    }
  `};

  ${props.variant === "tertiary" &&
  css`
      display: inline-block;
      text-align: left;
      width: auto;
      min-width: auto;
      padding: 0;
      height: auto;
      font-weight: ${theme.typography.weight.normal};
      font-size: ${theme.typography.size[props.size!]};
      line-height: ${theme.typography.lineHeight[props.size!]};
      border-radius: 0;

      &:disabled {
        cursor: not-allowed;
        background: none;
        box-shadow: none;
        color: ${theme.color.gray40};
      }

    ${
      props.textAlignment &&
      css`
        width: 100%;
        display: block;
        text-align: ${props.textAlignment};
      `
    };

      ${
        props.color === "blue" &&
        css`
          color: ${theme.color.blue70};
          font-weight: ${theme.typography.weight.bold};
          &:hover:not(:disabled) {
            color: ${theme.color.blue50};
          }

          &:focus:not(:disabled) {
            outline: ${theme.color.blue50} auto 5px;
            color: ${theme.color.blue50};
          }

          &:active:not(:disabled) {
            color: ${theme.color.blue70};
          }
        `
      }

      ${
        props.color === "red" &&
        css`
          font-weight: ${theme.typography.weight.bold};
          color: ${theme.color.red70};
          &:hover:not(:disabled) {
            color: ${theme.color.red50};
          }

          &:focus:not(:disabled) {
            outline: ${theme.color.blue50} auto 5px;
            color: ${theme.color.red50};
          }

          &:active:not(:disabled) {
            color: ${theme.color.red70};
          }
        `
      };
      }

      ${
        props.color === "green" &&
        css`
          font-weight: ${theme.typography.weight.bold};
          color: ${theme.color.green70};
          &:hover:not(:disabled) {
            color: ${theme.color.green60};
          }

          &:focus:not(:disabled) {
            outline: ${theme.color.blue50} auto 5px;
            color: ${theme.color.green60};
          }

          &:active:not(:disabled) {
            color: ${theme.color.green60};
          }
        `
      }
    `};

  ${withMargin}
`;

const StyledButton = styled("button")<ButtonProps>`
  ${buttonStyles};
`;

const StyledLink = styled(
  ({
    linkTo,
    isDisabled,
    isLoading,
    externalHref,
    color,
    children,
    ...otherProps
  }: ButtonProps) => {
    // The reason we don't use the `<Link>` component if there is no href is
    // because react-router doesn't allow for it's Link component to not have a `to` url.
    if (linkTo) {
      return (
        <Link to={linkTo} {...otherProps}>
          {children}
        </Link>
      );
    } else {
      return (
        <a href={externalHref} {...otherProps}>
          {children}
        </a>
      );
    }
  }
)<ButtonProps & { role?: string }>`
  ${buttonStyles};
`;

type ButtonProps = WithMarginProp & {
  children: React.ReactNode;
  color?: "red" | "green" | "blue";
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => void;
  variant?: ButtonVariants;
  isLoading?: boolean;
  isDisabled?: boolean;
  size?: "s" | "m";
  textAlignment?: "center" | "right";
  type?: "button" | "reset" | "submit";
  trackEventName?: string;
  linkTo?: LocationDescriptor;
  externalHref?: string;
  className?: string;
  dashed?: boolean;
  target?: string;
};

const onClickHandler = (
  trackEventName?: string,
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => void
) => {
  return (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>
  ) => {
    if (trackEventName) {
      deprecatedTrack(trackEventName);
    }
    if (onClick) {
      onClick(event);
    }
  };
};

/**
 * @deprecated
 * @see {@link Button} {@link LinkButton}
 */
export const DeprecatedButton = (props: ButtonProps) => {
  const {
    variant = "primary",
    isLoading = false,
    children,
    color,
    size = "m",
    trackEventName,
    ...otherProps
  } = props;

  if (props.linkTo || props.externalHref) {
    return (
      <StyledLink
        {...otherProps}
        linkTo={props.linkTo}
        externalHref={props.externalHref}
        variant={variant}
        color={color}
        size={size}
        onClick={onClickHandler(trackEventName, props.onClick)}
      >
        {!isLoading && children}
        {isLoading && "Loading..."}
      </StyledLink>
    );
  }

  return (
    <StyledButton
      {...otherProps}
      color={color}
      variant={variant}
      size={size}
      disabled={Boolean(props.isLoading || props.isDisabled)}
      onClick={onClickHandler(trackEventName, props.onClick)}
    >
      {!props.isLoading && children}
      {props.isLoading && "Loading..."}
    </StyledButton>
  );
};

export default DeprecatedButton;
