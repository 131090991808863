import * as React from "react";
import styled, { css } from "styled-components";
import withMargin, { WithMarginProp } from "../styled/withMargin";
import { deprecatedTrack } from "../analytics";

const AnchorWrapper = styled("a")<CommonLinkProps>`
  ${({ theme, isDisabled, size, color }) => css`
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;

    color: ${theme.color.blue70};
    font-weight: ${theme.typography.weight.bold};

    ${color === "red" &&
    css`
      color: ${theme.color.red70};
    `}

    ${size === "s" &&
    css`
        font-size: ${theme.typography.size.xs};
        line-height: ${theme.typography.lineHeight.xs}
        font-weight: ${theme.typography.weight.bold};
        color: ${color === "blue" ? theme.color.blue70 : theme.color.gray70};
      `}

    ${isDisabled &&
    css`
      opacity: 0.3;
      pointer-events: none;

      &:hover {
        background: inherit;
      }
    `}

    &:hover,
    &:focus {
      color: ${theme.color.blue70};
      transition: all 100ms ease;
    }
  `}
  ${withMargin};
`;

export type CommonLinkProps = Pick<
  AnchorLinkProps,
  "isDisabled" | "size" | "margin" | "color"
>;

type AnchorLinkProps = WithMarginProp & {
  href: string;
  isDisabled?: boolean;
  size?: "s" | "m";
  children: React.ReactNode;
  trackEventName?: string;
  color?: "gray" | "blue" | "red";
  onTrack?: () => void;
};

/**
 * @deprecated
 * @see {@link TextLink}
 */
const AnchorLink = ({
  children,
  isDisabled = false,
  size = "m",
  color,
  href,
  margin,
  onTrack,
  trackEventName
}: AnchorLinkProps) => {
  return (
    <AnchorWrapper
      color={color}
      margin={margin}
      size={size}
      target="_blank"
      rel="noreferrer"
      href={href}
      isDisabled={isDisabled}
      onClick={() => {
        if (onTrack) {
          onTrack();
        }
        if (trackEventName) {
          deprecatedTrack(trackEventName);
        }
      }}
    >
      {children}
    </AnchorWrapper>
  );
};

export default AnchorLink;
