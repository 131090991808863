/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountMigrationStatus {
  ACCOUNT_MIGRATION_STATUS_CHURNED = "ACCOUNT_MIGRATION_STATUS_CHURNED",
  ACCOUNT_MIGRATION_STATUS_COMPLETED = "ACCOUNT_MIGRATION_STATUS_COMPLETED",
  ACCOUNT_MIGRATION_STATUS_CONSENTED = "ACCOUNT_MIGRATION_STATUS_CONSENTED",
  ACCOUNT_MIGRATION_STATUS_FAILED = "ACCOUNT_MIGRATION_STATUS_FAILED",
  ACCOUNT_MIGRATION_STATUS_PENDING = "ACCOUNT_MIGRATION_STATUS_PENDING",
  ACCOUNT_MIGRATION_STATUS_UNKNOWN = "ACCOUNT_MIGRATION_STATUS_UNKNOWN"
}

export enum AccountPatientRelationship {
  RELATIONSHIP_CHILD = "RELATIONSHIP_CHILD",
  RELATIONSHIP_DEPENDENT = "RELATIONSHIP_DEPENDENT",
  RELATIONSHIP_OWNER = "RELATIONSHIP_OWNER",
  RELATIONSHIP_RELATIVE = "RELATIONSHIP_RELATIVE",
  RELATIONSHIP_UNKNOWN = "RELATIONSHIP_UNKNOWN"
}

export enum ActionNeededType {
  ACTION_NEEDED_ACCOUNT_INCOMPLETE = "ACTION_NEEDED_ACCOUNT_INCOMPLETE",
  ACTION_NEEDED_ACCOUNT_MIGRATED = "ACTION_NEEDED_ACCOUNT_MIGRATED",
  ACTION_NEEDED_ASK_PATIENT = "ACTION_NEEDED_ASK_PATIENT",
  ACTION_NEEDED_CONFIRM_PHONE = "ACTION_NEEDED_CONFIRM_PHONE",
  ACTION_NEEDED_CONNECT_GP_TO_KEEP_ORDERING = "ACTION_NEEDED_CONNECT_GP_TO_KEEP_ORDERING",
  ACTION_NEEDED_ORDERS_AWAITING_PAYMENT = "ACTION_NEEDED_ORDERS_AWAITING_PAYMENT",
  ACTION_NEEDED_SPECIFY_BRANDS = "ACTION_NEEDED_SPECIFY_BRANDS",
  ACTION_NEEDED_UPDATE_DEMOGRAPHICS = "ACTION_NEEDED_UPDATE_DEMOGRAPHICS",
  ACTION_NEEDED_URGENT_ASK_PATIENT = "ACTION_NEEDED_URGENT_ASK_PATIENT"
}

export enum AddressType {
  ADDRESS_TYPE_LOCAL_COLLECT = "ADDRESS_TYPE_LOCAL_COLLECT",
  ADDRESS_TYPE_PERSONAL = "ADDRESS_TYPE_PERSONAL",
  ADDRESS_TYPE_PHARMACY = "ADDRESS_TYPE_PHARMACY",
  ADDRESS_TYPE_UNKNOWN = "ADDRESS_TYPE_UNKNOWN"
}

export enum CancellationReason {
  CANCELLATION_REASON_ACCOUNT_CLOSED = "CANCELLATION_REASON_ACCOUNT_CLOSED",
  CANCELLATION_REASON_CANCELLED_BY_PATIENT = "CANCELLATION_REASON_CANCELLED_BY_PATIENT",
  CANCELLATION_REASON_ECHO_NOT_RIGHT = "CANCELLATION_REASON_ECHO_NOT_RIGHT",
  CANCELLATION_REASON_EXPIRED = "CANCELLATION_REASON_EXPIRED",
  CANCELLATION_REASON_GP_REFUSED = "CANCELLATION_REASON_GP_REFUSED",
  CANCELLATION_REASON_MEDICATION_DISCONTINUED = "CANCELLATION_REASON_MEDICATION_DISCONTINUED",
  CANCELLATION_REASON_MEDICATION_NOT_ON_REPEAT = "CANCELLATION_REASON_MEDICATION_NOT_ON_REPEAT",
  CANCELLATION_REASON_MEDICATION_OUT_OF_STOCK = "CANCELLATION_REASON_MEDICATION_OUT_OF_STOCK",
  CANCELLATION_REASON_NEEDS_REVIEW = "CANCELLATION_REASON_NEEDS_REVIEW",
  CANCELLATION_REASON_NOT_DISPENSED = "CANCELLATION_REASON_NOT_DISPENSED",
  CANCELLATION_REASON_NOT_REGISTERED = "CANCELLATION_REASON_NOT_REGISTERED",
  CANCELLATION_REASON_ORDERED_TOO_EARLY = "CANCELLATION_REASON_ORDERED_TOO_EARLY",
  CANCELLATION_REASON_OTHER_PHARMACY = "CANCELLATION_REASON_OTHER_PHARMACY",
  CANCELLATION_REASON_PATIENT_COLLECTING_LOCALLY = "CANCELLATION_REASON_PATIENT_COLLECTING_LOCALLY",
  CANCELLATION_REASON_PATIENT_DECEASED = "CANCELLATION_REASON_PATIENT_DECEASED",
  CANCELLATION_REASON_PATIENT_DELETED = "CANCELLATION_REASON_PATIENT_DELETED",
  CANCELLATION_REASON_PATIENT_NO_LONGER_NEEDS_MEDICATION = "CANCELLATION_REASON_PATIENT_NO_LONGER_NEEDS_MEDICATION",
  CANCELLATION_REASON_PATIENT_RAN_OUT_OF_MEDICATION = "CANCELLATION_REASON_PATIENT_RAN_OUT_OF_MEDICATION",
  CANCELLATION_REASON_PRESCRIPTION_EXPIRED = "CANCELLATION_REASON_PRESCRIPTION_EXPIRED",
  CANCELLATION_REASON_SCREENING_SUBSTITUTION = "CANCELLATION_REASON_SCREENING_SUBSTITUTION",
  CANCELLATION_REASON_SURGERY_CANCELLED = "CANCELLATION_REASON_SURGERY_CANCELLED",
  CANCELLATION_REASON_UNKNOWN = "CANCELLATION_REASON_UNKNOWN"
}

export enum ConnectError {
  CONNECT_ERROR_ACCOUNT_ALREADY_CONNECTED = "CONNECT_ERROR_ACCOUNT_ALREADY_CONNECTED",
  CONNECT_ERROR_ACCOUNT_ALREADY_EXISTS = "CONNECT_ERROR_ACCOUNT_ALREADY_EXISTS",
  CONNECT_ERROR_UNKNOWN = "CONNECT_ERROR_UNKNOWN",
  CONNECT_ERROR_URL_EXPIRED = "CONNECT_ERROR_URL_EXPIRED"
}

export enum ConsultationMedicationContraceptiveType {
  CONTRACEPTIVE_TYPE_COMBINED_ORAL = "CONTRACEPTIVE_TYPE_COMBINED_ORAL",
  CONTRACEPTIVE_TYPE_PROGESTERONE_ONLY = "CONTRACEPTIVE_TYPE_PROGESTERONE_ONLY",
  CONTRACEPTIVE_TYPE_UNSPECIFIED = "CONTRACEPTIVE_TYPE_UNSPECIFIED"
}

export enum ContentBlockActionID {
  CONTENT_BLOCK_ACTION_ADD_EXEMPTION = "CONTENT_BLOCK_ACTION_ADD_EXEMPTION",
  CONTENT_BLOCK_ACTION_ADD_MEDICINE_SUPPLY = "CONTENT_BLOCK_ACTION_ADD_MEDICINE_SUPPLY",
  CONTENT_BLOCK_ACTION_CANCEL_CONSULTATION = "CONTENT_BLOCK_ACTION_CANCEL_CONSULTATION",
  CONTENT_BLOCK_ACTION_CHANGE_GP_SURGERY = "CONTENT_BLOCK_ACTION_CHANGE_GP_SURGERY",
  CONTENT_BLOCK_ACTION_CONFIRM_MEDICINE = "CONTENT_BLOCK_ACTION_CONFIRM_MEDICINE",
  CONTENT_BLOCK_ACTION_CONFIRM_URGENT_MEDICINE = "CONTENT_BLOCK_ACTION_CONFIRM_URGENT_MEDICINE",
  CONTENT_BLOCK_ACTION_CONTACT_GP = "CONTENT_BLOCK_ACTION_CONTACT_GP",
  CONTENT_BLOCK_ACTION_CONTACT_SUPPORT_TEAM = "CONTENT_BLOCK_ACTION_CONTACT_SUPPORT_TEAM",
  CONTENT_BLOCK_ACTION_EXTERNAL_URL = "CONTENT_BLOCK_ACTION_EXTERNAL_URL",
  CONTENT_BLOCK_ACTION_NEED_IT_SOONER = "CONTENT_BLOCK_ACTION_NEED_IT_SOONER",
  CONTENT_BLOCK_ACTION_PAY_NOW = "CONTENT_BLOCK_ACTION_PAY_NOW",
  CONTENT_BLOCK_ACTION_PHONE_CALL = "CONTENT_BLOCK_ACTION_PHONE_CALL",
  CONTENT_BLOCK_ACTION_REQUEST_NEXT_BATCH = "CONTENT_BLOCK_ACTION_REQUEST_NEXT_BATCH",
  CONTENT_BLOCK_ACTION_REQUEST_PRESCRIPTION_PRIMARY = "CONTENT_BLOCK_ACTION_REQUEST_PRESCRIPTION_PRIMARY",
  CONTENT_BLOCK_ACTION_REQUEST_PRESCRIPTION_SECONDARY = "CONTENT_BLOCK_ACTION_REQUEST_PRESCRIPTION_SECONDARY",
  CONTENT_BLOCK_ACTION_SEE_REQUEST_DETAILS = "CONTENT_BLOCK_ACTION_SEE_REQUEST_DETAILS",
  CONTENT_BLOCK_ACTION_SPECIFY_BRAND = "CONTENT_BLOCK_ACTION_SPECIFY_BRAND",
  CONTENT_BLOCK_ACTION_TRACK_SHIPMENT = "CONTENT_BLOCK_ACTION_TRACK_SHIPMENT"
}

export enum DeliveryPreference {
  DELIVERY_PREFERENCE_GROUPED = "DELIVERY_PREFERENCE_GROUPED",
  DELIVERY_PREFERENCE_SOONEST = "DELIVERY_PREFERENCE_SOONEST",
  DELIVERY_PREFERENCE_UNKNOWN = "DELIVERY_PREFERENCE_UNKNOWN"
}

export enum DeliveryType {
  DELIVERY_TYPE_24 = "DELIVERY_TYPE_24",
  DELIVERY_TYPE_24_SIGNED_FOR = "DELIVERY_TYPE_24_SIGNED_FOR",
  DELIVERY_TYPE_48 = "DELIVERY_TYPE_48",
  DELIVERY_TYPE_48_SIGNED_FOR = "DELIVERY_TYPE_48_SIGNED_FOR",
  DELIVERY_TYPE_UNKNOWN = "DELIVERY_TYPE_UNKNOWN"
}

export enum ExemptionCode {
  EXEMPTION_CODE_A = "EXEMPTION_CODE_A",
  EXEMPTION_CODE_B = "EXEMPTION_CODE_B",
  EXEMPTION_CODE_C = "EXEMPTION_CODE_C",
  EXEMPTION_CODE_D = "EXEMPTION_CODE_D",
  EXEMPTION_CODE_E = "EXEMPTION_CODE_E",
  EXEMPTION_CODE_F = "EXEMPTION_CODE_F",
  EXEMPTION_CODE_G = "EXEMPTION_CODE_G",
  EXEMPTION_CODE_H = "EXEMPTION_CODE_H",
  EXEMPTION_CODE_HRT = "EXEMPTION_CODE_HRT",
  EXEMPTION_CODE_K = "EXEMPTION_CODE_K",
  EXEMPTION_CODE_L = "EXEMPTION_CODE_L",
  EXEMPTION_CODE_M = "EXEMPTION_CODE_M",
  EXEMPTION_CODE_S = "EXEMPTION_CODE_S",
  EXEMPTION_CODE_U = "EXEMPTION_CODE_U",
  EXEMPTION_CODE_UNKNOWN = "EXEMPTION_CODE_UNKNOWN"
}

export enum ExemptionStatus {
  EXEMPTION_APPROVED = "EXEMPTION_APPROVED",
  EXEMPTION_NEW = "EXEMPTION_NEW",
  EXEMPTION_REJECTED = "EXEMPTION_REJECTED",
  EXEMPTION_UNKNOWN = "EXEMPTION_UNKNOWN"
}

export enum Gender {
  GENDER_FEMALE = "GENDER_FEMALE",
  GENDER_MALE = "GENDER_MALE",
  GENDER_OTHER = "GENDER_OTHER",
  GENDER_UNKNOWN = "GENDER_UNKNOWN"
}

export enum HelpActionType {
  CALL = "CALL",
  CHAT = "CHAT",
  LINK = "LINK",
  NODE = "NODE"
}

export enum IM1Provider {
  IM1_MOCK_PROVIDER_BUBBLES = "IM1_MOCK_PROVIDER_BUBBLES",
  IM1_PROVIDER_EMIS = "IM1_PROVIDER_EMIS",
  IM1_PROVIDER_MICROTEST = "IM1_PROVIDER_MICROTEST",
  IM1_PROVIDER_TPP = "IM1_PROVIDER_TPP",
  IM1_PROVIDER_UNKNOWN = "IM1_PROVIDER_UNKNOWN",
  IM1_PROVIDER_VISION = "IM1_PROVIDER_VISION"
}

export enum MedicationAvailabilityType {
  AVAILABILITY_TYPE_AVAILABLE = "AVAILABILITY_TYPE_AVAILABLE",
  AVAILABILITY_TYPE_CONTROLLED = "AVAILABILITY_TYPE_CONTROLLED",
  AVAILABILITY_TYPE_DISCONTINUED = "AVAILABILITY_TYPE_DISCONTINUED",
  AVAILABILITY_TYPE_NOT_AVAILABLE = "AVAILABILITY_TYPE_NOT_AVAILABLE",
  AVAILABILITY_TYPE_UNKNOWN = "AVAILABILITY_TYPE_UNKNOWN"
}

export enum MedicationContraceptiveType {
  CONTRACEPTIVE_TYPE_COMBINED_ORAL = "CONTRACEPTIVE_TYPE_COMBINED_ORAL",
  CONTRACEPTIVE_TYPE_PROGESTERONE_ONLY = "CONTRACEPTIVE_TYPE_PROGESTERONE_ONLY",
  CONTRACEPTIVE_TYPE_UNKNOWN = "CONTRACEPTIVE_TYPE_UNKNOWN"
}

export enum MedicationIcon {
  ICON_BOTTLE = "ICON_BOTTLE",
  ICON_CAPSULE = "ICON_CAPSULE",
  ICON_DEFAULT = "ICON_DEFAULT",
  ICON_DRESSING = "ICON_DRESSING",
  ICON_DROP = "ICON_DROP",
  ICON_INHALER = "ICON_INHALER",
  ICON_NEEDLE = "ICON_NEEDLE",
  ICON_PATCH = "ICON_PATCH",
  ICON_SACHET = "ICON_SACHET",
  ICON_SPRAY = "ICON_SPRAY",
  ICON_STOCKING = "ICON_STOCKING",
  ICON_SYRINGE = "ICON_SYRINGE",
  ICON_TABLET = "ICON_TABLET",
  ICON_TEST_STRIP = "ICON_TEST_STRIP",
  ICON_TUB = "ICON_TUB",
  ICON_TUBE_CYLINDER = "ICON_TUBE_CYLINDER"
}

export enum MedicationLookupType {
  LOOKUP_TYPE_AMP = "LOOKUP_TYPE_AMP",
  LOOKUP_TYPE_AMPP = "LOOKUP_TYPE_AMPP",
  LOOKUP_TYPE_UNKNOWN = "LOOKUP_TYPE_UNKNOWN",
  LOOKUP_TYPE_VMP = "LOOKUP_TYPE_VMP"
}

export enum MedicationStatus {
  MEDICATION_STATUS_ACCOUNT_MIGRATED = "MEDICATION_STATUS_ACCOUNT_MIGRATED",
  MEDICATION_STATUS_ACTION_REQUIRED = "MEDICATION_STATUS_ACTION_REQUIRED",
  MEDICATION_STATUS_ALREADY_REQUESTED = "MEDICATION_STATUS_ALREADY_REQUESTED",
  MEDICATION_STATUS_CANNOT_BE_REQUESTED = "MEDICATION_STATUS_CANNOT_BE_REQUESTED",
  MEDICATION_STATUS_CURRENTLY_UNAVAILABLE = "MEDICATION_STATUS_CURRENTLY_UNAVAILABLE",
  MEDICATION_STATUS_EXCLUDED = "MEDICATION_STATUS_EXCLUDED",
  MEDICATION_STATUS_NOT_READY = "MEDICATION_STATUS_NOT_READY",
  MEDICATION_STATUS_PENDING_CONSULTATION = "MEDICATION_STATUS_PENDING_CONSULTATION",
  MEDICATION_STATUS_READY = "MEDICATION_STATUS_READY"
}

export enum MigrationActivationStatus {
  ACCOUNT_MIGRATION_ACTIVATION_STATUS_ACTIVATED = "ACCOUNT_MIGRATION_ACTIVATION_STATUS_ACTIVATED",
  ACCOUNT_MIGRATION_ACTIVATION_STATUS_PENDING_ACTIVATION = "ACCOUNT_MIGRATION_ACTIVATION_STATUS_PENDING_ACTIVATION",
  ACCOUNT_MIGRATION_ACTIVATION_STATUS_PENDING_CONSENT = "ACCOUNT_MIGRATION_ACTIVATION_STATUS_PENDING_CONSENT",
  ACCOUNT_MIGRATION_ACTIVATION_STATUS_REGISTRATION_FAILURE = "ACCOUNT_MIGRATION_ACTIVATION_STATUS_REGISTRATION_FAILURE",
  ACCOUNT_MIGRATION_ACTIVATION_STATUS_UNKNOWN = "ACCOUNT_MIGRATION_ACTIVATION_STATUS_UNKNOWN"
}

export enum NHSLoginError {
  NHS_LOGIN_ERROR_CANNOT_CONNECT = "NHS_LOGIN_ERROR_CANNOT_CONNECT",
  NHS_LOGIN_ERROR_CANNOT_CONNECT_GP = "NHS_LOGIN_ERROR_CANNOT_CONNECT_GP",
  NHS_LOGIN_ERROR_CANNOT_CREATE_ACCOUNT = "NHS_LOGIN_ERROR_CANNOT_CREATE_ACCOUNT",
  NHS_LOGIN_ERROR_CANNOT_RETRIEVE_USER_DETAILS = "NHS_LOGIN_ERROR_CANNOT_RETRIEVE_USER_DETAILS",
  NHS_LOGIN_ERROR_MISSING_PRACTICE = "NHS_LOGIN_ERROR_MISSING_PRACTICE",
  NHS_LOGIN_ERROR_NON_EPS_PRACTICE = "NHS_LOGIN_ERROR_NON_EPS_PRACTICE",
  NHS_LOGIN_ERROR_PATIENT_IS_NOK = "NHS_LOGIN_ERROR_PATIENT_IS_NOK",
  NHS_LOGIN_ERROR_UNKNOWN = "NHS_LOGIN_ERROR_UNKNOWN"
}

export enum NominationStatus {
  NOMINATION_ERROR = "NOMINATION_ERROR",
  NOMINATION_NEW = "NOMINATION_NEW",
  NOMINATION_NOMINATED = "NOMINATION_NOMINATED",
  NOMINATION_NOT_NOMINATED = "NOMINATION_NOT_NOMINATED",
  NOMINATION_PENDING = "NOMINATION_PENDING",
  NOMINATION_UNKNOWN = "NOMINATION_UNKNOWN",
  UNNOMINATION_PENDING = "UNNOMINATION_PENDING"
}

export enum NotificationMethodType {
  NOTIFICATION_METHOD_TYPE_EMAIL = "NOTIFICATION_METHOD_TYPE_EMAIL",
  NOTIFICATION_METHOD_TYPE_POST = "NOTIFICATION_METHOD_TYPE_POST",
  NOTIFICATION_METHOD_TYPE_PUSH = "NOTIFICATION_METHOD_TYPE_PUSH",
  NOTIFICATION_METHOD_TYPE_ROYAL_MAIL = "NOTIFICATION_METHOD_TYPE_ROYAL_MAIL",
  NOTIFICATION_METHOD_TYPE_SMS = "NOTIFICATION_METHOD_TYPE_SMS"
}

export enum NotificationType {
  NOTIFICATION_TYPE_ACCOUNT_NOTIFICATIONS = "NOTIFICATION_TYPE_ACCOUNT_NOTIFICATIONS",
  NOTIFICATION_TYPE_HOME_DELIVERY = "NOTIFICATION_TYPE_HOME_DELIVERY",
  NOTIFICATION_TYPE_MARKETING = "NOTIFICATION_TYPE_MARKETING",
  NOTIFICATION_TYPE_ORDER_REMINDERS = "NOTIFICATION_TYPE_ORDER_REMINDERS",
  NOTIFICATION_TYPE_ORDER_UPDATES = "NOTIFICATION_TYPE_ORDER_UPDATES"
}

export enum OrderEventStatus {
  ORDER_EVENT_STATUS_CANCELLED = "ORDER_EVENT_STATUS_CANCELLED",
  ORDER_EVENT_STATUS_COMPLETED = "ORDER_EVENT_STATUS_COMPLETED",
  ORDER_EVENT_STATUS_PENDING = "ORDER_EVENT_STATUS_PENDING"
}

export enum OrderStatus {
  STATUS_ASSEMBLING = "STATUS_ASSEMBLING",
  STATUS_AWAITING_DEPENDENT_ORDERS = "STATUS_AWAITING_DEPENDENT_ORDERS",
  STATUS_AWAITING_DEPENDENT_STOCK = "STATUS_AWAITING_DEPENDENT_STOCK",
  STATUS_AWAITING_FULFILMENT = "STATUS_AWAITING_FULFILMENT",
  STATUS_AWAITING_PAYMENT = "STATUS_AWAITING_PAYMENT",
  STATUS_AWAITING_RX = "STATUS_AWAITING_RX",
  STATUS_AWAITING_STOCK = "STATUS_AWAITING_STOCK",
  STATUS_AWAITING_THIRD_PARTY_DISPENSE = "STATUS_AWAITING_THIRD_PARTY_DISPENSE",
  STATUS_AWAITING_THIRD_PARTY_FORWARD = "STATUS_AWAITING_THIRD_PARTY_FORWARD",
  STATUS_CANCELLED = "STATUS_CANCELLED",
  STATUS_CHECKING = "STATUS_CHECKING",
  STATUS_COLLECTED = "STATUS_COLLECTED",
  STATUS_COLLECTED_FROM_THIRD_PARTY = "STATUS_COLLECTED_FROM_THIRD_PARTY",
  STATUS_DELIVERED = "STATUS_DELIVERED",
  STATUS_DISPATCHED = "STATUS_DISPATCHED",
  STATUS_DISPATCHING = "STATUS_DISPATCHING",
  STATUS_LABELLING = "STATUS_LABELLING",
  STATUS_NEW = "STATUS_NEW",
  STATUS_OUT_OF_STOCK = "STATUS_OUT_OF_STOCK",
  STATUS_PICKING = "STATUS_PICKING",
  STATUS_READY_TO_COLLECT_FROM_THIRD_PARTY = "STATUS_READY_TO_COLLECT_FROM_THIRD_PARTY",
  STATUS_UNKNOWN = "STATUS_UNKNOWN"
}

export enum OrderSummaryAction {
  ASK_PATIENT = "ASK_PATIENT",
  CALL_GP = "CALL_GP",
  CALL_PHARMACY = "CALL_PHARMACY",
  CHANGE_GP = "CHANGE_GP",
  PAY = "PAY",
  STOCK_OPTIONS = "STOCK_OPTIONS",
  SUPPORT = "SUPPORT",
  TRACK_DELIVERY = "TRACK_DELIVERY"
}

export enum OrderSummaryGroupType {
  ASK_PATIENT = "ASK_PATIENT",
  AWAITING_GP = "AWAITING_GP",
  AWAITING_STOCK = "AWAITING_STOCK",
  AWAITING_THIRD_PARTY_DISPENSE = "AWAITING_THIRD_PARTY_DISPENSE",
  AWAITING_THIRD_PARTY_FORWARD = "AWAITING_THIRD_PARTY_FORWARD",
  CANCELLED = "CANCELLED",
  COLLECTED_FROM_THIRD_PARTY = "COLLECTED_FROM_THIRD_PARTY",
  DISPATCHED = "DISPATCHED",
  NEW = "NEW",
  PAY = "PAY",
  PREPARING = "PREPARING",
  READY_TO_COLLECT_FROM_THIRD_PARTY = "READY_TO_COLLECT_FROM_THIRD_PARTY"
}

export enum OrderSummaryStatusLevel {
  ERROR = "ERROR",
  INFO = "INFO",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING"
}

export enum PaymentMethodType {
  PAYMENT_METHOD_ANDROID_PAY = "PAYMENT_METHOD_ANDROID_PAY",
  PAYMENT_METHOD_APPLE_PAY = "PAYMENT_METHOD_APPLE_PAY",
  PAYMENT_METHOD_CARD = "PAYMENT_METHOD_CARD",
  PAYMENT_METHOD_UNKNOWN = "PAYMENT_METHOD_UNKNOWN"
}

export enum PrescriptionItemStatus {
  PRESCRIPTION_ITEM_STATUS_ASK_PATIENT = "PRESCRIPTION_ITEM_STATUS_ASK_PATIENT",
  PRESCRIPTION_ITEM_STATUS_DELETED = "PRESCRIPTION_ITEM_STATUS_DELETED",
  PRESCRIPTION_ITEM_STATUS_INITIAL = "PRESCRIPTION_ITEM_STATUS_INITIAL",
  PRESCRIPTION_ITEM_STATUS_MATCHED = "PRESCRIPTION_ITEM_STATUS_MATCHED",
  PRESCRIPTION_ITEM_STATUS_NOT_DISPENSED = "PRESCRIPTION_ITEM_STATUS_NOT_DISPENSED",
  PRESCRIPTION_ITEM_STATUS_UNKNOWN = "PRESCRIPTION_ITEM_STATUS_UNKNOWN"
}

export enum PrescriptionRequestMethods {
  PRESCRIPTION_REQUEST_METHOD_EMAIL = "PRESCRIPTION_REQUEST_METHOD_EMAIL",
  PRESCRIPTION_REQUEST_METHOD_FAX = "PRESCRIPTION_REQUEST_METHOD_FAX",
  PRESCRIPTION_REQUEST_METHOD_PATIENT = "PRESCRIPTION_REQUEST_METHOD_PATIENT",
  PRESCRIPTION_REQUEST_METHOD_PHONE = "PRESCRIPTION_REQUEST_METHOD_PHONE",
  PRESCRIPTION_REQUEST_METHOD_POST = "PRESCRIPTION_REQUEST_METHOD_POST",
  PRESCRIPTION_REQUEST_METHOD_UNKNOWN = "PRESCRIPTION_REQUEST_METHOD_UNKNOWN"
}

export enum ScheduleDayOfWeek {
  SCHEDULE_DAY_FRIDAY = "SCHEDULE_DAY_FRIDAY",
  SCHEDULE_DAY_MONDAY = "SCHEDULE_DAY_MONDAY",
  SCHEDULE_DAY_SATURDAY = "SCHEDULE_DAY_SATURDAY",
  SCHEDULE_DAY_SUNDAY = "SCHEDULE_DAY_SUNDAY",
  SCHEDULE_DAY_THURSDAY = "SCHEDULE_DAY_THURSDAY",
  SCHEDULE_DAY_TUESDAY = "SCHEDULE_DAY_TUESDAY",
  SCHEDULE_DAY_UNKNOWN = "SCHEDULE_DAY_UNKNOWN",
  SCHEDULE_DAY_WEDNESDAY = "SCHEDULE_DAY_WEDNESDAY"
}

export enum ServiceAdvisoryType {
  REQUESTING_MEDICATIONS = "REQUESTING_MEDICATIONS",
  SIGNING_UP = "SIGNING_UP"
}

export enum SetupStatus {
  SETUP_STATUS_UNKNOWN = "SETUP_STATUS_UNKNOWN",
  STATUS_EXISTING_ACCOUNT = "STATUS_EXISTING_ACCOUNT",
  STATUS_NEW_ACCOUNT = "STATUS_NEW_ACCOUNT"
}

export enum SeverityLevel {
  SEVERITY_LEVEL_INFO = "SEVERITY_LEVEL_INFO",
  SEVERITY_LEVEL_SUCCESS = "SEVERITY_LEVEL_SUCCESS",
  SEVERITY_LEVEL_URGENT = "SEVERITY_LEVEL_URGENT",
  SEVERITY_LEVEL_WARNING = "SEVERITY_LEVEL_WARNING"
}

export enum StockEstimateStatus {
  AWAITING_STOCK = "AWAITING_STOCK",
  OUT_OF_STOCK = "OUT_OF_STOCK",
  STOCK_AVAILABLE = "STOCK_AVAILABLE"
}

/**
 * Represents a set of geolocation coordinates
 */
export interface CoordinatesInput {
  latitude: number;
  longitude: number;
  radius?: number | null;
}

export interface NotificationPreferenceToUpdate {
  notificationType: NotificationType;
  preferences: Preference[];
}

export interface Preference {
  method: NotificationMethodType;
  enabled: boolean;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
