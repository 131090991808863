import React from "react";
import { Button } from "../design-system/components/Button";
import { TextButton } from "../design-system/components/TextButton";
import { Heading } from "../design-system/components/Heading";
import { DeprecatedModal } from "./DeprecatedModal";
import { ApolloError } from "@apollo/client";
import { Text } from "../design-system/components/Text";
import { Stack } from "./Stack";
import { connect } from "react-redux";
import { isAuthError } from "../utils/auth";
import { DispatchProp } from "../store/types";
import { ErrorIllustration } from "./ErrorModal/ErrorIllustration";

interface ModalProps {
  error?: ApolloError | undefined;
  isOpen: boolean;
  onDismiss: () => void;
}

const DeprecatedErrorModal = ({
  error,
  onDismiss,
  isOpen
}: ModalProps & DispatchProp) => {
  // This is handled by apollo-link error elsewhere in our app.
  if (error && isAuthError(error.graphQLErrors)) {
    return null;
  }

  let description = (
    <>
      Something isn't working correctly. Please try again, or{" "}
      <TextButton
        label="contact support"
        className="openIntercom"
        onClick={() => {
          onDismiss();
        }}
      />{" "}
      if this keeps happening.
    </>
  );

  if (error && error.networkError) {
    description = (
      <>
        It seems like there's an issue with your internet connection. Please
        double check you're online and that you can access the internet.
      </>
    );
  }

  return (
    <DeprecatedModal isOpen={isOpen} onRequestClose={onDismiss}>
      <Stack vertical={true} distribution="center" alignment="center">
        <ErrorIllustration margin="0 0 s 0" />
        <Heading margin="0 0 m 0">Something's not right</Heading>
        <Text as="span" align="center" margin="0 0 l 0">
          {description}
        </Text>
        <Button label="Close" onClick={onDismiss} />
      </Stack>
    </DeprecatedModal>
  );
};

export default connect()(DeprecatedErrorModal);
