export function hasLocalStorage() {
  var k = "lsfeatdetect";
  try {
    localStorage.setItem(k, k);
    localStorage.removeItem(k);
    return true;
  } catch (e) {
    return false;
  }
}
