import Cookies from "js-cookie";

export function oneTrustCookies(): { [key: string]: any } {
  let c = Cookies.get("OptanonConsent");

  if (c === undefined) {
    return {};
  }

  // While OneTrust do provide some utility methods such as
  // OneTrust.OnConsentChanged, they have proven to be not entirely reliable
  // especially in the context of React, so to ensure we're always pulling the
  // most up to date consent, we just go direct to the cookie
  const oneTrustCookieData = c.split("&").reduce((acc, curr) => {
    const [key, value] = curr.split("=");
    if (key === "groups") {
      const groups: string[] = [];
      value.split(",").forEach(item => {
        const [group, flag] = item.split(":");
        if (flag === "1") {
          groups.push(group);
        }
      });
      acc[key] = groups;
    } else {
      acc[key] = value;
    }
    return acc;
  }, {} as any);

  return oneTrustCookieData;
}
