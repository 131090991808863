import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import * as Sentry from "@sentry/react";

import AppWrapper from "./AppWrapper";
import { useGetFeaturesQuery } from "../graphql/hooks/useGetFeaturesQuery";
import { useDispatch } from "react-redux";
import { featuresActions } from "../store/features";
import { rebootIntercom } from "../intercom";
import ScrollToTop from "../components/ScrollToTop";
import { ExperimentsProvider } from "../components/experiments/ExperimentsContext";
import { StripeProvider } from "../components/StripeProvider";
import { ErrorState } from "../components/ErrorState";
import { Router } from "~/layout/Router";
import { Tracking } from "~/tracking";
import Hotjar from "@hotjar/browser";
import config from "~/config/browser";
import Cookies from "js-cookie";
import { store } from "~/store";
import { authActions } from "~/store/auth";
import { MigrationProvider } from "~/migration/context/MigrationContext";

const App = () => {
  const { data } = useGetFeaturesQuery({});
  const dispatch = useDispatch();

  const siteId = 3544931;
  const hotjarVersion = 6;

  useEffect(() => {
    rebootIntercom();
    Hotjar.init(siteId, hotjarVersion);
  }, []);

  useEffect(() => {
    if (data && data.features) {
      dispatch(featuresActions.setFlags({ flags: data.features }));
    }
  }, [dispatch, data]);

  if (Cookies.get("staffSession") && !store.getState().auth.usingStaffSession) {
    store.dispatch(
      authActions.authenticate({
        intercomId: "staff",
        staffSession: true
      })
    );
  }

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <ErrorState
          title="Sorry, something went wrong"
          onRetry={() => {
            window.location.reload();
          }}
        />
      )}
    >
      <Helmet>
        <title>LloydsDirect</title>

        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />

        {/* OneTrust Cookie Consent: THIS MUST BE LOADED BEFORE ANY TRACKING CODES */}
        <script
          src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
          type="text/javascript"
          data-domain-script={config.ONETRUST_ID}
        ></script>

        {/* Load Segment Analytics, this won't do any tracking until we .load the client, dependant on consent in <Tracking /> */}
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
          !(function(){var analytics = window.analytics = window.analytics || [];if (!analytics.initialize)if (analytics.invoked)window.console &&console.error &&console.error("Segment snippet included twice.");else {analytics.invoked = !0;analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"];analytics.factory = function (t) {return function () {var e = Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics }};for (var t = 0; t < analytics.methods.length; t++) {var e = analytics.methods[t];analytics[e] = analytics.factory(e)}analytics.load = function (t, e) {var n = document.createElement("script");n.type = "text/javascript";n.async = !0;n.src = "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js";var a = document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n, a);analytics._loadOptions = e};analytics.SNIPPET_VERSION = "4.1.0";}})();
          `
          }}
        />

        {config.AWIN_ADVERTISER_ID !== "" && (
          <>
            <script
              src={`https://www.dwin1.com/${config.AWIN_ADVERTISER_ID}.js`}
              type="text/javascript"
            ></script>
          </>
        )}

        <link
          rel="icon"
          type="image/png"
          href={require("../images/favicon-16.png").default}
          sizes="16x16"
        />
        <link
          rel="icon"
          type="image/png"
          href={require("../images/favicon-32.png").default}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={require("../images/favicon-96.png").default}
          sizes="96x96"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={require("../images/touch-icon-iphone-retina.png").default}
        />
        <link
          rel="apple-touch-icon"
          sizes="167x167"
          href={require("../images/touch-icon-ipad-retina.png").default}
        />
        <link
          href="https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500"
          rel="stylesheet"
        />
        <link
          href="https://storage.googleapis.com/echo-webapp/fonts/cahuenga.css"
          rel="stylesheet"
        />
        <meta
          name="norton-safeweb-site-verification"
          content="se7x7-taotq4k-wfrw-y97zbo9zad-aga3e2zviundh64p81bpu11120qah706w20hfr6yar9msbwdkwl1qrap5a1v4ndg3cu6o87o7f5iznqb11bx3mc27avx7evgeu"
        />
      </Helmet>

      <Tracking />

      <StripeProvider>
        <AppWrapper>
          <ScrollToTop />
          <ExperimentsProvider>
            <MigrationProvider>
              <Router />
            </MigrationProvider>
          </ExperimentsProvider>
        </AppWrapper>
      </StripeProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
