import React, { createContext } from "react";
import { matchPath, useLocation } from "react-router";
import { useExperiment } from "~/components/experiments/hooks/useExperiment";
import { useGetMigrationStatusQuery } from "~/graphql/hooks/useGetMigrationStatusQuery";
import { usePerformPdsCheckMutation } from "~/graphql/hooks/usePerformPdsCheckMutation";
import {
  AccountMigrationStatus,
  MigrationActivationStatus
} from "~/graphql/types/global";

type MigrationContextType = {
  migrationEnabledForAccount?: boolean;
  migrationStatus: AccountMigrationStatus;
  activationStatus: MigrationActivationStatus;
  activationLink: string | null;
  nominatedWithLD: boolean;
  nominatedWithP2U: boolean;
  nominatedPharmacyLastCheckedAt: number | null;
  trackExperimentEvents: (eventName: string[]) => void;
  reFetchMigrationStatus: () => void;
};

type Props = {
  children?: React.ReactNode;
};

export const MigrationContext = createContext<MigrationContextType>({
  migrationStatus: AccountMigrationStatus.ACCOUNT_MIGRATION_STATUS_UNKNOWN,
  activationStatus:
    MigrationActivationStatus.ACCOUNT_MIGRATION_ACTIVATION_STATUS_UNKNOWN,
  activationLink: null,
  nominatedWithLD: false,
  nominatedWithP2U: false,
  nominatedPharmacyLastCheckedAt: null,
  trackExperimentEvents: () => {},
  reFetchMigrationStatus: () => {}
});

interface PatientRouteParams {
  patientId?: string;
}

// This is our context provider which we will wrap around the entire app
export const MigrationProvider: React.FC<Props> = ({ children }) => {
  // This is fairly hacky but without going through every route and
  // adding the provider we can't use router params properly at
  // this level in the app hierarchy
  const { pathname } = useLocation();
  const route = matchPath<PatientRouteParams>(pathname, {
    path: "*/(help|patient)/:patientId"
  });
  const patientId = route?.params?.patientId;

  const [performPdsCheck] = usePerformPdsCheckMutation({});

  const { data, refetch } = useGetMigrationStatusQuery({
    variables: {
      patientId: patientId || ""
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    onCompleted: data => {
      let migrationInfo = data?.account?.migrationInfo;
      let nominatedPharmacyLastUpdatedAtBeforePDS =
        data?.patient?.nominatedPharmacyUpdatedAt?.seconds;
      // If this is a migration experiment and the user is in a pending or consented state
      // we want to attempt a PDS trace to see if their nomination will change, if so
      // we will refetch the migration status again to get the latest from our backend.
      if (
        patientId &&
        migrationInfo?.experiment?.isEnabled &&
        (migrationInfo?.status ===
          AccountMigrationStatus.ACCOUNT_MIGRATION_STATUS_PENDING ||
          migrationInfo?.status ===
            AccountMigrationStatus.ACCOUNT_MIGRATION_STATUS_CONSENTED)
      ) {
        // This mutation handles limiting the number of PDS checks we can do per day
        // so we don't have to worry about rate limiting here.
        performPdsCheck({
          variables: {
            patientId: patientId,
            isMigrationCheck: true
          },
          onCompleted: pdsCheckData => {
            let pdsCheck = pdsCheckData.performPdsCheck;
            let pdsPatient = pdsCheck?.patient;
            let hasUpdatedPds =
              nominatedPharmacyLastUpdatedAtBeforePDS !==
              pdsPatient?.nominatedPharmacyUpdatedAt?.seconds;
            // If we've actually updated the nominated pharmacy
            if (hasUpdatedPds) {
              refetch();
            }
          }
        });
      }
    },
    onError: error => {
      console.log(error);
    }
  });

  const migrationStatus =
    data?.account?.migrationInfo.status ||
    AccountMigrationStatus.ACCOUNT_MIGRATION_STATUS_UNKNOWN;

  const activationStatus =
    data?.migrationActivationDetails?.status ||
    MigrationActivationStatus.ACCOUNT_MIGRATION_ACTIVATION_STATUS_UNKNOWN;
  const activationLink = data?.migrationActivationDetails?.link || null;

  const experiment = data?.account?.migrationInfo.experiment;
  const patient = data?.patient;

  const { trackExperimentEvents } = useExperiment("lion-cohort-01");

  return (
    <MigrationContext.Provider
      value={{
        migrationEnabledForAccount: experiment?.isEnabled,
        migrationStatus: migrationStatus,
        activationStatus,
        activationLink,
        nominatedWithLD: patient?.isNominated || false,
        nominatedWithP2U: patient?.isNominatedWithP2U || false,
        nominatedPharmacyLastCheckedAt:
          patient?.nominatedPharmacyCheckedAt?.seconds || null,
        trackExperimentEvents,
        reFetchMigrationStatus: refetch
      }}
    >
      {children}
    </MigrationContext.Provider>
  );
};
