import * as React from "react";
import styled, { css } from "styled-components";
import withMargin from "../styled/withMargin";
import { CommonLinkProps } from "./AnchorLink";

const CookiePreferencesButtonWrapper = styled("button")<CommonLinkProps>`
  ${({ theme, isDisabled, size, color }) => css`
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;

    color: ${theme.color.blue70};
    font-weight: ${theme.typography.weight.bold};
    padding: 0;
    font-size: ${theme.typography.size.xs};
    line-height: ${theme.typography.lineHeight.xs};
    font-weight: ${theme.typography.weight.bold};
    border: 0;
    color: ${color === "blue"
      ? `${theme.color.blue70}`
      : `${theme.color.gray70}`};

    &:hover,
    &:focus {
      color: ${theme.color.blue70};
      background: transparent;
      transition: all 100ms ease;
    }
  `}
  ${withMargin};
`;

const CookiePreferencesButton = ({ children }: { children: String }) => {
  return (
    <CookiePreferencesButtonWrapper
      rel="noreferrer"
      className="ot-sdk-show-settings"
    >
      {children}
    </CookiePreferencesButtonWrapper>
  );
};

export default CookiePreferencesButton;
