import { requestPrescription_patient_exemptions } from "~/graphql/types/requestPrescription";
import { ExemptionStatus } from "~/graphql/types/global";
import { formatPrice } from "./price";
import { getUnixTime, parse } from "date-fns";
import { pendingPrescriptions_patient_exemptions } from "~/graphql/types/pendingPrescriptions";
import { paymentPage_patient_exemptions } from "~/graphql/types/paymentPage";

export const getMostRecentExemption = <
  T extends
    | requestPrescription_patient_exemptions
    | pendingPrescriptions_patient_exemptions
    | paymentPage_patient_exemptions
>(
  exemptions: T[],
  exemptionStatus: ExemptionStatus
): T => {
  const currentDate = new Date().getTime() / 1000;

  return exemptions
    .filter(exemption => {
      return (
        exemption.status === exemptionStatus &&
        (exemption.expiry === null ||
          expiryInSeconds(
            exemption.expiry.seconds,
            exemption.expiry.formatted
          ) > currentDate)
      );
    })
    .sort((a, b) => {
      return b.created.seconds - a.created.seconds;
    })[0];
};

// This is a temporary hack to handle cases where the Unixtime of an expiry is
// such a big number it won't fit in the GraphQL Int32 range
// https://linear.app/echo/issue/PATX-848/error-resolving-exemption-expiry-date-in-api
export const expiryInSeconds = (seconds: number, formatted: string) => {
  return seconds > 0
    ? seconds
    : getUnixTime(parse(formatted, "dd/MM/yyyy", new Date()));
};

/**
 * This will prettify a number and return a standard representation as currency
 * @param price The price you want to format
 * @param transformZero If the price is zero, do you want to display it as 'Free'?
 */
export const getPriceAsString = (price: number, transformZero = true) => {
  if (transformZero && price === 0) {
    return "Free";
  }
  return formatPrice(price);
};

/**
 * "It picks a random element for each original array element, and excludes it from the next draw,
 * like picking randomly from a deck of cards.
 *
 * This clever exclusion swaps the picked element with the current one, then picks the next random
 * element from the remainder, looping backwards for optimal efficiency, ensuring the random pick
 * is simplified (it can always start at 0), and thereby skipping the final element."
 * - https://stackoverflow.com/a/12646864 ¯\_(ツ)_/¯
 */
export const shuffle = <T>(array: T[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
};
