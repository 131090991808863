import * as React from "react";
import styled from "styled-components";
import theme from "~/styled/theme";
import withMargin, { WithMarginProp } from "../styled/withMargin";
import { KeysMatching } from "@echo-health/design-system/utilities/types";

export type IconComponent = (
  props: React.SVGProps<SVGSVGElement>
) => React.ReactElement<SVGElement>;

export const sizeMap = {
  s: 16,
  m: 24,
  l: 32,
  xl: 64
};

const StyledSvg = styled.svg<WithMarginProp>`
  line-height: 1;
  ${withMargin};
`;

interface IconSize {
  width: number;
  height: number;
}

export type IconColor = KeysMatching<typeof theme.colors, string>;

interface Props extends WithMarginProp {
  type: IconComponent;
  color?: IconColor | "currentColor";
  size?: keyof typeof sizeMap;
  customSize?: IconSize;
  className?: string;
}

const Icon = ({
  type: IconComponent,
  color = "gray40",
  size = "m",
  customSize,
  className,
  margin
}: Props) => {
  let width = sizeMap[size];
  let height = sizeMap[size];

  if (customSize) {
    width = customSize.width;
    height = customSize.height;
  }

  return (
    <StyledSvg
      as={IconComponent}
      width={width}
      height={height}
      color={
        color === "currentColor"
          ? "currentColor"
          : (theme.colors[color] as string)
      }
      fill={
        color === "currentColor"
          ? "currentColor"
          : (theme.colors[color] as string)
      }
      className={className}
      margin={margin}
    />
  );
};

export default Icon;
