import React from "react";
import styled from "styled-components";
import { ApolloError } from "@apollo/client";
import { getErrorMessage } from "../utils/error";

// This component should be as self-contained as possible to avoid recursive
// errors from imported components, e.g. don't import design system or other
// common components that may be erroring themselves.

interface Props {
  title: string;
  error?: Error | ApolloError | string;
  onRetry?: () => void;
}

const Heading = styled.h1`
  width: 100%;
  font-size: 1.5rem;
  font-family: Cahuenga, sans-serif;
  font-weight: 500;
  margin-bottom: 14px;
  line-height: 1.5;
  color: #212932;
  text-align: center;
`;

const Button = styled.button`
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  box-sizing: border-box;
  border: none;
  width: 100%;
  padding: 12px 24px;
  border-radius: 4px;
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  -webkit-text-decoration: none;
  text-decoration: none;
  -webkit-transition: background-color 0.2s ease, box-shadow 0.2s ease;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  color: #002a1d;
  &:hover {
    background: rgb(100, 244, 198);
  }
  background: #2ee0a7;
`;

const Text = styled.p`
  display: block;
  color: rgb(33, 41, 50);
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  line-height: 1.5;
  margin: 0px 0px 24px;
  letter-spacing: 0px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
`;

const ErrorStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 50px;
  width: 350px;
`;

export function ErrorState({ title, error, onRetry }: Props) {
  const { isNetworkError, message, isGraphQLAuthError } =
    getErrorMessage(error);

  return (
    <ErrorStateWrapper>
      <Heading>{title}</Heading>
      {isNetworkError && (
        <>
          <Text>{message}</Text>
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            Try again
          </Button>
        </>
      )}
      {isGraphQLAuthError && (
        <Button onClick={() => (window.location.href = "/login")}>Login</Button>
      )}
      {!isNetworkError && !isGraphQLAuthError && (
        <>
          <Text>{message}</Text>
          <Button onClick={onRetry}>Try again</Button>
        </>
      )}
    </ErrorStateWrapper>
  );
}
