import * as React from "react";
import styled from "styled-components";

interface FormProps {
  id?: string;
  method?: string;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  children?: React.ReactNode;
}

const StyledForm = styled.form`
  width: 100%;
`;

const Form = ({ method = "post", children, onSubmit, ...props }: FormProps) => {
  return (
    <StyledForm
      method={method}
      onSubmit={event => {
        event.preventDefault();
        event.stopPropagation();
        onSubmit(event);
        return false;
      }}
      {...props}
    >
      {children}
    </StyledForm>
  );
};

export default Form;
